import React from 'react'
import { useSelector } from 'react-redux'
import {
  Col,
  ListGroup,
  Nav,
  Row
} from 'react-bootstrap'
import {
  NavLink,
  Outlet
} from 'react-router-dom'
import { selectUser } from '../auth'
import CategorySelector from './CategorySelector'
import TagSelector from './TagSelector'

export const ExamOrCourse = () => {
  const user = useSelector(selectUser)
  return (
    <Row className='h-100'>
      <Col sm='2' className='remoes-sidekick'>
        <h2>試験・講座</h2>
        <ListGroup className='exam-or-course-sidemenu'>
          <ListGroup.Item
            as={NavLink}
            to='browse'
          >試験・講座を探す
          </ListGroup.Item>
          {user &&
            <ListGroup.Item
              as={NavLink}
              to='application'
            >申し込み済み
            </ListGroup.Item>}
        </ListGroup>
        <div className='border rounded-2 bg-white shadow'>
          <CategorySelector />
        </div>
        <div className='border rounded-2 bg-white shadow'>
          <TagSelector />
        </div>
      </Col>
      <Col sm='10'><Outlet /></Col>
    </Row>
  )
}

ExamOrCourse.NavItem = () => (
  <Nav.Link
    as={NavLink}
    to='/examOrCourse'
  >試験・講座を探す
  </Nav.Link>
)
