export * from './commonSlice'
export * from './commonAPI'
export * from './Layout'
export * from './NotFound'
export * from './PostalCodeInput'
export * from './validation'
export * from './Pagination'
export * from './SearchPanel'

export const examOrCourseDisplay = examOrCourse => {
  if (!examOrCourse) {
    return examOrCourse
  }
  const { time, name } = examOrCourse
  if (!time) {
    return name
  }
  return `第${time}回${name}`
}
