import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useSearchParams,
  Link
} from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Col,
  Container,
  ListGroup,
  Row
} from 'react-bootstrap'
import _ from 'lodash'
import {
  Pagination,
  parseQueryAsInt,
  updateQueryParamEntries,
  removeQueryParamKeys
} from '../../common'
import { selectUser } from '../../auth'
import {
  openModal,
  closeModal,
  clearAllLists
} from '../adminSlice'
import SearchPanel from './SearchPanel'
import UpsertModal from './UpsertModal'
import DeleteModal from './DeleteModal'
import { selectCategory } from './categorySelectors'
import {
  fetchCategoryCandidates,
  fetchCategories,
  createCategory,
  updateCategory,
  deleteCategory
} from './categoryAPI'

const CategoryRow = ({
  name,
  parent,
  onEditClick,
  onDeleteClick
}) => (
  <Row>
    <Col>
      <h5>
        {parent && (
          <Link
            to={`?parentId=${parent.id}`}
            className='category-parent-link'
          >{parent.name}
          </Link>
        )}
        {parent && ' / '}
        {name}
      </h5>
    </Col>
    <Col sm='auto'>
      <Button
        onClick={onEditClick}
        variant='light'
      >編集
      </Button>
    </Col>
    <Col sm='auto'>
      <Button
        onClick={onDeleteClick}
        variant='danger'
      >削除
      </Button>
    </Col>
  </Row>
)

const upsertModalSelector = ['category', 'upsert']
const deleteModalSelector = ['category', 'delete']

const fetchCategoriesParams = (page, filtering) =>
  _.omitBy(
    _.merge(
      { page },
      filtering),
    (value) => !value || value === 'all')

const searchKeys = ['query', 'parentId']
const parseQueryToFiltering = queryParams =>
  Object.fromEntries(
    _.filter(
      [...queryParams.entries()],
      ([key, _value]) => searchKeys.includes(key)))

const CategoryList = () => {
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useSearchParams()
  const user = useSelector(selectUser)
  const {
    version,
    list,
    openedModal,
    upsert,
    delete: destroy,
    fetching
  } = useSelector(selectCategory)
  const currentPage = parseQueryAsInt(queryParams, 'page')
  const filtering = parseQueryToFiltering(queryParams)

  useEffect(() => {
    if (!user) {
      dispatch(clearAllLists())
    }
  }, [user])

  useEffect(() => {
    if (!user) return

    const filtering = parseQueryToFiltering(queryParams)
    dispatch(fetchCategories(fetchCategoriesParams(currentPage, filtering)))
  }, [user, version, currentPage, queryParams])

  const handlePaginationClick = page => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['page', page]]),
      { replace: true })
  const handleSearch = values =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        Object.entries(values)),
      { replace: true })
  const handleReset = () =>
    setQueryParams(
      removeQueryParamKeys(
        queryParams,
        searchKeys),
      { replace: true })
  const handleOpenModal = args => () => dispatch(openModal({ modalSelector: upsertModalSelector, args }))
  const handleHideModal = () => dispatch(closeModal(upsertModalSelector))
  const handleOpenDeleteModal = args => () => dispatch(openModal({ modalSelector: deleteModalSelector, args }))
  const handleHideDeleteModal = () => dispatch(closeModal(deleteModalSelector))
  const handleSubmit = currentCategory => values => {
    const action = currentCategory ? updateCategory : createCategory
    dispatch(action(_.merge(values, { id: currentCategory?.id })))
  }
  const handleDeleteSubmit = currentCategory =>
    () => dispatch(deleteCategory({ id: currentCategory.id }))
  const handleCategoryParentInputChange = ({ preDispatchCallback }) => query => {
    if (preDispatchCallback) {
      preDispatchCallback()
    }
    dispatch(fetchCategoryCandidates({ query, parentNull: true }))
  }
  const handleParentSelected = setParent => selectedParents => {
    if (selectedParents.length < 1) {
      return
    }
    setParent(selectedParents[0])
  }
  const handleRemoveParent = () =>
    setQueryParams(
      removeQueryParamKeys(
        queryParams,
        ['parentId']),
      { replace: true })
  return (
    <>
      <UpsertModal
        show={openedModal === 'upsert'}
        onHide={handleHideModal}
        onSubmit={handleSubmit(upsert.args)}
        fetching={fetching}
        notice={upsert.notice}
        error={upsert.error}
        category={upsert.args}
        onCategoryParentInputChange={handleCategoryParentInputChange}
        parentCandidates={upsert.parentCandidates}
        onParentSelected={handleParentSelected}
      />
      <DeleteModal
        show={openedModal === 'delete'}
        onHide={handleHideDeleteModal}
        onSubmit={handleDeleteSubmit(destroy.args)}
        error={destroy.error}
        notice={destroy.notice}
        fetching={fetching}
        category={destroy.args}
      />
      <Container>
        <Row className='mb-2'>
          <Col>
            <SearchPanel
              queryParams={queryParams}
              onSearch={handleSearch}
              onReset={handleReset}
              initialValues={filtering}
            />
          </Col>
          <Col sm='auto'>
            <Button
              onClick={handleOpenModal()}
            >新規作成
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              <Breadcrumb>
                <Breadcrumb.Item
                  onClick={handleRemoveParent}
                  active={!list.parent}
                >全ジャンル
                </Breadcrumb.Item>
                {list.parent && (
                  <Breadcrumb.Item
                    active
                  >{list.parent.name}
                  </Breadcrumb.Item>
                )}
              </Breadcrumb>
              {list && <small>{list.totalCount}件</small>}
              <ListGroup variant='flush' className='category-list'>
                {_.map(
                  list.data,
                  (category) => (
                    <ListGroup.Item key={category.id}>
                      <CategoryRow
                        {...category}
                        onEditClick={handleOpenModal(category)}
                        onDeleteClick={handleOpenDeleteModal(category)}
                      />
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Container>
            <Pagination
              currentPage={currentPage || 1}
              totalPages={list.totalPages}
              onPaginationClick={handlePaginationClick}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CategoryList
