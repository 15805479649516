import React, { useEffect } from 'react'
import {
  useParams,
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { parseQueryAsInt } from '../../common'
import {
  selectUser,
  openModal as openAuthModal
} from '../../auth'
import {
  setPostComplete
} from '../../profile'
import {
  fetchExamOrCourseName,
  fetchExamOrCourseNameSections,
  fetchExamOrCourse,
  createApplication
} from './browseAPI'
import {
  selectBrowse,
  openModal,
  closeModal
} from '../examOrCourseSlice'
import { ApplyModal } from './ApplyModal'
import ExamOrCourseNameDisplay from './ExamOrCourseNameDisplay'

export const ExamOrCourseNameDetail = () => {
  const { examOrCourseNameId: id } = useParams()
  const dispatch = useDispatch()
  const { detail } = useSelector(selectBrowse)
  const { examOrCourseName, sections, examOrCourse, apply, openedModal } = detail
  const [queryParams, setQueryParams] = useSearchParams()
  const selectedExamOrCourseId = parseQueryAsInt(queryParams, 'selectedExamOrCourseId')
  const user = useSelector(selectUser)
  const navigate = useNavigate()

  const usedSectionIds = _.map(examOrCourseName?.explanatoryItems ?? [], ({ examOrCourseNameSectionId }) => examOrCourseNameSectionId)
  const explanatoryItems = _.reduce(
    examOrCourseName?.explanatoryItems ?? [],
    (accumulated, { examOrCourseNameSectionId, ...explanatoryItem }) =>
      _.merge(
        accumulated,
        {
          [examOrCourseNameSectionId]: [
            ..._.has(accumulated, [examOrCourseNameSectionId]) ? accumulated[examOrCourseNameSectionId] : [],
            explanatoryItem
          ]
        }),
    {})

  useEffect(() => {
    dispatch(fetchExamOrCourseNameSections({ perPage: 1024, sorting: { key: 'order', order: 'asc' } }))
    dispatch(fetchExamOrCourseName({ id }))
    dispatch(fetchExamOrCourse(selectedExamOrCourseId))
  }, [id])

  const initiateApplication = (examOrCourseId, examOrCourseNameId) => () => {
    if (!user) {
      dispatch(openAuthModal({
        modalName: 'login',
        notice: '申し込みを開始するには、ログインしてください。アカウントをお持ちでない方は、会員登録をしてください。',
        applyingExamOrCourse: examOrCourseId,
        applyingExamOrCourseName: examOrCourseNameId
      }))
      return
    }
    if (!user.readyForApplication) {
      dispatch(setPostComplete({
        applyingExamOrCourse: examOrCourseId,
        applyingExamOrCourseName: examOrCourseNameId,
        notice: 'プロフィール入力が必要です。申し込みを開始するには、プロフィールの入力を完了させてください。'
      }))
      navigate('/profile/input')
      return
    }
    dispatch(openModal({
      modalSelector: ['browse', 'detail', 'apply'],
      args: examOrCourseId
    }))
  }
  useEffect(() => {
    const applyingExamOrCourseId = Number.parseInt(queryParams.get('applyingExamOrCourse'))
    const applyingExamOrCourseNameId = Number.parseInt(queryParams.get('applyingExamOrCourseName'))
    if (!isNaN(applyingExamOrCourseId) && applyingExamOrCourseId &&
        !isNaN(applyingExamOrCourseNameId) && applyingExamOrCourseNameId) {
      setQueryParams(
        _.filter(
          [...queryParams.entries()],
          ([key, _value]) => !['applyingExamOrCourse', 'applyingExamOrCourseName'].includes(key)))
      initiateApplication(applyingExamOrCourseId, applyingExamOrCourseNameId)()
    }
  }, [queryParams])
  useEffect(() => {
    if (apply.paymentUrl) {
      window.location.href = apply.paymentUrl
    }
  }, [apply.paymentUrl])

  const handleHideApplyModal = () => dispatch(closeModal(['browse', 'detail', 'apply']))
  const handleApplySubmit = examOrCourse => values => {
    if (!examOrCourse) {
      return
    }
    dispatch(createApplication({ examOrCourseId: examOrCourse.id, ...values }))
  }

  if (!examOrCourseName || !sections || !examOrCourse) {
    return <></>
  }

  return (
    <>
      <ApplyModal
        show={openedModal === 'apply'}
        onHide={handleHideApplyModal}
        onSubmit={handleApplySubmit}
        examOrCourse={examOrCourse}
        error={apply.error}
      />
      <ExamOrCourseNameDisplay
        {...examOrCourseName}
        sections={_.filter(sections, section => usedSectionIds.includes(section.id))}
        explanatoryItems={explanatoryItems}
        onApply={initiateApplication(examOrCourse.id, examOrCourseName.id)}
        examOrCourse={examOrCourse}
      />
    </>
  )
}
