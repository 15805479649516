import toCamel from 'lodash-humps'
import _ from 'lodash'
import {
  objectToQuerystring,
  handleError
} from '../common'
import { invalidateCurrentUser } from '../auth'

export const fetchExamOrCoursesPayloadCreator = async (params, { getState, rejectWithValue, dispatch }) => {
  const { baseUri } = getState().common
  return fetch(`${baseUri}admin/exam_or_courses?${objectToQuerystring(params)}`, {
    headers: {
      Accept: 'application/json'
    }
  })
    .then(async response => {
      if (!response.ok) {
        const error = new Error(`${response.status} ${response.statusText}`)
        error.status = response.status
        error.statusText = response.statusText
        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.startsWith('application/json')) {
          const responseBody = await response.json()
          error.messages = _.reduce(
            responseBody.errors,
            (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
            responseBody.error ? [responseBody.error] : []
          )
        }
        throw error
      }
      return response.json()
    })
    .then(toCamel)
    .catch(handleError({
      dispatch,
      rejectWithValue,
      invalidateCurrentUser,
      redirectUri: '/?authMode=login'
    }))
}

export const fetchVenueExecutionsPayloadCreator = async (params, { getState, rejectWithValue, dispatch }) => {
  const { baseUri } = getState().common
  return fetch(`${baseUri}admin/venue_executions?${objectToQuerystring(params)}`, {
    headers: {
      Accept: 'application/json'
    }
  })
    .then(async response => {
      if (!response.ok) {
        const error = new Error(`${response.status} ${response.statusText}`)
        error.status = response.status
        error.statusText = response.statusText
        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.startsWith('application/json')) {
          const responseBody = await response.json()
          error.messages = _.reduce(
            responseBody.errors,
            (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
            responseBody.error ? [responseBody.error] : []
          )
        }
        throw error
      }
      return response.json()
    })
    .then(toCamel)
    .catch(handleError({
      dispatch,
      rejectWithValue,
      invalidateCurrentUser,
      redirectUri: '/?authMode=login'
    }))
}

export const fetchOperationOrganizatiosPayloadCreator = async (params, { getState, rejectWithValue, dispatch }) => {
  const { baseUri } = getState().common
  return fetch(`${baseUri}admin/operation_organizations?${objectToQuerystring(params)}`, {
    headers: {
      Accept: 'application/json'
    }
  })
    .then(async response => {
      if (!response.ok) {
        const error = new Error(`${response.status} ${response.statusText}`)
        error.status = response.status
        error.statusText = response.statusText
        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.startsWith('application/json')) {
          const responseBody = await response.json()
          error.messages = _.reduce(
            responseBody.errors,
            (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
            responseBody.error ? [responseBody.error] : []
          )
        }
        throw error
      }
      return response.json()
    })
    .then(toCamel)
    .catch(handleError({
      dispatch,
      rejectWithValue,
      invalidateCurrentUser,
      redirectUri: '/?authMode=login'
    }))
}

export const fetchExamOrCourseNamesPayloadCreator = async (params, { getState, rejectWithValue, dispatch }) => {
  const { baseUri } = getState().common
  return fetch(`${baseUri}admin/exam_or_course_names?${objectToQuerystring(params)}`, {
    headers: {
      Accept: 'application/json'
    }
  })
    .then(async response => {
      if (!response.ok) {
        const error = new Error(`${response.status} ${response.statusText}`)
        error.status = response.status
        error.statusText = response.statusText
        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.startsWith('application/json')) {
          const responseBody = await response.json()
          error.messages = _.reduce(
            responseBody.errors,
            (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
            responseBody.error ? [responseBody.error] : []
          )
        }
        throw error
      }
      return response.json()
    })
    .then(toCamel)
    .catch(handleError({
      dispatch,
      rejectWithValue,
      invalidateCurrentUser,
      redirectUri: '/?authMode=login'
    }))
}
