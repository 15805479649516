import {
  object,
  string,
  date,
  mixed,
  array
} from 'yup'
import { compose } from 'lodash/fp'
import {
  convertToHalfWidth,
  prefectures,
  katakanaValidationMessage,
  katakanaValidationTest
} from '../common'

export const aboutYouSchema = object({
  familyName: string().required(),
  givenName: string().required(),
  phonicFamilyName: string().required()
    .test('katakana',
      katakanaValidationMessage,
      katakanaValidationTest),
  phonicGivenName: string().required()
    .test('katakana',
      katakanaValidationMessage,
      katakanaValidationTest),
  birthDate: date().required()
})

export const addressSchema = object({
  postalCode: string().required().matches(/\d{7}/, '数字7桁で入力してください'),
  prefecture: string().required().oneOf(prefectures),
  city: string().required(),
  addressLine1: string().required(),
  addressLine2: string().optional()
})

export const contactSchema = object({
  phoneNumber: string().required()
})

const stripNonNumericNonHyphen = s => s.replace(/[^0-9-]/g, '')

export const validatePhoneNumberInput = compose(stripNonNumericNonHyphen, convertToHalfWidth)

const supportedImageMimes = [
  'image/bmp', 'image/jpeg', 'image/png', 'image/tiff'
]

const imageFileMaxMb = 10

export const identificationDocumentSchema = object({
  identificationDocumentImages: array().of(mixed().required('画像を撮影または選択してください').test(
    'fileType',
    '対応する画像はBMP, JPEG, PNG, TIFFです',
    file => file && supportedImageMimes.includes(file.type)
  ).test(
    'fileSize',
    `${imageFileMaxMb}MB以下の画像をアップロードしてください`,
    file => file && file.size <= imageFileMaxMb * 1024 * 1024
  ))
})
