import { createSlice } from '@reduxjs/toolkit'
import {
  updateProfile,
  searchAddressByPostalCode,
  uploadIdentificationDocumentImage
} from './profileAPI'

const initialState = {
  version: 0,
  searchByPostalCode: {
    fetching: false,
    candidates: []
  }
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    dismissNotice: (state, action) => {
      delete state.notice
    },
    dismissAlert: (state, action) => {
      delete state.error
    },
    setPostComplete: (state, action) => {
      const { notice, ...postComplete } = action.payload
      state.postComplete = postComplete
      state.notice = notice
    },
    removePostComplete: state => {
      delete state.postComplete
    }
  },
  extraReducers: builder => builder
    .addCase(updateProfile.pending, state => {
      state.fetching = true
      delete state.error
      delete state.notice
    })
    .addCase(updateProfile.fulfilled, (state, action) => {
      state.fetching = false
      state.notice = '更新を保存しました'
      state.version += 1
    })
    .addCase(updateProfile.rejected, (state, action) => {
      state.fetching = false
      state.error = action.payload
    })
    .addCase(searchAddressByPostalCode.pending, searchAddressByPostalCode.pendingReducer(state => state.searchByPostalCode))
    .addCase(searchAddressByPostalCode.fulfilled, searchAddressByPostalCode.fulfilledReducer(state => state.searchByPostalCode))
    .addCase(searchAddressByPostalCode.rejected, searchAddressByPostalCode.rejectedReducer(state => state.searchByPostalCode))
    .addCase(uploadIdentificationDocumentImage.pending, state => {
      state.fetching = true
      delete state.identificationDocument.error
    })
    .addCase(uploadIdentificationDocumentImage.fulfilled, (state, action) => {
      state.fetching = false
      state[action.payload].notice = '更新を保存しました'
      state.version += 1
    })
    .addCase(uploadIdentificationDocumentImage.rejected, (state, action) => {
      state.fetching = false
      state[action.payload.origin].error = action.payload
    })
})

export const selectProfile = state => state.profile

export const {
  dismissNotice,
  dismissAlert,
  setPostComplete,
  removePostComplete
} = profileSlice.actions
