import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useSearchParams
} from 'react-router-dom'
import {
  Button,
  Col,
  Container,
  ListGroup,
  Row
} from 'react-bootstrap'
import _ from 'lodash'
import {
  Pagination,
  parseQueryAsInt,
  updateQueryParamEntries,
  removeQueryParamKeys
} from '../../common'
import { selectUser } from '../../auth'
import {
  openModal,
  closeModal,
  clearAllLists
} from '../adminSlice'
import { selectOperationOrganization } from './operationOrganizationSelectors'
import {
  fetchOperationOrganizations,
  createOperationOrganization,
  updateOperationOrganization,
  listUsers
} from './operationOrganizationAPI'
import { OperationOrganizationSearchPanel } from './SearchPanel'
import { UpsertModal } from './UpsertModal'

const OperationOrganizationRow = ({
  operationOrganization,
  onEditClick
}) => (
  <Row>
    <Col>
      <h5>{operationOrganization.name}</h5>
      <dl>
        <dt>名称カナ</dt>
        <dd>{operationOrganization.phonicName}</dd>
        <dt>通知用メールアドレス</dt>
        <dd>{operationOrganization.notificationEmail}</dd>
        {operationOrganization.contactPerson &&
          <>
            <dt>連絡先ユーザー</dt>
            <dd>
              {operationOrganization.contactPerson.familyName}
              {operationOrganization.contactPerson.givenName}
              {' <' + operationOrganization.contactPerson.email + '>'}
            </dd>
          </>}
      </dl>
    </Col>
    <Col sm='auto'>
      <Button
        variant='light'
      >編集
      </Button>
    </Col>
  </Row>
)

const upsertModalSelector = ['operationOrganization', 'upsert']

const fetchOperationOrganizationsParams = (page, filtering) =>
  _.omitBy(
    _.merge(
      { page },
      filtering),
    (value) => !value || value === 'all')

const operationOrganizationSearchKeys = ['query']
const parseQueryToFiltering = queryParams =>
  Object.fromEntries(
    _.filter(
      [...queryParams.entries()],
      ([key, _value]) => operationOrganizationSearchKeys.includes(key)))

export const OperationOrganizationList = () => {
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useSearchParams()
  const user = useSelector(selectUser)
  const {
    version,
    list,
    openedModal,
    upsert,
    fetching
  } = useSelector(selectOperationOrganization)
  const currentPage = parseQueryAsInt(queryParams, 'page')
  const filtering = parseQueryToFiltering(queryParams)

  useEffect(() => {
    if (!user) {
      dispatch(clearAllLists())
    }
  }, [user])

  useEffect(() => {
    if (!user) return

    const filtering = parseQueryToFiltering(queryParams)
    dispatch(fetchOperationOrganizations(fetchOperationOrganizationsParams(currentPage, filtering)))
  }, [user, version, currentPage, queryParams])

  const handlePaginationClick = page => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['page', page]]),
      { replace: true })
  const handleSearch = values =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        Object.entries(values)),
      { replace: true })
  const handleReset = () =>
    setQueryParams(
      removeQueryParamKeys(
        queryParams,
        operationOrganizationSearchKeys),
      { replace: true })
  const handleOpenModal = args => () => dispatch(openModal({ modalSelector: upsertModalSelector, args }))
  const handleHideModal = () => dispatch(closeModal(upsertModalSelector))
  const handleSubmit = id => values => dispatch((id ? updateOperationOrganization : createOperationOrganization)(_.merge(values, { id })))
  const handleContactPersonIdInputChange = preDispatchCallback => query => {
    preDispatchCallback()
    dispatch(listUsers({ query }))
  }
  const handleContactPersonsSelected = setUser => selectedUsers => {
    if (selectedUsers.length < 1) {
      return
    }
    setUser(selectedUsers[0])
  }

  return (
    <>
      <UpsertModal
        show={openedModal === 'upsert'}
        onHide={handleHideModal}
        fetching={fetching}
        notice={upsert.notice}
        error={upsert.error}
        operationOrganization={upsert.args}
        onSubmit={handleSubmit(upsert.args?.id)}
        onContactPersonIdInputChange={handleContactPersonIdInputChange}
        userCandidates={upsert.userCandidates}
        onContactPersonsSelected={handleContactPersonsSelected}
      />
      <Container>
        <Row className='mb-2'>
          <Col>
            <OperationOrganizationSearchPanel
              queryParams={queryParams}
              onSearch={handleSearch}
              onReset={handleReset}
              initialValues={filtering}
            />
          </Col>
          <Col sm='auto'>
            <Button
              onClick={handleOpenModal()}
            >新規作成
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              {list && <small>{list.totalCount}件</small>}
              <ListGroup variant='flush' className='operation-organization-list'>
                {_.map(
                  list.data,
                  (operationOrganization) => (
                    <ListGroup.Item key={operationOrganization.id}>
                      <OperationOrganizationRow
                        operationOrganization={operationOrganization}
                        onEditClick={handleOpenModal(operationOrganization)}
                      />
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Container>
            <Pagination
              currentPage={currentPage || 1}
              totalPages={list.totalPages}
              onPaginationClick={handlePaginationClick}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
