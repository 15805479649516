import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import _ from 'lodash'
import {
  csrfToken,
  objectToQuerystring,
  handleError
} from '../../common'
import { invalidateCurrentUser } from '../../auth'

export const fetchCertificates = createAsyncThunk(
  'admin/digital_certificate/fetchCertificates',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}admin/digital_certificates?${objectToQuerystring(params)}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

const createPayloadCreatror = (operation) => async (params, { getState, rejectWithValue, dispatch }) => {
  const { baseUri } = getState().common
  return fetch(
    `${baseUri}admin/digital_certificates/${operation}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify(params)
    })
    .then(async response => {
      if (!response.ok) {
        const error = new Error(`${response.status} ${response.statusText}`)
        error.status = response.status
        error.statusText = response.statusText
        const contentType = response.headers.get('Content-Type')
        if (contentType && contentType.startsWith('application/json')) {
          const responseBody = await response.json()
          error.messages = _.reduce(
            responseBody.errors,
            (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
            responseBody.error ? [responseBody.error] : []
          )
        }
        throw error
      }
      return response.json()
    })
    .catch(handleError({
      dispatch,
      rejectWithValue,
      invalidateCurrentUser,
      redirectUri: '/?authMode=login'
    }))
}

export const addDigitalCertificate = createAsyncThunk(
  'admin/digitalCertificfate/addDigitalCertificate',
  createPayloadCreatror('add')
)

export const updateDigitalCertificate = createAsyncThunk(
  'admin/digitalCertificfate/updateDigitalCertificate',
  createPayloadCreatror('update')
)
