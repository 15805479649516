import {
  object,
  string,
  number,
  array
} from 'yup'
import {
  katakanaValidationMessage,
  katakanaValidationTest
} from '../../common'

export const examOrCourseNameSchema = object({
  operationOrganization: number().required().min(1, '選択してください'),
  value: string().required(),
  phonicName: string().required()
    .test('katakana',
      katakanaValidationMessage,
      katakanaValidationTest),
  categoryId: number().required().min(1),
  tagIds: array()
    .min(1, '選択してください')
    .of(number().min(1)),
  description: string().required()
})

export const examOrCourseLevelSchema = object({
  examOrCourseNameId: number().required().min(1),
  name: string().required()
})
