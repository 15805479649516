import React from 'react'
import {
  Col,
  ListGroup,
  NavDropdown,
  Row
} from 'react-bootstrap'
import {
  NavLink,
  Outlet
} from 'react-router-dom'

export const Setting = () => {
  return (
    <Row className='h-100'>
      <Col sm='2' className='mb-3'>
        <h2>設定</h2>
        <ListGroup>
          <ListGroup.Item
            as={NavLink}
            to='email'
          >メールアドレス
          </ListGroup.Item>
          <ListGroup.Item
            as={NavLink}
            to='password'
          >パスワード
          </ListGroup.Item>
        </ListGroup>
      </Col>
      <Col><Outlet /></Col>
    </Row>
  )
}

Setting.NavItem = () => (
  <NavDropdown.Item
    as={NavLink}
    to='/setting'
  >設定
  </NavDropdown.Item>
)
