import React from 'react'
import {
  Alert,
  Button,
  Form,
  Modal
} from 'react-bootstrap'
import { Formik } from 'formik'
import _ from 'lodash'
import { emailSchema } from './validation'

export const ResendConfirmationModal = ({
  show,
  onHide,
  onSubmit,
  error,
  notice,
  onLoginButtonClicked
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
  >
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmit}
      validationSchema={emailSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>確認メール再送</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && error.messages && error.messages.length > 0 &&
              <Alert variant='danger'>{_.map(error.messages, (m, i) => <p key={i}>{m}</p>)}</Alert>}
            {notice &&
              <Alert variant='success'>{notice}</Alert>}
            <Form.Group className='mb-3'>
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type='email'
                name='email'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && errors.email}
                disabled={notice}
              />
              <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Button
              variant='link'
              onClick={onLoginButtonClicked}
            >ログインはこちら
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='button'
              variant='secondary'
              onClick={onHide}
              style={{ display: notice ? null : 'none' }}
            >閉じる
            </Button>
            <Button
              type='submit'
              style={{ display: notice ? 'none' : null }}
              disabled={errors && Object.entries(errors).length > 0}
            >確認メールを再送する
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  </Modal>
)
