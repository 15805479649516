import {
  createSlice,
  createSelector
} from '@reduxjs/toolkit'
import {
  fetchCategories,
  fetchTags
} from './examOrCourseAPI'
import {
  fetchExamOrCourses,
  fetchExamOrCourse,
  createApplication,
  fetchExamOrCourseName,
  fetchExamOrCourseNameSections
} from './browse/browseAPI'
import {
  fetchApplications
} from './application/applicationAPI'

const initialState = {
  browse: {
    openedModal: null,
    list: {
      data: [],
      totalPages: 1
    },
    detail: {
      apply: {}
    }
  },
  application: {
    list: {
      data: [],
      currentPage: 1,
      totalPages: 1,
      sorting: {
        key: 'updatedAt',
        order: 'desc'
      }
    }
  },
  categories: [],
  tags: []
}

const selectState = (state, properties) => {
  if (properties.length === 1) {
    return [state, properties[0]]
  }
  const [head, ...rest] = properties
  return selectState(state[head], rest)
}

export const examOrCourseSlice = createSlice({
  name: 'examOrCourse',
  initialState,
  reducers: {
    selectPage: (state, action) => {
      const { page, propName } = action.payload
      state[propName].list.currentPage = page
    },
    openModal: (state, action) => {
      const { modalSelector, args } = action.payload
      const [targetState, modalName] = selectState(state, modalSelector)
      targetState.openedModal = modalName
      if (args) {
        targetState[modalName].args = args
      }
    },
    closeModal: (state, action) => {
      const [targetState, modalName] = selectState(state, action.payload)
      targetState[modalName] = {}
      targetState.openedModal = null
    }
  },
  extraReducers: builder => builder
    .addCase(fetchExamOrCourses.pending, (state, action) => {
      state.browse.fetching = true
    })
    .addCase(fetchExamOrCourses.fulfilled, (state, action) => {
      state.browse.fetching = false
      const { list, currentPage, totalCount, totalPages } = action.payload
      state.browse.list.data = list
      state.browse.list.currentPage = currentPage
      state.browse.list.totalCount = totalCount
      state.browse.list.totalPages = totalPages
    })
    .addCase(fetchExamOrCourses.rejected, (state, action) => {
      state.browse.fetching = false
    })
    .addCase(fetchExamOrCourse.pending, (state, action) => {
      delete state.browse.detail.examOrCourse
    })
    .addCase(fetchExamOrCourse.fulfilled, (state, action) => {
      state.browse.detail.examOrCourse = action.payload
    })
    .addCase(fetchExamOrCourse.rejected, (state, action) => {
      state.browse.detail.error = action.payload || '検定・講座情報の取得に失敗しました'
    })
    .addCase(createApplication.pending, (state, action) => {
    })
    .addCase(createApplication.fulfilled, (state, action) => {
      const { paymentUrl } = action.payload
      state.browse.detail.apply.paymentUrl = paymentUrl
    })
    .addCase(createApplication.rejected, (state, action) => {
      state.browse.detail.apply.error = action.payload || '検定・講座情報への申し込みに失敗しました'
    })
    .addCase(fetchExamOrCourseName.pending, (state, action) => {
      state.browse.fetching = true
    })
    .addCase(fetchExamOrCourseName.fulfilled, (state, action) => {
      state.browse.fetching = false
      state.browse.detail.examOrCourseName = action.payload
    })
    .addCase(fetchExamOrCourseName.rejected, (state, action) => {
      state.browse.fetching = false
    })
    .addCase(fetchExamOrCourseNameSections.pending, (state, action) => {
      state.browse.fetching = true
    })
    .addCase(fetchExamOrCourseNameSections.fulfilled, (state, action) => {
      state.browse.fetching = false
      const { list } = action.payload
      state.browse.detail.sections = list
    })
    .addCase(fetchExamOrCourseNameSections.rejected, (state, action) => {
      state.browse.fetching = false
    })
    .addCase(fetchApplications.pending, (state, action) => {
      state.application.fetching = true
    })
    .addCase(fetchApplications.fulfilled, (state, action) => {
      state.application.fetching = false
      const { list, currentPage, totalCount, totalPages } = action.payload
      state.application.list.data = list
      state.application.list.currentPage = currentPage
      state.application.list.totalCount = totalCount
      state.application.list.totalPages = totalPages
    })
    .addCase(fetchApplications.rejected, (state, action) => {
      state.application.fetching = false
    })
    .addCase(fetchCategories.pending, (state, action) => {
    })
    .addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload.list
    })
    .addCase(fetchCategories.rejected, (state, action) => {
    })
    .addCase(fetchTags.pending, (state, action) => {
    })
    .addCase(fetchTags.fulfilled, (state, action) => {
      state.tags = action.payload.list
    })
    .addCase(fetchTags.rejected, (state, action) => {
    })
})

export const selectExamOrCourse = state => state.examOrCourse

export const selectBrowse = createSelector(
  selectExamOrCourse,
  examOrCourse => examOrCourse.browse
)

export const selectApplication = createSelector(
  selectExamOrCourse,
  examOrCourse => examOrCourse.application
)

export const selectCategories = createSelector(
  selectExamOrCourse,
  examOrCourse => examOrCourse.categories
)

export const selectTags = createSelector(
  selectExamOrCourse,
  examOrCourse => examOrCourse.tags
)

export const {
  selectPage,
  openModal,
  closeModal
} = examOrCourseSlice.actions
