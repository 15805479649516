import React from 'react'
import { Typeahead } from 'react-bootstrap-typeahead'
import {
  validatePostalCodeInput
} from './validation'

const MenuItem = ({ prefecture, city, rest }) => `${prefecture} ${city} ${rest}`

export const PostalCodeInput = ({
  id,
  defaultInputValue,
  setFieldValue,
  postalCodeFieldName,
  prefectureFieldName,
  cityFieldName,
  addressLineFieldName,
  onInputChange,
  options,
  isInvalid,
  onBlur,
  disabled = false
}) => {
  const handlePostalCodeChange = candidates => {
    const {
      label,
      prefecture,
      city,
      rest
    } = (candidates?.length < 1) ? { label: '', prefecture: '', city: '', rest: '' } : candidates[0]
    setFieldValue(postalCodeFieldName, label, true)
    setFieldValue(prefectureFieldName, prefecture, true)
    setFieldValue(cityFieldName, city, true)
    setFieldValue(addressLineFieldName, rest, true)
  }
  const handleInputChange = query => {
    const validatedQuery = validatePostalCodeInput(query)
    onInputChange(validatedQuery)
  }
  const handlePostalCodeBlur = event => {
    onBlur(event)
    const validatedQuery = validatePostalCodeInput(event.target.value)
    setFieldValue(postalCodeFieldName, validatedQuery)
  }
  return (
    <Typeahead
      defaultInputValue={defaultInputValue}
      id={id}
      className='is-invalid'
      onChange={handlePostalCodeChange}
      onInputChange={handleInputChange}
      options={options}
      isInvalid={isInvalid}
      disabled={disabled}
      inputProps={{ id, name: id, isInvalid }}
      onBlur={handlePostalCodeBlur}
      renderMenuItemChildren={MenuItem}
      emptyLabel='7桁の郵便番号を入力してください'
      filterBy={() => true}
    />
  )
}
