import React from 'react'
import {
  Accordion,
  Button,
  Card,
  Form,
  InputGroup
} from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Formik
} from 'formik'

const CustomToggle = () => (
  <Button
    variant='secondary'
    onClick={useAccordionButton('0')}
  >
    <FontAwesomeIcon icon='sliders' />
  </Button>
)

export const SearchPanel = ({
  onSearch,
  onReset,
  initialValues,
  queryParams,
  queryPropName,
  children
}) => (
  <Accordion>
    <Card>
      <Formik
        initialValues={initialValues}
        onSubmit={onSearch}
        key={queryParams.toString()}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form onSubmit={handleSubmit}>
          <Card.Header className='p-0'>
            <Form.Group>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon='magnifying-glass' />
                </InputGroup.Text>
                <Form.Control
                  type='text'
                  name={queryPropName}
                  value={values[queryPropName]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched[queryPropName] && errors[queryPropName]}
                />
                <CustomToggle />
              </InputGroup>
            </Form.Group>
          </Card.Header>
          <Accordion.Collapse eventKey='0'>
            <>
              <Card.Body>
                {children && children({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit
                })}
              </Card.Body>
              <Card.Footer>
                <Button
                  type='button'
                  variant='secondary'
                  onClick={onReset}
                >クリア
                </Button>{' '}
                <Button type='submit'>検索する</Button>
              </Card.Footer>
            </>
          </Accordion.Collapse>
        </Form>
      )}
      </Formik>
    </Card>
  </Accordion>
)
