import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Alert,
  Button,
  Col,
  Form,
  Row
} from 'react-bootstrap'
import { Formik } from 'formik'
import _ from 'lodash'
import {
  selectUser,
  fetchUser
} from '../auth'
import {
  convertToKatakana,
  PostalCodeInput,
  prefectures
} from '../common'
import {
  aboutYouSchema,
  validatePhoneNumberInput
} from './validation'
import {
  updateProfile,
  searchAddressByPostalCode
} from './profileAPI'
import {
  selectProfile,
  dismissAlert,
  dismissNotice
} from './profileSlice'

export const ProfileInput = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const { version, notice, error, searchByPostalCode } = useSelector(selectProfile)
  const inputRef = useRef(null)
  const [duringComposition, setDuringComposition] = useState(false)
  useEffect(() => {
    if (user) {
      dispatch(fetchUser(user.id))
    }
  }, [version])
  const handleCompositionStart = () => {
    setDuringComposition(true)
  }
  const handleCompositionEnd = () => {
    setDuringComposition(false)
  }
  useEffect(() => {
    inputRef.current.addEventListener('compositionstart', handleCompositionStart)
    inputRef.current.addEventListener('compositionend', handleCompositionEnd)
  }, [])
  const handleSubmit = values => dispatch(updateProfile({
    updateParams: values,
    id: user.id
  }))
  const handleNoticeClose = () => dispatch(dismissNotice())
  const handleAlertClose = () => dispatch(dismissAlert())
  return (
    <Formik
      initialValues={{
        familyName: user?.familyName || '',
        givenName: user?.givenName || '',
        phonicFamilyName: user?.phonicFamilyName || '',
        phonicGivenName: user?.phonicGivenName || '',
        birthDate: user?.birthDate || '',
        postalCode: user?.postalCode || '',
        prefecture: user?.prefecture || '選択してください',
        city: user?.city || '',
        addressLine1: user?.addressLine1 || '',
        addressLine2: user?.addressLine2 || '',
        phoneNumber: user?.phoneNumber || ''
      }}
      onSubmit={handleSubmit}
      validationSchema={aboutYouSchema}
    >
      {({
        dirty,
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col>
              {error &&
                <Alert
                  variant='danger'
                  dismissible
                  onClose={handleAlertClose}
                >
                  {error.message && error.message}
                  {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
                </Alert>}
              {notice &&
                <Alert
                  variant='success'
                  dismissible
                  onClose={handleNoticeClose}
                >{notice}
                </Alert>}
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>あなたについて</h4>
            </Col>
            <Col sm='auto'>
              <Button
                type='sumbit'
                disabled={!user || !dirty || (errors && Object.entries(errors).length > 0)}
              >保存する
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs='6'>
              <Form.Group className='mb-3'>
                <Form.Label>姓</Form.Label>
                <Form.Control
                  type='text'
                  name='familyName'
                  value={values.familyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.familyName && errors.familyName}
                />
                <Form.Control.Feedback type='invalid'>{errors.familyName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs='6'>
              <Form.Group className='mb-3'>
                <Form.Label>名</Form.Label>
                <Form.Control
                  type='text'
                  name='givenName'
                  value={values.givenName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.givenName && errors.givenName}
                />
                <Form.Control.Feedback type='invalid'>{errors.givenName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs='6'>
              <Form.Group className='mb-3'>
                <Form.Label>姓カナ</Form.Label>
                <Form.Control
                  type='text'
                  name='phonicFamilyName'
                  value={values.phonicFamilyName}
                  onChange={handleChange}
                  onBlur={event => {
                    event.target.value = convertToKatakana(event.target.value)
                    handleBlur(event)
                    handleChange(event)
                  }}
                  isInvalid={touched.phonicFamilyName && errors.phonicFamilyName}
                />
                <Form.Control.Feedback type='invalid'>{errors.phonicFamilyName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs='6'>
              <Form.Group className='mb-3'>
                <Form.Label>名カナ</Form.Label>
                <Form.Control
                  type='text'
                  name='phonicGivenName'
                  value={values.phonicGivenName}
                  onChange={handleChange}
                  onBlur={event => {
                    event.target.value = convertToKatakana(event.target.value)
                    handleBlur(event)
                    handleChange(event)
                  }}
                  isInvalid={touched.phonicGivenName && errors.phonicGivenName}
                />
                <Form.Control.Feedback type='invalid'>{errors.phonicGivenName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>生年月日</Form.Label>
                <Form.Control
                  type='date'
                  name='birthDate'
                  value={values.birthDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.birthDate && errors.birthDate}
                />
                <Form.Control.Feedback type='invalid'>{errors.birthDate}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>住所</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>郵便番号</Form.Label>
                <PostalCodeInput
                  id='postalCode'
                  defaultInputValue={values.postalCode}
                  setFieldValue={setFieldValue}
                  postalCodeFieldName='postalCode'
                  prefectureFieldName='prefecture'
                  cityFieldName='city'
                  addressLineFieldName='addressLine1'
                  onInputChange={query => dispatch(searchAddressByPostalCode(query))}
                  options={searchByPostalCode.candidates}
                  isInvalid={touched.postalCode && !!errors.postalCode}
                  onBlur={handleBlur}
                />
                <Form.Control.Feedback type='invalid'>{errors.postalCode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>都道府県</Form.Label>
                <Form.Control
                  as='select'
                  name='prefecture'
                  value={values.prefecture}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.prefecture && errors.prefecture}
                >{_.map(_.concat(['選択してください'], prefectures), (value, i) => <option key={i} value={value}>{value}</option>)}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>{errors.prefecture}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>市区町村</Form.Label>
                <Form.Control
                  type='text'
                  name='city'
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.city && errors.city}
                />
                <Form.Control.Feedback type='invalid'>{errors.city}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>丁目・番地・号</Form.Label>
                <Form.Control
                  type='text'
                  name='addressLine1'
                  value={values.addressLine1}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.addressLine1 && errors.addressLine1}
                />
                <Form.Control.Feedback type='invalid'>{errors.addressLine1}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>建物名・部屋番号</Form.Label>
                <Form.Control
                  type='text'
                  name='addressLine2'
                  value={values.addressLine2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.addressLine2 && errors.addressLine2}
                />
                <Form.Control.Feedback type='invalid'>{errors.addressLine2}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>連絡先</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>電話番号</Form.Label>
                <Form.Control
                  ref={inputRef}
                  type='text'
                  name='phoneNumber'
                  value={values.phoneNumber}
                  onChange={event => {
                    handleChange(event)
                    if (!duringComposition) {
                      const validatedValue = validatePhoneNumberInput(event.target.value)
                      setFieldValue('phoneNumber', validatedValue)
                    }
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.phoneNumber && errors.phoneNumber}
                />
                <Form.Control.Feedback type='invalid'>{errors.phoneNumber}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
