import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  csrfToken,
  toSnake,
  fetchTagsPayloadCreator,
  handleError
} from '../../common'
import { invalidateCurrentUser } from '../../auth'

export const createTag = createAsyncThunk(
  'admin/tag/createTag',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}admin/tags`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake(params))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const updateTag = createAsyncThunk(
  'admin/tag/updateTag',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id, ...updateParams } = params
    return fetch(`${baseUri}admin/tags/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake(updateParams))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const deleteTag = createAsyncThunk(
  'admin/tag/deleteTag',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id } = params
    return fetch(`${baseUri}admin/tags/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const fetchTags = createAsyncThunk(
  'admin/tag/fetchTags',
  fetchTagsPayloadCreator(invalidateCurrentUser)
)
