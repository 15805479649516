import React, { useEffect } from 'react'
import {
  Button,
  Container
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Search } from 'react-bootstrap-icons'
import _ from 'lodash'
import {
  fetchCategories,
  fetchTags
} from './homeAPI'
import {
  selectCategories,
  selectTags
} from './homeSelectors'

export const Home = () => {
  const dispatch = useDispatch()
  const categories = useSelector(selectCategories)
  const tags = useSelector(selectTags)

  useEffect(() => {
    dispatch(fetchCategories({ perPage: 1024 }))
    dispatch(fetchTags({ perPage: 1024 }))
  }, [])

  return (
    <>
      <h1>リモーズ 検定試験の申込みサイト ～ここから学びを始めよう～</h1>
      <div className='remoes-contents pt-4 pb-4'>
        <Container as='section' className='mt-4'>
          <h2 className='remoes-h2'>
            <Search className='bi' /> 試験・検定を探す
          </h2>
        </Container>
        <Container as='section' className='mt-4'>
          <div className='border rounded-1 bg-white shadow'>
            <h3 className='remoes-h3'>ジャンルで探す</h3>
            <ul className='list-unstyled d-flex flex-wrap p-3 mb-0 category-list'>
              {_.map(
                categories,
                ({ id, name }) => (
                  <li className='p-1 category-list-item'>
                    <Link to={`/examOrCourse/browse?${new URLSearchParams([['categories[]', id]]).toString()}`}>
                      <Button variant='outline-dark'>{name}</Button>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </Container>
        <Container as='section' className='mt-4'>
          <div className='border rounded-1 bg-white shadow'>
            <h3 className='remoes-h3'>特徴で探す</h3>
            <ul className='list-unstyled d-flex flex-wrap p-3 mb-0 tag-list'>
              {_.map(
                tags,
                ({ id, name }) => (
                  <li className='p-1 tag-list-item'>
                    <Link to={`/examOrCourse/browse?${new URLSearchParams([['tags[]', id]]).toString()}`}>
                      <Button variant='outline-dark'>{name}</Button>
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </Container>
      </div>
    </>
  )
}
