import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useSearchParams
} from 'react-router-dom'
import {
  Button,
  Col,
  Container,
  ListGroup,
  Row
} from 'react-bootstrap'
import _ from 'lodash'
import {
  Pagination,
  parseQueryAsInt,
  updateQueryParamEntries,
  removeQueryParamKeys,
  examOrCourseDisplay,
  selectBaseUri,
  objectToQuerystring
} from '../../common'
import { selectUser } from '../../auth'
import {
  OperationOrganizationDropdown
} from '../OperationOrganizationDropdown'
import {
  ExamOrCourseDropdown
} from '../ExamOrCourseDropdown'
import {
  selectVenueExecution,
  openModal,
  closeModal,
  clearAllLists
} from '../adminSlice'
import {
  VenueExecutionSearchPanel
} from './SearchPanel'
import {
  fetchExamOrCourses,
  fetchVenueExecutions,
  searchAddressByPostalCode,
  createVenueExecution,
  updateVenueExecution
} from './venueExecutionAPI'
import { UpsertModal } from './UpsertModal'

const VenueExecutionRow = ({
  venueExecution,
  onEditClick
}) => (
  <Row>
    <h5>{venueExecution.operationOrganization.name} / {venueExecution.name}</h5>
    <Col>
      <dl>
        <dt>郵便番号・住所</dt>
        <dd>{venueExecution.postalCode} {venueExecution.prefecture} {venueExecution.city} {venueExecution.addressLine1} {venueExecution.addressLine2}</dd>
        <dt>会場を使用する検定・講座</dt>
        <dd>
          <ul>
            {_.map(
              venueExecution.examOrCourses,
              (examOrCourse, index) => (
                <li key={index}>{examOrCourseDisplay(examOrCourse)}</li>
              ))}
          </ul>
        </dd>
      </dl>
    </Col>
    <Col>
      <dl>
        <dt>定員</dt>
        <dd>{venueExecution.capacity}</dd>
        <dt>申し込み数</dt>
        <dd>{venueExecution.numberOfApplicants}</dd>
      </dl>
    </Col>
    <Col sm='auto'>
      <Button
        variant='light'
        onClick={onEditClick}
      >編集
      </Button>
    </Col>
  </Row>
)

const fetchExamOrCoursesParams = operationOrganization =>
  !operationOrganization || operationOrganization === 'all'
    ? {}
    : { operationOrganizationId: operationOrganization }

const fetchVenueExecutionsParams = (operationOrganization, examOrCourseId, page, filtering) =>
  _.omitBy(
    _.merge(
      fetchExamOrCoursesParams(operationOrganization),
      { examOrCourseId, page },
      filtering),
    (value) => !value || value === 'all')

const venueExecutionSearchKeys = ['query']
const parseQueryToFiltering = queryParams =>
  Object.fromEntries(
    _.filter(
      [...queryParams.entries()],
      ([key, _value]) => venueExecutionSearchKeys.includes(key)))

export const VenueExecutionList = () => {
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useSearchParams()
  const user = useSelector(selectUser)
  const {
    version,
    list,
    openedModal,
    upsert,
    fetching
  } = useSelector(selectVenueExecution)
  const selectedOperationOrganization = parseQueryAsInt(queryParams, 'operationOrganization')
  const selectedExamOrCourse = parseQueryAsInt(queryParams, 'examOrCourse')
  const currentPage = parseQueryAsInt(queryParams, 'page')
  const filtering = parseQueryToFiltering(queryParams)
  const baseUri = useSelector(selectBaseUri)

  useEffect(() => {
    if (!user) {
      dispatch(clearAllLists())
    }
  }, [user])

  useEffect(() => {
    if (!user) return

    dispatch(fetchExamOrCourses(fetchExamOrCoursesParams(selectedOperationOrganization)))
  }, [user, selectedOperationOrganization])

  useEffect(() => {
    if (!user) return

    const filtering = parseQueryToFiltering(queryParams)
    dispatch(fetchVenueExecutions(fetchVenueExecutionsParams(selectedOperationOrganization, selectedExamOrCourse, currentPage, filtering)))
  }, [user, selectedOperationOrganization, selectedExamOrCourse, version, currentPage, queryParams])

  const handlePaginationClick = page => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['page', page]]),
      { replace: true })
  const handleSearch = values =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        Object.entries(values)),
      { replace: true })
  const handleSelectOperationOrganization = operationOrganization => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['operationOrganization', operationOrganization], ['examOrCourse', 'all']]),
      { replace: true })
  const handleSelectExamOrCourse = examOrCourseId => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['examOrCourse', examOrCourseId]]),
      { replace: true })
  const handleReset = () =>
    setQueryParams(
      removeQueryParamKeys(
        queryParams,
        venueExecutionSearchKeys),
      { replace: true })
  const handleOpenModal = modalSelector => args => () => dispatch(openModal({ modalSelector, args }))
  const handleHideModal = modalSelector => () => dispatch(closeModal(modalSelector))
  const handlePostalCodeInputChange = postalCode => dispatch(searchAddressByPostalCode(postalCode))
  const handleSubmit = id => values => dispatch((id ? updateVenueExecution : createVenueExecution)(_.merge(values, { id })))
  return (
    <>
      <UpsertModal
        show={openedModal === 'upsert'}
        onHide={handleHideModal(['venueExecution', 'upsert'])}
        fetching={fetching}
        notice={upsert.notice}
        error={upsert.error}
        venueExecution={upsert.args}
        onSubmit={handleSubmit(upsert.args?.id)}
        onPostalCodeInputChange={handlePostalCodeInputChange}
        addressCandidates={upsert.searchByPostalCode.candidates}
        operationOrganizations={user?.operationOrganizations}
      />
      <Container>
        <Row className='mb-2'>
          <Col>
            <VenueExecutionSearchPanel
              queryParams={queryParams}
              onSearch={handleSearch}
              onReset={handleReset}
              initialValues={filtering}
            />
          </Col>
          <Col sm='auto'>
            <OperationOrganizationDropdown
              className='operation-organization-dropdown'
              operationOrganizations={user?.operationOrganizations}
              selected={selectedOperationOrganization || 'all'}
              onSelectOperationOrganization={handleSelectOperationOrganization}
            />
          </Col>
          <Col sm='auto'>
            <ExamOrCourseDropdown
              className='exam-or-course-dropdown'
              examOrCourses={list.examOrCourses}
              selected={selectedExamOrCourse || 'all'}
              onSelectExamOrCourse={handleSelectExamOrCourse}
            />
          </Col>
          <Col sm='auto'>
            <Button
              onClick={handleOpenModal(['venueExecution', 'upsert'])()}
            >新規作成
            </Button>
          </Col>
          <Col sm='auto'>
            <Button
              href={`${baseUri}admin/venue_executions?${objectToQuerystring(_.merge(_.omit(fetchVenueExecutionsParams(selectedOperationOrganization, selectedExamOrCourse, currentPage, filtering), ['page']), { format: 'xlsx' }))}`}
            >ダウンロード
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              {list && <small>{list.totalCount}件</small>}
              <ListGroup variant='flush' className='venue-execution-list'>
                {_.map(
                  list.data,
                  (venueExecution, index) => (
                    <ListGroup.Item key={index}>
                      <VenueExecutionRow
                        venueExecution={venueExecution}
                        onEditClick={handleOpenModal(['venueExecution', 'upsert'])(venueExecution)}
                      />
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Container>
            <Pagination
              currentPage={currentPage || 1}
              totalPages={list.totalPages}
              onPaginationClick={handlePaginationClick}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
