const HEADER_CELLS = [
  'GOKAKUSYO_CD_1', 'GOKAKUSYO_CD_2', 'SHIKEN_KAISU', 'JUKEN_NO', 'NAME_SEI', 'NAME_MEI',
  'BIRTH', 'SHIKEN_DATE', 'SHIKEN_DATE_Y', 'SHIKEN_DATE_M', 'SHIKEN_DATE_D', 'SHIKEN_NAME', 'KYU_NAME']

const validateCSV = (csvText) => {
  const lines = csvText.trimEnd().split(/\r?\n|\r/g)

  if (lines.length === 0) {
    return ['空のファイルです']
  }

  const headers = lines[0].split(',')

  if (headers.length < HEADER_CELLS.length) {
    return ['列の数が足りません']
  }

  if (HEADER_CELLS.every((h, i) => h.localeCompare(headers[i].replace(/^"/g, '').replace(/"$/g, '')) !== 0)) {
    return ['CSVヘッダーが正しくない列があります']
  }

  if (lines.length === 1) {
    return ['データ行が存在しません']
  }

  const validationResults = lines.slice(1).flatMap((line, i) => {
    const cells = line.split(',')
    if (cells.length < HEADER_CELLS.length) {
      return [`行${i + 1}: 列の数が足りません`]
    }
    return []
  })

  return validationResults
}

export const validateInsertCSV = validateCSV
export const validateUpdateCSV = validateCSV
export const validateDeleteCSV = validateCSV
