import React from 'react'
import {
  Container,
  Row,
  Col,
  Table
} from 'react-bootstrap'
import moment from 'moment'

import {
  Pagination
} from '../../common'

moment.locale('ja')

const CertificateRow = (certificate) => {
  return (
    <tr key={certificate.id}>
      <td style={{ whiteSpace: 'nowrap', minWidth: '10em' }}>{certificate.certificateCode1}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '10em' }}>{certificate.examineeCode}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '9em' }}>{certificate.examineeLastName}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '9em' }}>{certificate.examineeFirstName}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '9em' }}>
        {
          certificate.examineeBirthDay
            ? moment(certificate.examineeBirthDay).format('LL')
            : '(未入力)'
        }
      </td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '4em' }}>{certificate.examinationGrade}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '18em' }}>{certificate.examinationName}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '4em' }}>{certificate.examinationStage}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '9em' }}>{moment(certificate.certificatedAt).format('LL')}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '12em' }}>{moment(certificate.createdAt).format('LLL')}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '12em' }}>{moment(certificate.administratedAt).format('LLL')}</td>
      <td style={{ whiteSpace: 'nowrap', minWidth: '18em' }} className='cert-last-operator'>
        {certificate.lastOperator || ''}
      </td>
      <td style={{ whiteSpace: 'nowrap' }}>
        <a className='cert-card' href={certificate.directUrl1} target='_blank' rel='noreferrer'>合格証</a>
      </td>
      <td style={{ whiteSpace: 'nowrap' }}>
        <a className='cert-doc' href={certificate.directUrl2} target='_blank' rel='noreferrer'>合格証明書</a>
      </td>
    </tr>
  )
}

export const CertificateList = ({
  certificates,
  totalCount,
  currentPage,
  totalPages,
  onPaginationClick,
  ...props
}) => {
  return (
    <div>
      <Container>
        <Row>
          <Col md={2} lg={2}>
            {totalCount}件
          </Col>
          <Col md={8}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPaginationClick={onPaginationClick}
            />
          </Col>
          <Col md={2} lg={2} />
        </Row>
      </Container>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table className='certificate-table' striped bordered hover style={{ backgroundColor: '#fff' }}>
          <thead>
            <tr>
              <th style={{ whiteSpace: 'nowrap' }}>認証ID</th>
              <th style={{ whiteSpace: 'nowrap' }}>受験番号</th>
              <th style={{ whiteSpace: 'nowrap' }}>氏名_姓</th>
              <th style={{ whiteSpace: 'nowrap' }}>氏名_名</th>
              <th style={{ whiteSpace: 'nowrap' }}>生年月日</th>
              <th style={{ whiteSpace: 'nowrap' }}>級</th>
              <th style={{ whiteSpace: 'nowrap' }}>検定試験名称</th>
              <th style={{ whiteSpace: 'nowrap' }}>試験回数</th>
              <th style={{ whiteSpace: 'nowrap' }}>試験日</th>
              <th style={{ whiteSpace: 'nowrap' }}>新規登録日時</th>
              <th style={{ whiteSpace: 'nowrap' }}>最終更新日時</th>
              <th style={{ whiteSpace: 'nowrap' }}>更新ユーザー名</th>
              <th style={{ whiteSpace: 'nowrap' }}>ダイレクトURL1</th>
              <th style={{ whiteSpace: 'nowrap' }}>ダイレクトURL2</th>
            </tr>
          </thead>
          <tbody>
            {certificates.map((certificate) => CertificateRow(certificate))}
          </tbody>
        </Table>
      </div>
      <Container>
        <Row>
          <Col md={2} lg={2} />
          <Col md={8} lg={8}>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPaginationClick={onPaginationClick}
            />
          </Col>
          <Col md={2} lg={2} />
        </Row>
      </Container>
    </div>
  )
}
