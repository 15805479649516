import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import _ from 'lodash'
import {
  csrfToken,
  objectToQuerystring,
  handleError
} from '../../common'
import { invalidateCurrentUser } from '../../auth'
import {
  fetchExamOrCoursesPayloadCreator
} from '../adminAPI'

export const fetchExamOrCourses = createAsyncThunk(
  'admin/application/fetchExamOrCourses',
  fetchExamOrCoursesPayloadCreator
)

export const fetchApplications = createAsyncThunk(
  'admin/application/fetchApplications',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}admin/applications?${objectToQuerystring(params)}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const uploadExamineeNumber = createAsyncThunk(
  'admin/application/uploadExamineeNumber',
  async ({ examineeNumberCsv, skipFirstRow }, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const formData = new FormData() // eslint-disable-line no-undef
    formData.append('examinee_number_csv', examineeNumberCsv, examineeNumberCsv.name)
    formData.append('skip_first_row', skipFirstRow)
    return fetch(`${baseUri}admin/applications/examinee_number`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: formData
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          if (contentType && contentType.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            const a = document.createElement('a')
            a.style.display = 'none'
            response.blob().then(data => {
              a.href = window.URL.createObjectURL(data)
              a.download = '受検番号登録エラー.xlsx'
              document.body.appendChild(a)
              a.click()
              a.remove()
              window.URL.revokeObjectURL(a.href)
            })
            error.messages = ['受検番号の更新に失敗したデータがあります。' +
                              'ダウンロードしたファイル"受検番号登録エラー.xlsx"を確認してください。']
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const cancelApplication = createAsyncThunk(
  'admin/application/cancelApplication',
  async (applicationId, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}admin/applications/${applicationId}/cancel`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)
