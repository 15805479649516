import {
  object,
  string,
  number
} from 'yup'

export const operationOrganizationSchema = object({
  name: string().required(),
  phonicName: string().required(),
  notificationEmail: string().email().required(),
  contactPersonId: number().positive().required()
})
