import React from 'react'
import {
  Dropdown,
  DropdownButton
} from 'react-bootstrap'
import _ from 'lodash'
import { examOrCourseDisplay } from '../common'

export const ExamOrCourseDropdown = ({
  examOrCourses,
  selected,
  onSelectExamOrCourse,
  className
}) => (
  <DropdownButton
    variant='secondary'
    title={examOrCourseDisplay(_.find(examOrCourses, { id: selected })) ?? '全て'}
    className={className}
  >
    <Dropdown.Item
      onClick={onSelectExamOrCourse('all')}
      active={selected === 'all'}
    >全て
    </Dropdown.Item>
    {examOrCourses &&
    _.map(
      examOrCourses,
      (examOrCourse, index) => (
        <Dropdown.Item
          key={index}
          onClick={onSelectExamOrCourse(examOrCourse.id)}
          active={selected === examOrCourse.id}
        >
          {examOrCourseDisplay(examOrCourse)}
        </Dropdown.Item>
      ))}
  </DropdownButton>
)
