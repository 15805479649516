import { createSelector } from '@reduxjs/toolkit'

export const selectHome = state => state.home

export const selectCategories = createSelector(
  selectHome,
  home => home.categories
)

export const selectTags = createSelector(
  selectHome,
  home => home.tags
)
