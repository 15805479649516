import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useSearchParams
} from 'react-router-dom'
import {
  Button,
  Col,
  Container,
  ListGroup,
  Row
} from 'react-bootstrap'
import _ from 'lodash'
import {
  Pagination,
  parseQueryAsInt,
  updateQueryParamEntries,
  removeQueryParamKeys
} from '../../common'
import { selectUser } from '../../auth'
import {
  openModal,
  closeModal,
  clearAllLists
} from '../adminSlice'
import SearchPanel from './SearchPanel'
import UpsertModal from './UpsertModal'
import DeleteModal from './DeleteModal'
import { selectTag } from './tagSelectors'
import {
  createTag,
  updateTag,
  deleteTag,
  fetchTags
} from './tagAPI'

const TagRow = ({
  name,
  onEditClick,
  onDeleteClick
}) => (
  <Row>
    <Col>
      <h5>
        {name}
      </h5>
    </Col>
    <Col sm='auto'>
      <Button
        variant='light'
        onClick={onEditClick}
      >編集
      </Button>
    </Col>
    <Col sm='auto'>
      <Button
        variant='danger'
        onClick={onDeleteClick}
      >削除
      </Button>
    </Col>
  </Row>
)

const upsertModalSelector = ['tag', 'upsert']
const deleteModalSelector = ['tag', 'delete']

const fetchTagsParams = (page, filtering) =>
  _.omitBy(
    _.merge(
      { page },
      filtering),
    (value) => !value || value === 'all')

const searchKeys = ['query']
const parseQueryToFiltering = queryParams =>
  Object.fromEntries(
    _.filter(
      [...queryParams.entries()],
      ([key, _value]) => searchKeys.includes(key)))

export default () => {
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useSearchParams()
  const user = useSelector(selectUser)
  const {
    version,
    list,
    openedModal,
    upsert,
    delete: destroy,
    fetching
  } = useSelector(selectTag)
  const currentPage = parseQueryAsInt(queryParams, 'page')
  const filtering = parseQueryToFiltering(queryParams)

  useEffect(() => {
    if (!user) {
      dispatch(clearAllLists())
    }
  }, [user])

  useEffect(() => {
    if (!user) return

    const filtering = parseQueryToFiltering(queryParams)
    dispatch(fetchTags(fetchTagsParams(currentPage, filtering)))
  }, [user, version, currentPage, queryParams])

  const handlePaginationClick = page => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['page', page]]),
      { replace: true })
  const handleSearch = values =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        Object.entries(values)),
      { replace: true })
  const handleReset = () =>
    setQueryParams(
      removeQueryParamKeys(
        queryParams,
        searchKeys),
      { replace: true })
  const handleOpenModal = args => () => dispatch(openModal({ modalSelector: upsertModalSelector, args }))
  const handleHideModal = () => dispatch(closeModal(upsertModalSelector))
  const handleOpenDeleteModal = args => () => dispatch(openModal({ modalSelector: deleteModalSelector, args }))
  const handleHideDeleteModal = () => dispatch(closeModal(deleteModalSelector))
  const handleSubmit = currentTag => values => {
    const action = currentTag ? updateTag : createTag
    dispatch(action(_.merge(values, { id: currentTag?.id })))
  }
  const handleDeleteSubmit = currentTag =>
    () => dispatch(deleteTag({ id: currentTag.id }))
  return (
    <>
      <UpsertModal
        show={openedModal === 'upsert'}
        onHide={handleHideModal}
        onSubmit={handleSubmit(upsert.args)}
        fetching={fetching}
        notice={upsert.notice}
        error={upsert.error}
        tag={upsert.args}
      />
      <DeleteModal
        show={openedModal === 'delete'}
        onHide={handleHideDeleteModal}
        onSubmit={handleDeleteSubmit(destroy.args)}
        error={destroy.error}
        notice={destroy.notice}
        fetching={fetching}
        tag={destroy.args}
      />
      <Container>
        <Row className='mb-2'>
          <Col>
            <SearchPanel
              queryParams={queryParams}
              onSearch={handleSearch}
              onReset={handleReset}
              initialValues={filtering}
            />
          </Col>
          <Col sm='auto'>
            <Button
              onClick={handleOpenModal()}
            >新規作成
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              {list && <small>{list.totalCount}件</small>}
              <ListGroup variant='flush' className='tag-list'>
                {_.map(
                  list.data,
                  (tag) => (
                    <ListGroup.Item key={tag.id}>
                      <TagRow
                        {...tag}
                        onEditClick={handleOpenModal(tag)}
                        onDeleteClick={handleOpenDeleteModal(tag)}
                      />
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Container>
            <Pagination
              currentPage={currentPage || 1}
              totalPages={list.totalPages}
              onPaginationClick={handlePaginationClick}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
