import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useSearchParams
} from 'react-router-dom'
import {
  Accordion,
  Button,
  Col,
  Container,
  ListGroup,
  Row,
  Image
} from 'react-bootstrap'
import _ from 'lodash'
import {
  Pagination,
  parseQueryAsInt,
  updateQueryParamEntries,
  removeQueryParamKeys
} from '../../common'
import { selectUser } from '../../auth'
import {
  openModal,
  closeModal,
  clearAllLists
} from '../adminSlice'
import {
  OperationOrganizationDropdown
} from '../OperationOrganizationDropdown'
import { selectExamOrCourseName } from './examOrCourseNameSelectors'
import {
  fetchExamOrCourseNames,
  createExamOrCourseName,
  updateExamOrCourseName,
  createExamOrCourseLevel,
  updateExamOrCourseLevel,
  fetchCategoryCandidates,
  fetchTagsCandidates,
  fetchExamOrCourseNameSections,
  fetchExamOrCourseNameExplanatoryItems
} from './examOrCourseNameAPI'
import { ExamOrCourseNameSearchPanel } from './SearchPanel'
import { UpsertModal } from './UpsertModal'
import { UpsertLevelModal } from './UpsertLevelModal'

const ExamOrCourseLevelRow = ({
  name,
  onEditClick
}) => (
  <Row>
    <Col>{name}</Col>
    <Col sm='auto'>
      <Button
        variant='light'
        onClick={onEditClick}
      >
        編集
      </Button>
    </Col>
  </Row>
)

const ExamOrCourseNameRow = ({
  examOrCourseName,
  onEditClick,
  onCreateLevelClick,
  onEditLevelClick
}) => (
  <>
    <Row>
      <h5>{examOrCourseName.operationOrganization.name} / {examOrCourseName.value}</h5>
      <Col>
        <dl>
          <dt>試験・講座名カナ</dt>
          <dd>{examOrCourseName.phonicName ?? '(未設定)'}</dd>
          <dt>ジャンル</dt>
          <dd>{examOrCourseName.category?.name ?? '(未設定)'}</dd>
          <dt>概要</dt>
          <dd>{examOrCourseName.description ?? '(未設定)'}</dd>
          <dt>ロゴ画像</dt>
          <dd>{examOrCourseName.logoImage ? <Image fluid src={examOrCourseName.logoImage} /> : '(未設定)'}</dd>
        </dl>
      </Col>
      <Col>
        <dl>
          <dt>タグ</dt>
          <dd>
            {examOrCourseName.tags.length > 0
              ? (
                <ul>
                  {_.map(
                    examOrCourseName.tags,
                    ({ id, name }) => (
                      <li key={id}>{name}</li>
                    ))}
                </ul>
                )
              : '(未設定)'}
          </dd>
        </dl>
      </Col>
      <Col sm='auto'>
        <Button
          variant='light'
          onClick={onEditClick}
        >編集
        </Button>
      </Col>
    </Row>
    <Row>
      <Col>
        <Accordion>
          <Accordion.Item eventKey='0'>
            <Accordion.Header>級・レベル</Accordion.Header>
            <Accordion.Body>
              <ListGroup variant='flush' className='exam-or-course-level-list'>
                {_.map(
                  examOrCourseName.levels,
                  level => (
                    <ListGroup.Item key={level.id}>
                      <ExamOrCourseLevelRow
                        {...level}
                        onEditClick={onEditLevelClick({ ...level, examOrCourseNameId: examOrCourseName.id })}
                      />
                    </ListGroup.Item>
                  ))}
              </ListGroup>
              <Button onClick={onCreateLevelClick}>新規作成</Button>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  </>
)

const fetchExamOrCourseNamesParams = (page, filtering) =>
  _.omitBy(
    _.merge(
      { page },
      filtering),
    (value) => !value || value === 'all')

const examOrCourseNameSearchKeys = { query: String, operationOrganizationId: Number }
const parseQueryToFiltering = queryParams =>
  _.omitBy(
    Object.fromEntries(
      _.map(
        _.filter(
          [...queryParams.entries()],
          ([key, _value]) => Object.keys(examOrCourseNameSearchKeys).includes(key)),
        ([key, value]) =>
          examOrCourseNameSearchKeys[key] === Number
            ? [key, Number.parseInt(value)]
            : [key, value])),
    value => !value)

export const ExamOrCourseNameList = () => {
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useSearchParams()
  const user = useSelector(selectUser)
  const {
    version,
    list,
    openedModal,
    upsert,
    upsertLevel,
    fetching,
    sections
  } = useSelector(selectExamOrCourseName)
  const currentPage = parseQueryAsInt(queryParams, 'page')
  const filtering = parseQueryToFiltering(queryParams)

  useEffect(() => {
    if (!user) {
      dispatch(clearAllLists())
    }
  }, [user])

  useEffect(() => {
    if (!user) return

    const filtering = parseQueryToFiltering(queryParams)
    dispatch(fetchExamOrCourseNames(fetchExamOrCourseNamesParams(currentPage, filtering)))
  }, [user, version, currentPage, queryParams])

  useEffect(() => {
    dispatch(fetchExamOrCourseNameSections({ perPage: 1024, sorting: { key: 'order', order: 'asc' } }))
  }, [])

  const handleSelectOperationOrganization = operationOrganization => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['operationOrganizationId', operationOrganization]]),
      { replace: true })
  const handlePaginationClick = page => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['page', page]]),
      { replace: true })
  const handleSearch = values =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        Object.entries(values)),
      { replace: true })
  const handleReset = () =>
    setQueryParams(
      removeQueryParamKeys(
        queryParams,
        _.reject(Object.keys(examOrCourseNameSearchKeys), key => key === 'operationOrganizationId')),
      { replace: true })
  const handleOpenModal = modalSelector => args => () => dispatch(openModal({ modalSelector, args }))
  const handleHideModal = modalSelector => () => dispatch(closeModal(modalSelector))
  const handleEditClick = examOrCourseName => () => {
    dispatch(fetchExamOrCourseNameExplanatoryItems({ examOrCourseNameId: examOrCourseName.id })).then(({ payload }) => {
      const { list } = payload
      const explanatoryItems = _.reduce(
        list,
        (accumulated, { examOrCourseNameSectionId, ...explanatoryItem }) =>
          _.merge(
            accumulated,
            {
              [examOrCourseNameSectionId]: [
                ..._.has(accumulated, [examOrCourseNameSectionId]) ? accumulated[examOrCourseNameSectionId] : [],
                explanatoryItem
              ]
            }),
        {})
      handleOpenModal(['examOrCourseName', 'upsert'])({ ...examOrCourseName, explanatoryItems })()
    })
  }
  const handleSubmit = id => values => {
    const { explanatoryItems, ...rest } = values
    const explanatoryItemsAttributes =
      _.flatMap(
        _.toPairs(explanatoryItems),
        ([sectionId, explanatoryItems]) =>
          _.map(
            explanatoryItems,
            explanatoryItem => ({ ...explanatoryItem, examOrCourseNameSectionId: sectionId })))
    const params = _.merge(rest, { id, explanatoryItemsAttributes })
    dispatch((id ? updateExamOrCourseName : createExamOrCourseName)(params))
  }
  const handleSubmitLevel = id => values => dispatch((id ? updateExamOrCourseLevel : createExamOrCourseLevel)(_.merge(values, { id })))
  const handleCategoryInputChange = ({ preDispatchCallback }) => query => {
    if (preDispatchCallback) {
      preDispatchCallback()
    }
    dispatch(fetchCategoryCandidates({ query }))
  }
  const handleCategorySelected = setCategory => selectedCategories => {
    if (selectedCategories.length < 1) {
      return
    }
    setCategory(selectedCategories[0])
  }
  const handleTagsSelected = setTags => selectedTags => {
    setTags(selectedTags)
  }
  const handleTagsInputChange = ({ preDispatchCallback }) => query => {
    if (preDispatchCallback) {
      preDispatchCallback()
    }
    dispatch(fetchTagsCandidates({ query }))
  }
  return (
    <>
      <UpsertModal
        show={openedModal === 'upsert'}
        onHide={handleHideModal(['examOrCourseName', 'upsert'])}
        fetching={fetching}
        notice={upsert.notice}
        error={upsert.error}
        examOrCourseName={upsert.args}
        onSubmit={handleSubmit(upsert.args?.id)}
        operationOrganizations={user?.operationOrganizations}
        onCategoryInputChange={handleCategoryInputChange}
        categoryCandidates={upsert.categoryCandidates}
        onCategorySelected={handleCategorySelected}
        onTagsInputChange={handleTagsInputChange}
        tagsCandidates={upsert.tagsCandidates}
        onTagsSelected={handleTagsSelected}
        sections={sections}
      />
      <UpsertLevelModal
        show={openedModal === 'upsertLevel'}
        onHide={handleHideModal(['examOrCourseName', 'upsertLevel'])}
        fetching={fetching}
        notice={upsertLevel.notice}
        error={upsertLevel.error}
        onSubmit={handleSubmitLevel(upsertLevel.args?.id)}
        examOrCourseLevel={upsertLevel.args}
      />
      <Container>
        <Row className='mb-2'>
          <Col>
            <ExamOrCourseNameSearchPanel
              queryParams={queryParams}
              onSearch={handleSearch}
              onReset={handleReset}
              initialValues={_.merge({ query: '' }, filtering)}
            />
          </Col>
          <Col sm='auto'>
            <OperationOrganizationDropdown
              operationOrganizations={user?.operationOrganizations}
              selected={filtering.operationOrganizationId}
              onSelectOperationOrganization={handleSelectOperationOrganization}
            />
          </Col>
          <Col sm='auto'>
            <Button
              onClick={handleOpenModal(['examOrCourseName', 'upsert'])()}
            >新規作成
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container>
              {list && <small>{list.totalCount}件</small>}
              <ListGroup variant='flush' className='exam-or-course-name-list'>
                {_.map(
                  list.data,
                  (examOrCourseName) => (
                    <ListGroup.Item key={examOrCourseName.id}>
                      <ExamOrCourseNameRow
                        examOrCourseName={examOrCourseName}
                        onEditClick={handleEditClick(examOrCourseName)}
                        onCreateLevelClick={handleOpenModal(['examOrCourseName', 'upsertLevel'])({ examOrCourseNameId: examOrCourseName.id })}
                        onEditLevelClick={handleOpenModal(['examOrCourseName', 'upsertLevel'])}
                      />
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Container>
            <Pagination
              currentPage={currentPage || 1}
              totalPages={list.totalPages}
              onPaginationClick={handlePaginationClick}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
