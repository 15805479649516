import React from 'react'

import {
  Modal,
  Button
} from 'react-bootstrap'

export const UploadModal = ({
  operationName,
  file,
  show,
  onHide,
  onSubmit
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size='lg'
    >
      <Modal.Header closeButton>
        <Modal.Title>CSVファイルをアップロードして以下の操作を行います</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize: '2em' }}>ファイル名: {`${file?.name}`}</p>
        <p style={{ fontSize: '2em' }}>操作: {operationName}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onHide}>閉じる</Button>
        <Button variant='primary' onClick={(e) => onSubmit(e)}>アップロード</Button>
      </Modal.Footer>
    </Modal>
  )
}
