import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useSearchParams
} from 'react-router-dom'
import {
  Badge,
  Button,
  Col,
  Container,
  ListGroup,
  Row
} from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import {
  Pagination,
  objectToQuerystring,
  selectBaseUri,
  parseQueryAsInt,
  updateQueryParamEntries,
  removeQueryParamKeys,
  examOrCourseDisplay
} from '../../common'
import { selectUser } from '../../auth'
import {
  OperationOrganizationDropdown
} from '../OperationOrganizationDropdown'
import {
  ExamOrCourseDropdown
} from '../ExamOrCourseDropdown'
import {
  selectApplication,
  openModal,
  closeModal,
  clearAllLists
} from '../adminSlice'
import {
  fetchExamOrCourses,
  fetchApplications,
  uploadExamineeNumber,
  cancelApplication
} from './applicationAPI'
import { ApplicationSearchPanel } from './SearchPanel'
import {
  ExamineeNumberUploadModal
} from './ExamineeNumberUploadModal'
import {
  ApplicationCancelModal
} from './ApplicationCancelModal'
moment.locale('ja')

const statusDisplays = {
  created: '未払い',
  paymentCompleted: '支払い済み',
  canceled: 'キャンセル済み'
}

const StatusBadge = ({ status }) => (
  <Badge pill bg='primary'>
    {statusDisplays[status]}
  </Badge>
)

const ApplicationRow = ({
  application,
  onCancelApplicationClick
}) => (
  <Row>
    <h5>
      {application.user.id}: {application.user.familyName} {application.user.givenName} ({application.user.phonicFamilyName} {application.user.phonicGivenName})
      <StatusBadge status={_.camelCase(application.status)} />
    </h5>
    <h6>{application.examOrCourse.operationOrganization.name} / {examOrCourseDisplay(application.examOrCourse)}</h6>
    <Col>
      <dl>
        <dt>メールアドレス</dt>
        <dd className='email'>{application.user.email}</dd>
        <dt>受検番号</dt>
        <dd className='examinee-number'>{(application.examineeNumber === null || application.examineeNumber === undefined)
          ? '未登録'
          : application.examineeNumber}
        </dd>
        <dt>住所</dt>
        <dd className='address'>{application.user.postalCode} {application.user.prefecture} {application.user.city} {application.user.addressLine1} {application.user.addressLine2}</dd>
        <dt>電話番号</dt>
        <dd className='phone-number'>{application.user.phoneNumber}</dd>
      </dl>
    </Col>
    <Col>
      <dl>
        <dt>申し込み</dt>
        <dd className='application-created-at'>{moment(application.createdAt).format('LLL')}</dd>
        <dt>支払い</dt>
        <dd className='payment-completed-at'>{(application.paymentCompletedAt === null || application.paymentCompletedAt === undefined)
          ? '未払い'
          : `${moment(application.paymentCompletedAt).format('LLL')}支払い済み`}
        </dd>
        <dt>点数</dt>
        <dd className='score'>{(application.score === null || application.score === undefined)
          ? '未入力'
          : application.score}
        </dd>
        <dt>合否</dt>
        <dd className='passed'>{(application.passed === null || application.passed === undefined)
          ? '未入力'
          : (application.passed ? '合格' : '不合格')}
        </dd>
        {_.map(
          application.user.extraAttributes,
          (extraAttribute, index) => (
            <div key={index}>
              <dt>{extraAttribute.name}</dt>
              <dd>{extraAttribute.value
                ? extraAttribute.value
                : _.join(extraAttribute.values ?? [], ', ')}
              </dd>
            </div>
          ))}
      </dl>
    </Col>
    <Col sm='auto'>
      <Button
        variant='danger'
        onClick={onCancelApplicationClick}
        disabled={application.status === 'canceled'}
      >キャンセル
      </Button>
    </Col>
  </Row>
)

const fetchExamOrCoursesParams = operationOrganization =>
  !operationOrganization || operationOrganization === 'all'
    ? {}
    : { operationOrganizationId: operationOrganization }

const fetchApplicationsParams = (operationOrganization, examOrCourseId, page, filtering) =>
  _.omitBy(
    _.merge(
      fetchExamOrCoursesParams(operationOrganization),
      { examOrCourseId, page },
      filtering),
    (value) => !value || value === 'all')

const applicationSearchKeys = [
  'query',
  'paymentCompletedAtNull',
  'examineeNumberNull',
  'paymentCompletedAtSince',
  'paymentCompletedAtUntil',
  'createdAtSince',
  'createdAtUntil'
]
const parseQueryToFiltering = queryParams =>
  _.defaults(
    Object.fromEntries(
      _.filter(
        [...queryParams.entries()],
        ([key, _value]) => applicationSearchKeys.includes(key))),
    { paymentCompletedAtNull: 'false' },
    { examineeNumberNull: 'all' })

export const ApplicationList = () => {
  const dispatch = useDispatch()
  const {
    version,
    openedModal,
    list,
    fetching,
    examineeNumberUpload,
    applicationCancel
  } = useSelector(selectApplication)
  const baseUri = useSelector(selectBaseUri)
  const [queryParams, setQueryParams] = useSearchParams()
  const user = useSelector(selectUser)
  const selectedOperationOrganization = parseQueryAsInt(queryParams, 'operationOrganization')
  const selectedExamOrCourse = parseQueryAsInt(queryParams, 'examOrCourse')
  const currentPage = parseQueryAsInt(queryParams, 'page')
  const filtering = parseQueryToFiltering(queryParams)

  useEffect(() => {
    if (!user) {
      dispatch(clearAllLists())
    }
  }, [user])

  useEffect(() => {
    if (!user) return

    dispatch(fetchExamOrCourses(fetchExamOrCoursesParams(selectedOperationOrganization)))
  }, [user, selectedOperationOrganization])

  useEffect(() => {
    if (!user) return

    const filtering = parseQueryToFiltering(queryParams)
    dispatch(fetchApplications(fetchApplicationsParams(selectedOperationOrganization, selectedExamOrCourse, currentPage, filtering)))
  }, [user, selectedOperationOrganization, selectedExamOrCourse, version, currentPage, queryParams])

  const handleSelectOperationOrganization = operationOrganization => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['operationOrganization', operationOrganization], ['examOrCourse', 'all']]),
      { replace: true })
  const handleSelectExamOrCourse = examOrCourseId => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['examOrCourse', examOrCourseId]]),
      { replace: true })
  const handlePaginationClick = page => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['page', page]]),
      { replace: true })
  const handleSearch = values =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        Object.entries(values)),
      { replace: true })
  const handleReset = () =>
    setQueryParams(
      removeQueryParamKeys(
        queryParams,
        applicationSearchKeys),
      { replace: true })
  const handleExamineeNumberButtonClick = () => dispatch(openModal({
    modalSelector: ['application', 'examineeNumberUpload']
  }))
  const handleHideModal = modalSelector => () => dispatch(closeModal(modalSelector))
  const handleExamineeNumberUploadSubmit = values => dispatch(uploadExamineeNumber(values))
  const handleCancelApplicationClick = application => () => dispatch(openModal({
    modalSelector: ['application', 'applicationCancel'],
    args: application
  }))
  const handleCancelSubmit = applicationId => () => dispatch(cancelApplication(applicationId))
  return (
    <>
      <Container>
        <ApplicationCancelModal
          show={openedModal === 'applicationCancel'}
          onHide={handleHideModal(['application', 'applicationCancel'])}
          application={applicationCancel.args}
          notice={applicationCancel.notice}
          error={applicationCancel.error}
          fetching={fetching}
          onSubmit={handleCancelSubmit(applicationCancel?.args?.id)}
        />
        <ExamineeNumberUploadModal
          show={openedModal === 'examineeNumberUpload'}
          onHide={handleHideModal(['application', 'examineeNumberUpload'])}
          onSubmit={handleExamineeNumberUploadSubmit}
          fetching={fetching}
          notice={examineeNumberUpload.notice}
          error={examineeNumberUpload.error}
        />
        <Row className='mb-2'>
          <Col>
            <ApplicationSearchPanel
              queryParams={queryParams}
              onSearch={handleSearch}
              onReset={handleReset}
              initialValues={filtering}
            />
          </Col>
          <Col sm='auto'>
            <OperationOrganizationDropdown
              className='operation-organization-dropdown'
              operationOrganizations={user?.operationOrganizations}
              selected={selectedOperationOrganization || 'all'}
              onSelectOperationOrganization={handleSelectOperationOrganization}
            />
          </Col>
          <Col sm='auto'>
            <ExamOrCourseDropdown
              className='exam-or-course-dropdown'
              examOrCourses={list.examOrCourses}
              selected={selectedExamOrCourse || 'all'}
              onSelectExamOrCourse={handleSelectExamOrCourse}
            />
          </Col>
          <Col sm='auto'>
            <Button
              onClick={handleExamineeNumberButtonClick}
            >受検番号登録
            </Button>
          </Col>
          <Col sm='auto'>
            <Button
              href={`${baseUri}admin/applications?${objectToQuerystring(_.merge(_.omit(fetchApplicationsParams(selectedOperationOrganization, selectedExamOrCourse, currentPage, filtering), ['page']), { format: 'xlsx' }))}`}
            >ダウンロード
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            {list && <small>{list.totalCount}件</small>}
            <ListGroup variant='flush' className='application-list'>
              {_.map(
                list.data,
                (application, index) => (
                  <ListGroup.Item key={index}>
                    <ApplicationRow
                      application={application}
                      onCancelApplicationClick={handleCancelApplicationClick(application)}
                    />
                  </ListGroup.Item>
                ))}
            </ListGroup>
            <Pagination
              currentPage={currentPage || 1}
              totalPages={list.totalPages}
              onPaginationClick={handlePaginationClick}
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}
