import React from 'react'
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal
} from 'react-bootstrap'
import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { signInSchema } from './validation'

export const LoginModal = ({
  show,
  onHide,
  onSubmit,
  passwordHidden,
  notice,
  error,
  onToggleButtonClicked,
  onSignupButtonClicked,
  onForgetPasswordButtonClicked,
  onResendConfirmationButtonClicked,
  onResendUnlockButtonClicked
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
  >
    <Formik
      initialValues={{ email: '', password: '', rememberMe: false }}
      onSubmit={onSubmit}
      validationSchema={signInSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>ログイン</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error &&
              <Alert variant='danger'>
                {error.message && error.message}
                {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
              </Alert>}
            {notice &&
              <Alert variant='success'>{notice}</Alert>}
            <Form.Group className='mb-3'>
              <Form.Label>メールアドレス</Form.Label>
              <Form.Control
                type='email'
                name='email'
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && errors.email}
              />
              <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>パスワード</Form.Label>
              <InputGroup>
                <Form.Control
                  type={passwordHidden ? 'password' : 'text'}
                  name='password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && errors.password}
                />
                <Button
                  variant='secondary'
                  onClick={onToggleButtonClicked}
                >
                  <FontAwesomeIcon icon={passwordHidden ? 'eye-slash' : 'eye'} />
                </Button>
                <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Check
                type='checkbox' label='ログインを記憶する'
                name='rememberMe'
                checked={values.rememberMe}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Form.Group>
            <Button
              variant='link'
              onClick={onForgetPasswordButtonClicked}
            >パスワードを忘れた場合
            </Button>
            <Button
              variant='link'
              onClick={onResendConfirmationButtonClicked}
            >確認メールを再送する
            </Button>
            <Button
              variant='link'
              onClick={onResendUnlockButtonClicked}
            >アカウントがロックした場合
            </Button>
            <Button
              variant='link'
              onClick={onSignupButtonClicked}
            >会員登録はこちら
            </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              disabled={errors && Object.entries(errors).length > 0}
            >ログインする
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  </Modal>
)
