import React from 'react'
import {
  Alert,
  Modal,
  Form,
  Button
} from 'react-bootstrap'
import { Formik } from 'formik'
import _ from 'lodash'
import tagSchema from './tagSchema'

const Error = ({ error }) => error
  ? (
    <Alert variant='danger'>
      {error.message && error.message}
      {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
    </Alert>
    )
  : <></>

const Notice = ({ notice }) => notice ? <Alert variant='success'>{notice}</Alert> : <></>

export default ({
  show,
  onHide,
  onSubmit,
  fetching,
  notice,
  error,
  tag
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
    backdrop='static'
    keyboard={false}
  >
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        name: tag?.name ?? ''
      }}
      validationSchema={tagSchema}
    >{({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit
    }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>{tag ? '編集' : '新規作成'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error error={error} />
          <Notice notice={notice} />
          <Form.Group className='mb-3'>
            <Form.Label>名称</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.name && errors.name}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
          </Form.Group>
          <Error error={error} />
          <Notice notice={notice} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            variant='secondary'
            onClick={onHide}
          >{notice ? '閉じる' : 'キャンセル'}
          </Button>
          <Button
            type='submit'
            style={{ display: notice ? 'none' : null }}
            disabled={(errors && Object.entries(errors).length > 0) || fetching}
          >{tag ? '更新する' : '作成する'}
          </Button>
        </Modal.Footer>
      </Form>
    )}
    </Formik>
  </Modal>
)
