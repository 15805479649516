import _ from 'lodash'
import { compose } from 'lodash/fp'

const phoneNumberCharsConversion = {
  '０': '0',
  '１': '1',
  '２': '2',
  '３': '3',
  '４': '4',
  '５': '5',
  '６': '6',
  '７': '7',
  '８': '8',
  '９': '9',
  '−': '-'
}

export const convertToHalfWidth = s => _.join(_.map(s.split(''), c => phoneNumberCharsConversion[c] || c))

const stripNonNumeric = s => s.replace(/\D/g, '')

export const validatePostalCodeInput = compose(stripNonNumeric, convertToHalfWidth)

export const prefectures = [
  '北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県',
  '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県',
  '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県',
  '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県',
  '宮崎県', '鹿児島県', '沖縄県']

const hiraganaChars = [
  'ぁ', 'ぃ', 'ぅ', 'ぇ', 'ぉ', 'ゃ', 'ゅ', 'ょ', 'っ', 'ゎ', 'ゕ', 'ゖ', 'あ', 'い', 'う',
  'え', 'お', 'か', 'き', 'く', 'け', 'こ', 'さ', 'し', 'す', 'せ', 'そ', 'た', 'ち', 'つ',
  'て', 'と', 'な', 'に', 'ぬ', 'ね', 'の', 'は', 'ひ', 'ふ', 'へ', 'ほ', 'ま', 'み', 'む',
  'め', 'も', 'や', 'ゆ', 'よ', 'ら', 'り', 'る', 'れ', 'ろ', 'わ', 'ゐ', 'ゑ', 'を', 'ん',
  'が', 'ぎ', 'ぐ', 'げ', 'ご', 'ざ', 'じ', 'ず', 'ぜ', 'ぞ', 'だ', 'ぢ', 'づ', 'で', 'ど',
  'ば', 'び', 'ぶ', 'べ', 'ぼ', 'ぱ', 'ぴ', 'ぷ', 'ぺ', 'ぽ', 'ゔ']
const katakanaChars = [
  'ァ', 'ィ', 'ゥ', 'ェ', 'ォ', 'ャ', 'ュ', 'ョ', 'ッ', 'ヮ', 'ヵ', 'ヶ', 'ア', 'イ', 'ウ',
  'エ', 'オ', 'カ', 'キ', 'ク', 'ケ', 'コ', 'サ', 'シ', 'ス', 'セ', 'ソ', 'タ', 'チ', 'ツ',
  'テ', 'ト', 'ナ', 'ニ', 'ヌ', 'ネ', 'ノ', 'ハ', 'ヒ', 'フ', 'ヘ', 'ホ', 'マ', 'ミ', 'ム',
  'メ', 'モ', 'ヤ', 'ユ', 'ヨ', 'ラ', 'リ', 'ル', 'レ', 'ロ', 'ワ', 'ヰ', 'ヱ', 'ヲ', 'ン',
  'ガ', 'ギ', 'グ', 'ゲ', 'ゴ', 'ザ', 'ジ', 'ズ', 'ゼ', 'ゾ', 'ダ', 'ヂ', 'ヅ', 'デ', 'ド',
  'バ', 'ビ', 'ブ', 'ベ', 'ボ', 'パ', 'ピ', 'プ', 'ペ', 'ポ', 'ヴ']
const conversionMap = _.zipObject(hiraganaChars, katakanaChars)

const katakanaExtraChars = ['ヸ', 'ヹ', 'ヺ']
const fullWidthSymbols = ['・', 'ー', '゛', '゜', '（', '）', '　']
const halfWidthSymbols = [' ']
const allowedChars = new Set(_.concat(hiraganaChars, katakanaChars, katakanaExtraChars, fullWidthSymbols, halfWidthSymbols))
export const katakanaValidationMessage = ({ value }) => {
  if (!value) {
    return
  }
  const invalidChars = _.join(
    Array.from(new Set(_.filter(value.split(''), c => c.length > 0 && !allowedChars.has(c)))),
    ', ')
  return `処理できない文字が含まれています: ${invalidChars}`
}
export const katakanaValidationTest = value => value && _.every(value.split(''), c => allowedChars.has(c))

export const convertToKatakana = (value) => _.join(
  _.map(
    value.split(''),
    c => {
      if (c in conversionMap) return conversionMap[c]
      return c
    }
  ), ''
)
