import {
  object,
  setLocale,
  string,
  boolean,
  lazy
} from 'yup'
import * as ja from 'yup-locale-ja'
import NBP from 'nbp'

NBP.init('mostcommon_1000000', '/nbpcollections/', true)
setLocale(ja.suggestive)

export const password = string()
  .min(12)
  .required()

const registeringPassword = email => password
  .notOneOf([email], 'メールアドレスと同じパスワードは設定できません')
  .test('avoid-repetition', '同じ文字が3文字以上連続しないようにしてください', value => !/(.)\1{2,}/.test(value))
  .test(
    'multi-charsets',
    '数字、大文字、小文字、その他から最低2種を含むようにしてください',
    value => [
      /[0-9]+/.test(value),
      /[A-Z]+/.test(value),
      /[a-z]+/.test(value),
      /[^0-9A-Za-z]+/.test(value)
    ].filter(b => b).length >= 2)
  .test(
    'avoid-common-password',
    'このパスワードは使えないため、他のパスワードを設定してください',
    value => value && !NBP.isCommonPassword(value)
  )

export const authenticatingPasswordSchema = object({
  password
})

export const registeringPasswordSchema = email =>
  object({
    password: registeringPassword(email)
  })

export const emailSchema = object({
  email: string().email().required()
})

export const signupSchema = object({
  email: string().email().required(),
  agreement: boolean().isTrue(),
  password: lazy((_value, options) => registeringPassword(options.parent.email))
})

export const signInSchema = authenticatingPasswordSchema.concat(emailSchema)
