import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  Alert,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Spinner
} from 'react-bootstrap'
import {
  useDropzone
} from 'react-dropzone'

import { selectUser } from '../../auth'
import {
  clearAllLists,
  selectAdmin,
  dismissDigitalCertificateError,
  resetDigitalCertificateResult
} from '../adminSlice'

import { UploadModal } from './UploadModal'
import {
  validateInsertCSV
} from './validation'
import {
  addDigitalCertificate,
  updateDigitalCertificate
} from './digitalCertificateAPI'

const selectDigitalCertificate = createSelector(
  selectAdmin,
  admin => admin.digitalCertificate
)

const operationToOperationName = {
  add: '新規',
  update: '修正'
}

const operationToOperationVerbName = {
  add: '新規追加',
  update: '修正'
}

const dropzoneStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  height: '100px',
  minHeight: '100px',
  color: '#666',
  border: '#ccc dashed 5px'
}

const Processing = ({ fetching }) =>
  fetching
    ? <Spinner animation='border' role='status'><span className='visually-hidden'>Processing ...</span></Spinner>
    : null

const ResponseSummary = ({
  response,
  selectedFile,
  onClickDownloadErrorCSV
}) => {
  if (response == null || selectedFile !== null) return null

  if (response.status === 'ok') {
    return (
      <p>{response.success_count}件の{operationToOperationVerbName[response.operation]}が完了しました。
        {response.skipped_count > 0 && `${response.skipped_count}件の空行をスキップしました。`}
      </p>
    )
  }

  return (
    <>
      <p>
        {response.process_count}行のうち{response.error_count}行に不正があり全ての処理を取り消しました。
      </p>
      <p>
        エラー内容を備考欄に記載したCSVファイルをダウンロードできます。
        <Button onClick={onClickDownloadErrorCSV} variant='secondary'>ダウンロード</Button>
      </p>
    </>
  )
}

const Error = ({
  error,
  onClose
}) => {
  if (!error) return null

  return (
    <Alert
      variant='danger'
      dismissible
      onClose={onClose}
    >
      {error.message && error.message}
      {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
    </Alert>
  )
}

export const DigitalCertificateForm = () => {
  const dispatch = useDispatch()
  const {
    fetching,
    response,
    error
  } = useSelector(selectDigitalCertificate)

  // ステート
  const initialRadioValue = 'add'
  const [radioValue, setRadioValue] = useState(initialRadioValue)
  const [selectedFile, setSelectedFile] = useState(null)
  const [validationResults, setValidationResults] = useState([])
  const [fileContent, setFileContent] = useState(null)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!user) {
      dispatch(clearAllLists())
    }
  }, [user])

  // Clean up the error when the component is unmounted.
  useEffect(() => () => dispatch(resetDigitalCertificateResult()), [])

  // 追加・更新のラジオボタン
  const radios = [
    { name: '新規', value: 'add', variant: 'outline-primary' },
    { name: '修正', value: 'update', variant: 'outline-success' }
  ]

  // ファイルドロップ
  const handleDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0]

    if (file === null) {
      return
    }

    dispatch(resetDigitalCertificateResult())

    const reader = new window.FileReader()
    reader.onload = (e) => {
      const content = e.target.result
      const fileValidationResults = validateInsertCSV(content)
      setValidationResults(fileValidationResults)
      setSelectedFile(file)

      if (validationResults.length === 0) {
        setFileContent(content)
      }
    }
    reader.onerror = (e) => {
      console.error('read content error: ', e)
    }
    reader.readAsText(file)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'text/csv': ['.csv']
    }
  })

  // バリデーションエラー表示
  const validationErrors = validationResults.length === 0
    ? null
    : <div>{validationResults.map((error, idx) => <p key={idx} className='my-0'>{error}</p>)}</div>

  // アップロードモーダル
  const handleClickUpload = (e) => {
    if (selectedFile === null || validationResults.length > 0) {
      return
    }

    setShowUploadModal(true)
  }

  const handleSubmitUploadModal = (e) => {
    if (selectedFile === null || validationResults.length > 0) {
      return
    }

    switch (radioValue) {
      case 'add':
        dispatch(addDigitalCertificate({ csv_data: fileContent }))
        break
      case 'update':
        dispatch(updateDigitalCertificate({ csv_data: fileContent }))
        break
      default:
        break
    }

    setSelectedFile(null)
    setShowUploadModal(false)
  }

  // アップロード結果表示
  const handleClickDownloadErrorCSV = (e) => {
    const blob = new window.Blob([response.csv_data], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const anchor = document.createElement('a')
    document.body.appendChild(anchor)
    anchor.href = url
    anchor.setAttribute('download', 'error.csv')
    anchor.click()
    document.body.removeChild(anchor)
  }

  const handleCloseError = () => dispatch(dismissDigitalCertificateError())

  return (
    <>
      <h2>デジタル合格証データ管理</h2>
      <Stack className='my-2' direction='vertical' gap={2}>
        <div
          {...getRootProps({
            className: 'dropzone',
            style: dropzoneStyle
          })}
        >
          <input {...getInputProps()} />
          <div style={{ width: '100%', fontSize: '1.5em' }}>CSVファイルをドロップするかクリックしてCSVファイルを選択</div>
        </div>
        <div>
          ファイル: {selectedFile !== null ? `${selectedFile.name} (${selectedFile.size} bytes)` : ''}
        </div>
      </Stack>
      <Stack>
        {validationErrors}
      </Stack>
      <Stack className='my-2' direction='horizontal' gap={2}>
        <ToggleButtonGroup
          name='operations'
          className='ms-auto'
          value={radioValue}
          defaultValue={initialRadioValue}
        >
          {radios.map((radio, idx) => (
            <ToggleButton
              id={`radio-${idx}`}
              key={idx}
              type='radio'
              size='lg'
              variant={radio.variant}
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => setRadioValue(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Button
          size='lg'
          className='me-1'
          disabled={selectedFile === null || validationResults.length > 0 || fetching}
          onClick={handleClickUpload}
        >
          アップロード
        </Button>
      </Stack>
      <Stack>
        <Processing fetching={fetching} />
        <ResponseSummary
          response={response}
          selectedFile={selectedFile}
          onClickDownloadErrorCSV={handleClickDownloadErrorCSV}
        />
        <Error error={error} onClose={handleCloseError} />
      </Stack>
      <UploadModal
        show={showUploadModal}
        onHide={(e) => setShowUploadModal(false)}
        onSubmit={handleSubmitUploadModal}
        operationName={operationToOperationName[radioValue]}
        file={selectedFile}
      />
    </>
  )
}
