import {
  object,
  mixed,
  boolean
} from 'yup'

export const examineeNumberUploadSchema = object({
  examineeNumberCsv: mixed().test(
    'file', 'CSVファイルを指定してください', file => file
  ).required(),
  skipFirstRow: boolean().required()
})
