import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  Button
} from 'react-bootstrap'
import _ from 'lodash'
import {
  addQueryParamEntries,
  parseQueryAsIntAll,
  removeQueryParamEntry
} from '../common'
import { selectTags } from './examOrCourseSlice'
import { fetchTags } from './examOrCourseAPI'

export default () => {
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useSearchParams()
  const tags = useSelector(selectTags)
  const selectedTagIds = parseQueryAsIntAll(queryParams, 'tags[]')

  useEffect(() => {
    dispatch(fetchTags({ perPage: 1024 }))
  }, [])

  const handleClick = (tagId, previouslySelected) =>
    setQueryParams(
      previouslySelected
        ? removeQueryParamEntry(queryParams, ['tags[]', tagId.toString()])
        : addQueryParamEntries(queryParams, [['tags[]', tagId.toString()]]),
      { replace: true })

  return (
    <section className='tag-selector'>
      <h3 className='remoes-h3'>特徴で探す</h3>
      <ul className='list-unstyled d-flex flex-wrap p-3 pt-0'>
        {_.map(
          tags,
          ({ id, name }) => {
            const checked = selectedTagIds.includes(id)
            const variant = checked ? 'primary' : 'outline-dark'
            return (
              <li key={id}>
                <Button
                  variant={variant}
                  onClick={() => handleClick(id, checked)}
                >{name}
                </Button>
              </li>
            )
          }
        )}
      </ul>
    </section>
  )
}
