import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useSearchParams
} from 'react-router-dom'
import { createSelector } from '@reduxjs/toolkit'

import {
  Row,
  Col
} from 'react-bootstrap'

import { selectUser } from '../../auth'
import {
  clearAllLists,
  selectAdmin
} from '../adminSlice'

import { CertificateList } from './CertificateList'
import {
  CertificateSearchPanel,
  CertificateSortItemDropdown
} from './SearchPanel'
import {
  fetchCertificates
} from '../digitalCertificate/digitalCertificateAPI'

const selectDigitalCertificate = createSelector(
  selectAdmin,
  admin => admin.digitalCertificate
)

const queryParamsToState = (queryParams) =>
  Array(...queryParams.entries()).reduce((acc, ent) => { acc[ent[0]] = ent[1]; return acc }, {})

export const DigitalCertificateList = () => {
  const dispatch = useDispatch()
  const {
    list
  } = useSelector(selectDigitalCertificate)

  // ステート
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!user) {
      dispatch(clearAllLists())
    }
  }, [user])

  // デジタル合格証リスト
  const [queryParams, setQueryParams] = useSearchParams()
  const [queryState, setQueryState] = useState({
    page: 1,
    ...queryParamsToState(queryParams)
  })

  useEffect(() => {
    if (!user) return

    dispatch(fetchCertificates(queryState))
  }, [queryState])

  useEffect(() => {
    if (!user) return

    setQueryState(queryParamsToState(queryParams))
  }, [queryParams])

  const handlePaginationClick = page => () => {
    setQueryParams({ ...queryState, page })
  }

  const handleSearch = (formParams, actions) => {
    setQueryParams({ ...queryState, ...formParams, page: 1 })
  }

  const handleResetSearch = () => {
    setQueryParams({})
  }

  const handleChangeSortKey = (value) => {
    setQueryParams({ ...queryState, sortKey: value.target.selectedOptions[0].value, page: 1 })
  }

  const handleChangeSortOrder = (value) => {
    setQueryParams({ ...queryState, sortOrder: value.target.selectedOptions[0].value, page: 1 })
  }

  return (
    <>
      <h2>デジタル合格証データ一覧</h2>
      <Row className='mb-2'>
        <Col xs='9'>
          <CertificateSearchPanel
            queryParams={queryParams}
            onSearch={handleSearch}
            onReset={handleResetSearch}
            initialValues={queryParamsToState(queryParams)}
          />
        </Col>
        <Col xs='3'>
          <CertificateSortItemDropdown
            sortKey={queryState.sortKey}
            sortOrder={queryState.sortOrder}
            onChangeKey={handleChangeSortKey}
            onChangeOrder={handleChangeSortOrder}
          />
        </Col>
      </Row>
      <div>
        <CertificateList
          certificates={list.data}
          totalCount={list.totalCount}
          currentPage={list.currentPage}
          totalPages={list.totalPages}
          onPaginationClick={handlePaginationClick}
        />
      </div>
    </>
  )
}
