import React from 'react'
import PropTypes from 'prop-types'
import { Pagination as BSPagination } from 'react-bootstrap'
import { range } from 'lodash'

export const Pagination = ({
  currentPage,
  totalPages,
  padding = 3,
  onPaginationClick
}) => {
  const firstPage = currentPage > 1 ? <BSPagination.First onClick={onPaginationClick(1)} /> : null

  const lastPage =
    totalPages > 0 && currentPage !== totalPages ? <BSPagination.Last onClick={onPaginationClick(totalPages)} /> : null

  const previousPage =
    currentPage >= 2 ? <BSPagination.Prev onClick={onPaginationClick(currentPage - 1)} /> : null

  const nextPage =
    currentPage + 1 <= totalPages ? <BSPagination.Next onClick={onPaginationClick(currentPage + 1)} /> : null

  const pages = [
    ...range(currentPage - padding, currentPage).filter(page => page >= 1),
    ...range(currentPage, currentPage + padding + 1).filter(page => page <= totalPages)
  ].map(page => {
    return (
      <BSPagination.Item
        key={page}
        active={page === currentPage}
        onClick={onPaginationClick(page)}
      >
        {page}
      </BSPagination.Item>
    )
  })

  const leftGap = currentPage > padding + 1 ? <BSPagination.Ellipsis /> : null
  const rightGap = currentPage + padding < totalPages ? <BSPagination.Ellipsis /> : null

  return (
    <BSPagination size='sm' className='justify-content-center'>
      {firstPage}
      {previousPage}
      {leftGap}
      {pages}
      {rightGap}
      {nextPage}
      {lastPage}
    </BSPagination>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  onPaginationClick: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
  padding: PropTypes.number
}
