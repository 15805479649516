import React from 'react'
import {
  Alert,
  Modal,
  Form,
  Button
} from 'react-bootstrap'
import {
  Formik
} from 'formik'
import _ from 'lodash'
import {
  PostalCodeInput,
  prefectures
} from '../../common'
import { venueSchema } from './validation'

const Error = ({ error }) => error
  ? (
    <Alert variant='danger'>
      {error.message && error.message}
      {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
    </Alert>
    )
  : <></>

const Notice = ({ notice }) => notice ? <Alert variant='success'>{notice}</Alert> : <></>

export const UpsertModal = ({
  show,
  onHide,
  venueExecution,
  notice,
  error,
  fetching,
  onSubmit,
  onPostalCodeInputChange,
  addressCandidates,
  operationOrganizations
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
    backdrop='static'
    keyboard={false}
  >
    <Formik
      initialValues={{
        name: venueExecution?.name ?? '',
        operationOrganization: venueExecution?.operationOrganization?.id ??
          (operationOrganizations?.length === 1 ? operationOrganizations[0].id : 0),
        capacity: venueExecution?.capacity ?? '',
        postalCode: venueExecution?.postalCode ?? '',
        prefecture: venueExecution?.prefecture ?? '',
        city: venueExecution?.city ?? '',
        addressLine1: venueExecution?.addressLine1 ?? '',
        addressLine2: venueExecution?.addressLine2 ?? ''
      }}
      onSubmit={onSubmit}
      validationSchema={venueSchema}
    >{({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
    }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>{venueExecution ? '編集' : '新規作成'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error error={error} />
          <Notice notice={notice} />
          <Form.Group className='mb-3'>
            <Form.Label>運営組織</Form.Label>
            <Form.Control
              as='select'
              name='operationOrganization'
              value={values.operationOrganization}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.operationOrganization && errors.operationOrganization}
              disabled={operationOrganizations?.length === 1 || venueExecution || notice}
            >{_.map(
              _.concat([{ id: 0, name: '選択してください' }], operationOrganizations || []),
              ({ id, name }, index) => (
                <option key={index} value={id}>{name}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>{errors.operationOrganization}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>会場名</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.name && errors.name}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>定員</Form.Label>
            <Form.Control
              type='number'
              name='capacity'
              value={values.capacity}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.capacity && errors.capacity}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.capacity}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>郵便番号</Form.Label>
            <PostalCodeInput
              id='postalCode'
              defaultInputValue={values.postalCode}
              setFieldValue={setFieldValue}
              postalCodeFieldName='postalCode'
              prefectureFieldName='prefecture'
              cityFieldName='city'
              addressLineFieldName='addressLine1'
              onInputChange={onPostalCodeInputChange}
              options={addressCandidates}
              isInvalid={touched.postalCode && !!errors.postalCode}
              onBlur={handleBlur}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.postalCode}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>都道府県</Form.Label>
            <Form.Control
              as='select'
              name='prefecture'
              value={values.prefecture}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.prefecture && errors.prefecture}
              disabled={notice}
            >{_.map(
              _.concat([['選択してください', '']], _.map(prefectures, prefecture => [prefecture, prefecture])),
              ([display, value], i) => <option key={i} value={value}>{display}</option>)}
            </Form.Control>
            <Form.Control.Feedback type='invalid'>{errors.prefecture}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>市区町村</Form.Label>
            <Form.Control
              type='text'
              name='city'
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.city && errors.city}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.city}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>丁目・番地・号</Form.Label>
            <Form.Control
              type='text'
              name='addressLine1'
              value={values.addressLine1}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.addressLine1 && errors.addressLine1}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.addressLine1}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>建物名・部屋番号</Form.Label>
            <Form.Control
              type='text'
              name='addressLine2'
              value={values.addressLine2}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.addressLine2 && errors.addressLine2}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.addressLine2}</Form.Control.Feedback>
          </Form.Group>
          <Error error={error} />
          <Notice notice={notice} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            variant='secondary'
            onClick={onHide}
          >{notice ? '閉じる' : 'キャンセル'}
          </Button>
          <Button
            type='submit'
            style={{ display: notice ? 'none' : null }}
            disabled={(errors && Object.entries(errors).length > 0) || fetching}
          >{venueExecution ? '更新する' : '作成する'}
          </Button>
        </Modal.Footer>
      </Form>
    )}
    </Formik>
  </Modal>
)
