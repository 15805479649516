import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import _ from 'lodash'
import {
  csrfToken,
  toSnake,
  objectToQuerystring
} from '../common'

export const login = createAsyncThunk(
  'auth/login',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/sign_in`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ user: params }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            if (responseBody.error) {
              error.message = responseBody.error
            }
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        message: error.message,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/sign_out`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })
      .then(response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        message: error.message,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const signup = createAsyncThunk(
  'auth/signup',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { postAuth } = getState().auth
    return fetch(`${baseUri}users`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake(
        _.merge(
          { user: params },
          postAuth ? { auth: { postAuth } } : {})))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (errors, error) => [...errors, error],
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        messages: error.messages,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const confirm = createAsyncThunk(
  'auth/confirm',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/confirmation?${objectToQuerystring(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        messages: error.messages,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const forgetPassword = createAsyncThunk(
  'auth/forgetPassowrd',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ user: params }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        messages: error.messages,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const resetPassword = createAsyncThunk(
  'auth/resetPassowrd',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/password`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ user: params }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        messages: error.messages,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const resendConfirmation = createAsyncThunk(
  'auth/resendConfirmation',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/confirmation`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ user: params }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        messages: error.messages,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const resendUnlock = createAsyncThunk(
  'auth/resendUnlock',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/unlock`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ user: params }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        messages: error.messages,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const unlock = createAsyncThunk(
  'auth/unlock',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/unlock?${objectToQuerystring(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        messages: error.messages,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const fetchUser = createAsyncThunk(
  'auth/fetchUser',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/${params}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(error => rejectWithValue({
        messages: error.messages,
        status: error.status,
        statusText: error.statusText
      }))
  }
)

export const removeFrontendState = createAsyncThunk(
  'auth/removeFrontendState',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const id = params
    return fetch(`${baseUri}users/${id}/frontend_state`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(error => rejectWithValue({
        messages: error.messages,
        status: error.status,
        statusText: error.statusText
      }))
  }
)
