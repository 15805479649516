import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Col,
  ListGroup,
  NavDropdown,
  Row
} from 'react-bootstrap'
import {
  NavLink,
  Outlet,
  useNavigate
} from 'react-router-dom'
import {
  selectUser
} from '../auth'
import {
  selectProfile,
  removePostComplete
} from './profileSlice'

export const Profile = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const { postComplete } = useSelector(selectProfile)
  const navigate = useNavigate()
  useEffect(() => {
    if (user?.readyForApplication && postComplete?.applyingExamOrCourse && postComplete?.applyingExamOrCourseName) {
      dispatch(removePostComplete())
      navigate(`/examOrCourse/browse/${postComplete.applyingExamOrCourseName}?selectedExamOrCourseId=${postComplete.applyingExamOrCourse}&applyingExamOrCourse=${postComplete.applyingExamOrCourse}&applyingExamOrCourseName=${postComplete.applyingExamOrCourseName}`)
    }
  }, [user, postComplete])
  return (
    <Row className='h-100'>
      <Col sm='2' className='mb-3'>
        <h2>プロフィール</h2>
        <ListGroup>
          <ListGroup.Item
            as={NavLink}
            to='input'
          >プロフィール入力
          </ListGroup.Item>
        </ListGroup>
      </Col>
      <Col><Outlet /></Col>
    </Row>
  )
}

Profile.NavItem = () => (
  <NavDropdown.Item
    as={NavLink}
    to='/profile'
  >プロフィール
  </NavDropdown.Item>
)
