import {
  object,
  string,
  number
} from 'yup'
import { prefectures } from '../../common/validation'

export const venueSchema = object({
  operationOrganization: number().required().min(1, '選択してください'),
  name: string().required(),
  capacity: number().positive().required(),
  postalCode: string().optional().matches(/\d{7}/, '数字7桁で入力してください'),
  prefecture: string().optional().oneOf(prefectures),
  city: string().optional(),
  addressLine1: string().optional(),
  addressLine2: string().optional()
})
