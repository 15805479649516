import React from 'react'
import {
  Alert,
  Modal,
  Button
} from 'react-bootstrap'
import _ from 'lodash'

const Error = ({ error }) => error
  ? (
    <Alert variant='danger'>
      {error.message && error.message}
      {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
    </Alert>
    )
  : <></>

const Notice = ({ notice }) => notice ? <Alert variant='success'>{notice}</Alert> : <></>

export default ({
  show,
  onHide,
  onSubmit,
  error,
  notice,
  fetching,
  category
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
  >
    <Modal.Header>
      <Modal.Title>削除</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Error error={error} />
      <Notice notice={notice} />
      {category?.name}を削除しますか? この操作は取り消しできません。
    </Modal.Body>
    <Modal.Footer>
      <Button
        type='button'
        variant='secondary'
        onClick={onHide}
      >{notice ? '閉じる' : 'キャンセル'}
      </Button>
      <Button
        type='submit'
        style={{ display: notice ? 'none' : null }}
        disabled={fetching}
        onClick={onSubmit}
      >削除する
      </Button>
    </Modal.Footer>
  </Modal>
)
