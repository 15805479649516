import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import toCamel from 'lodash-humps'
import {
  csrfToken,
  toSnake,
  fetchCategoriesPayloadCreator,
  fetchTagsPayloadCreator,
  fetchExamOrCourseNameSectionsPayloadCreator,
  objectToQuerystring,
  objectToQueryParamPairs,
  handleError
} from '../../common'
import { invalidateCurrentUser } from '../../auth'
import { fetchExamOrCourseNamesPayloadCreator } from '../adminAPI'

export const fetchExamOrCourseNames = createAsyncThunk(
  'admin/examOrCourseName/fetchExamOrCourseNames',
  fetchExamOrCourseNamesPayloadCreator
)

export const createExamOrCourseName = createAsyncThunk(
  'admin/examOrCourseName/createExamOrCourseNames',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const {
      operationOrganization: organizationId,
      ...otherParams
    } = params
    const formData = new FormData() // eslint-disable-line no-undef
    _.map(objectToQueryParamPairs({ examOrCourseName: otherParams }), ([key, value]) => formData.append(key, value))
    return fetch(`${baseUri}admin/operation_organizations/${organizationId}/exam_or_course_names`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: formData
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const updateExamOrCourseName = createAsyncThunk(
  'admin/examOrCourseName/updateExamOrCourseNames',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const {
      operationOrganization: organizationId,
      id,
      ...otherParams
    } = params
    const formData = new FormData() // eslint-disable-line no-undef
    _.map(objectToQueryParamPairs({ examOrCourseName: otherParams }), ([key, value]) => formData.append(key, value))
    return fetch(`${baseUri}admin/operation_organizations/${organizationId}/exam_or_course_names/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: formData
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const createExamOrCourseLevel = createAsyncThunk(
  'admin/examOrCourseName/createExamOrCourseLevel',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { examOrCourseNameId, ...createParams } = params
    return fetch(`${baseUri}admin/exam_or_course_names/${examOrCourseNameId}/exam_or_course_levels`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ examOrCourseLevel: createParams }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const updateExamOrCourseLevel = createAsyncThunk(
  'admin/examOrCourseName/updateExamOrCourseLevel',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { examOrCourseNameId, id, ...updateParams } = params
    return fetch(`${baseUri}admin/exam_or_course_names/${examOrCourseNameId}/exam_or_course_levels/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ examOrCourseLevel: updateParams }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const fetchCategoryCandidates = createAsyncThunk(
  'admin/examOrCourseName/fetchCategoryCandidates',
  fetchCategoriesPayloadCreator(invalidateCurrentUser)
)

export const fetchTagsCandidates = createAsyncThunk(
  'admin/examOrCourseName/fetchTagsCandidates',
  fetchTagsPayloadCreator(invalidateCurrentUser)
)

export const fetchExamOrCourseNameSections = createAsyncThunk(
  'admin/examOrCourseName/fetchExamOrCourseNameSections',
  fetchExamOrCourseNameSectionsPayloadCreator(invalidateCurrentUser)
)

export const fetchExamOrCourseNameExplanatoryItems = createAsyncThunk(
  'admin/examOrCourseName/fetchExamOrCourseNameExplanatoryItems',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { examOrCourseNameId, ...otherParams } = params
    return fetch(`${baseUri}exam_or_course_names/${examOrCourseNameId}/exam_or_course_name_explanatory_items?${objectToQuerystring(otherParams)}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const fetchCategoriesForPreview = createAsyncThunk(
  'admin/examOrCourseName/fetchCategoriesForPreview',
  fetchCategoriesPayloadCreator(invalidateCurrentUser)
)

export const fetchTagsForPreview = createAsyncThunk(
  'admin/examOrCourseName/fetchTagsForPreview',
  fetchTagsPayloadCreator(invalidateCurrentUser)
)
