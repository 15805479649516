import {
  createSlice,
  createSelector
} from '@reduxjs/toolkit'
import {
  fetchExamOrCourseNames,
  createExamOrCourseName,
  updateExamOrCourseName,
  createExamOrCourseLevel,
  updateExamOrCourseLevel,
  fetchCategoryCandidates as fetchExamOrCourseNameCategoryCandidates,
  fetchTagsCandidates,
  fetchExamOrCourseNameSections,
  fetchExamOrCourseNameExplanatoryItems,
  fetchCategoriesForPreview,
  fetchTagsForPreview
} from './examOrCourseName/examOrCourseNameAPI'
import {
  createExamOrCourse,
  searchAddressByPostalCode,
  searchVenue,
  fetchExamOrCourses,
  updateExamOrCourse,
  fetchExamOrCourseNames as fetchExamOrCourseExamOrCourseNames
} from './examOrCourse/examOrCourseAPI'
import {
  fetchExamOrCourses as fetchApplicationExamOrCourses,
  fetchApplications,
  uploadExamineeNumber,
  cancelApplication
} from './application/applicationAPI'
import {
  fetchExamOrCourses as fetchVenueExecutionExamOrCourses,
  fetchVenueExecutions,
  searchAddressByPostalCode as searchVenueExecutionAddressByPostalCode,
  createVenueExecution,
  updateVenueExecution
} from './venueExecution/venueExecutionAPI'
import {
  fetchOperationOrganizations,
  createOperationOrganization,
  updateOperationOrganization,
  listUsers
} from './operationOrganization/operationOrganizationAPI'
import {
  fetchCategoryCandidates,
  createCategory,
  updateCategory,
  deleteCategory,
  fetchCategories
} from './category/categoryAPI'
import {
  createTag,
  updateTag,
  deleteTag,
  fetchTags
} from './tag/tagAPI'
import {
  fetchCertificates,
  addDigitalCertificate,
  updateDigitalCertificate
} from './digitalCertificate/digitalCertificateAPI'

const initialState = {
  examOrCourseName: {
    version: 0,
    openedModal: null,
    upsert: {
      categoryCandidates: [],
      tagsCandidates: [],
      preview: {
        tags: [],
        categories: []
      }
    },
    upsertLevel: {},
    list: {
      totalPages: 1
    },
    fetching: false
  },
  examOrCourse: {
    version: 0,
    openedModal: null,
    upsert: {
      searchByPostalCode: {
        fetching: false,
        candidates: []
      },
      venueCandidates: [],
      nameCandidates: []
    },
    list: {
      status: 'all',
      operationOrganization: 'all',
      data: [],
      sorting: {
        key: 'updatedAt',
        order: 'desc'
      },
      filtering: {
        name: ''
      },
      totalPages: 1
    }
  },
  application: {
    version: 0,
    openedModal: null,
    examineeNumberUpload: {},
    applicationCancel: {},
    list: {
      operationOrganization: 'all',
      examOrCourse: 'all',
      examOrCourses: [],
      data: [],
      sorting: {
        key: 'updatedAt',
        order: 'desc'
      },
      filtering: {
        query: '',
        id: '',
        paymentCompletedAtNull: 'false',
        examineeNumberNull: 'all',
        paymentCompletedAtSince: '',
        paymentCompletedAtUntil: ''
      },
      totalPages: 1
    }
  },
  venueExecution: {
    version: 0,
    list: {
      operationOrganization: 'all',
      examOrCourse: 'all',
      examOrCourses: [],
      data: [],
      sorting: {
        key: 'updatedAt',
        order: 'desc'
      },
      filtering: {
        query: ''
      },
      totalPages: 1
    },
    upsert: {
      searchByPostalCode: {
        fetching: false,
        candidates: []
      }
    }
  },
  operationOrganization: {
    version: 0,
    list: {
      data: [],
      filtering: {
        query: ''
      },
      totalPages: 1
    },
    upsert: {
      userCandidates: []
    }
  },
  category: {
    list: { data: [] },
    upsert: {
      parentCandidates: []
    },
    delete: {}
  },
  tag: {
    list: { data: [] },
    upsert: {},
    delete: {}
  },
  digitalCertificate: {
    list: {
      data: [],
      currentPage: 1,
      totalPages: 1,
      totalCount: 0,
      directUrlBase: ''
    },
    response: null,
    lastRequest: null,
    fetching: false
  }
}

const selectState = (state, properties) => {
  if (properties.length === 1) {
    return [state, properties[0]]
  }
  const [head, ...rest] = properties
  return selectState(state[head], rest)
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { modalSelector, args } = action.payload
      const [targetState, modalName] = selectState(state, modalSelector)
      targetState.openedModal = modalName
      if (args) {
        targetState[modalName].args = args
      }
    },
    closeModal: (state, action) => {
      const [targetState, modalName] = selectState(state, action.payload)
      const [newTargetState, newModalName] = selectState(initialState, action.payload)
      targetState[modalName] = newTargetState[newModalName]
      targetState.openedModal = null
    },
    selectStatus: (status, action) => {
      status.examOrCourse.list.status = action.payload
    },
    selectOperationOrganization: (status, action) => {
      const { operationOrganization, propName } = action.payload
      status[propName].list.operationOrganization = operationOrganization
    },
    selectSortingKey: (status, action) => {
      status.examOrCourse.list.sorting.key = action.payload
    },
    selectSortingOrder: (status, action) => {
      status.examOrCourse.list.sorting.order = action.payload
    },
    selectExamOrCourse: (state, action) => {
      const { examOrCourse, propName } = action.payload
      state[propName].list.examOrCourse = examOrCourse
    },
    clearAllLists: state => {
      Object.keys(state).forEach(key => {
        state[key].list.data = []
      })
    },
    clearExamOrCourseUpsertCandidates: state => {
      state.examOrCourse.upsert.venueCandidates = []
      state.examOrCourse.upsert.nameCandidates = []
    },
    dismissDigitalCertificateError: state => {
      delete state.digitalCertificate.error
    },
    resetDigitalCertificateResult: state => {
      delete state.digitalCertificate.response
      delete state.digitalCertificate.error
    }
  },
  extraReducers: builder => builder
    .addCase(createExamOrCourse.pending, (state, action) => {
      state.examOrCourse.fetching = true
      delete state.examOrCourse.upsert.notice
      delete state.examOrCourse.upsert.error
    })
    .addCase(createExamOrCourse.fulfilled, (state, action) => {
      state.examOrCourse.fetching = false
      state.examOrCourse.version += 1
      state.examOrCourse.upsert.notice = '作成が完了しました'
    })
    .addCase(createExamOrCourse.rejected, (state, action) => {
      state.examOrCourse.fetching = false
      state.examOrCourse.upsert.error = action.payload
    })
    .addCase(searchVenue.pending, (state, action) => {
      state.examOrCourse.fetching = true
    })
    .addCase(searchVenue.fulfilled, (state, action) => {
      state.examOrCourse.fetching = false
      const { list } = action.payload
      state.examOrCourse.upsert.venueCandidates = list
    })
    .addCase(searchVenue.rejected, (state, action) => {
      state.examOrCourse.fetching = false
    })
    .addCase(searchAddressByPostalCode.pending, searchAddressByPostalCode.pendingReducer(state => state.examOrCourse.upsert.searchByPostalCode))
    .addCase(searchAddressByPostalCode.fulfilled, searchAddressByPostalCode.fulfilledReducer(state => state.examOrCourse.upsert.searchByPostalCode))
    .addCase(searchAddressByPostalCode.rejected, searchAddressByPostalCode.rejectedReducer(state => state.examOrCourse.upsert.searchByPostalCode))
    .addCase(fetchExamOrCourses.pending, (state, action) => {
      state.examOrCourse.fetching = true
    })
    .addCase(fetchExamOrCourses.fulfilled, (state, action) => {
      state.examOrCourse.fetching = false
      const { list, currentPage, totalCount, totalPages } = action.payload
      state.examOrCourse.list.data = list
      state.examOrCourse.list.currentPage = currentPage
      state.examOrCourse.list.totalCount = totalCount
      state.examOrCourse.list.totalPages = totalPages
    })
    .addCase(fetchExamOrCourses.rejected, (state, action) => {
      state.examOrCourse.fetching = false
    })
    .addCase(updateExamOrCourse.pending, (state, action) => {
      state.examOrCourse.fetching = true
      delete state.examOrCourse.upsert.notice
      delete state.examOrCourse.upsert.error
    })
    .addCase(updateExamOrCourse.fulfilled, (state, action) => {
      state.examOrCourse.fetching = false
      state.examOrCourse.version += 1
      state.examOrCourse.upsert.notice = '更新が完了しました'
    })
    .addCase(updateExamOrCourse.rejected, (state, action) => {
      state.examOrCourse.fetching = false
      state.examOrCourse.upsert.error = action.payload
    })
    .addCase(fetchApplicationExamOrCourses.pending, (state, action) => {
      state.application.fetching = true
    })
    .addCase(fetchApplicationExamOrCourses.fulfilled, (state, action) => {
      state.application.fetching = false
      const { list } = action.payload
      state.application.list.examOrCourses = list
    })
    .addCase(fetchApplicationExamOrCourses.rejected, (state, action) => {
      state.application.fetching = false
    })
    .addCase(fetchApplications.pending, (state, action) => {
      state.application.fetching = true
    })
    .addCase(fetchApplications.fulfilled, (state, action) => {
      state.application.fetching = false
      const { list, currentPage, totalCount, totalPages } = action.payload
      state.application.list.data = list
      state.application.list.currentPage = currentPage
      state.application.list.totalCount = totalCount
      state.application.list.totalPages = totalPages
    })
    .addCase(fetchApplications.rejected, (state, action) => {
      state.application.fetching = false
    })
    .addCase(uploadExamineeNumber.pending, (state, action) => {
      state.application.fetching = true
    })
    .addCase(uploadExamineeNumber.fulfilled, (state, action) => {
      state.application.fetching = false
      state.application.version += 1
      state.application.examineeNumberUpload.notice = '更新が完了しました'
    })
    .addCase(uploadExamineeNumber.rejected, (state, action) => {
      state.application.fetching = false
      state.application.version += 1
      state.application.examineeNumberUpload.error = action.payload
    })
    .addCase(cancelApplication.pending, (state, action) => {
      state.application.fetching = true
    })
    .addCase(cancelApplication.fulfilled, (state, action) => {
      state.application.fetching = false
      state.application.version += 1
      state.application.applicationCancel.notice = '更新が完了しました'
    })
    .addCase(cancelApplication.rejected, (state, action) => {
      state.application.fetching = false
      state.application.version += 1
      state.application.applicationCancel.error = action.payload
    })
    .addCase(fetchVenueExecutionExamOrCourses.pending, (state, action) => {
      state.venueExecution.fetching = true
    })
    .addCase(fetchVenueExecutionExamOrCourses.fulfilled, (state, action) => {
      state.venueExecution.fetching = false
      const { list } = action.payload
      state.venueExecution.list.examOrCourses = list
    })
    .addCase(fetchVenueExecutionExamOrCourses.rejected, (state, action) => {
      state.venueExecution.fetching = false
    })
    .addCase(fetchVenueExecutions.pending, (state, action) => {
      state.venueExecution.fetching = true
    })
    .addCase(fetchVenueExecutions.fulfilled, (state, action) => {
      state.venueExecution.fetching = false
      const { list, currentPage, totalCount, totalPages } = action.payload
      state.venueExecution.list.data = list
      state.venueExecution.list.currentPage = currentPage
      state.venueExecution.list.totalCount = totalCount
      state.venueExecution.list.totalPages = totalPages
    })
    .addCase(fetchVenueExecutions.rejected, (state, action) => {
      state.venueExecution.fetching = false
    })
    .addCase(searchVenueExecutionAddressByPostalCode.pending, searchVenueExecutionAddressByPostalCode.pendingReducer(state => state.venueExecution.upsert.searchByPostalCode))
    .addCase(searchVenueExecutionAddressByPostalCode.fulfilled, searchVenueExecutionAddressByPostalCode.fulfilledReducer(state => state.venueExecution.upsert.searchByPostalCode))
    .addCase(searchVenueExecutionAddressByPostalCode.rejected, searchVenueExecutionAddressByPostalCode.rejectedReducer(state => state.venueExecution.upsert.searchByPostalCode))
    .addCase(createVenueExecution.pending, (state, action) => {
      state.venueExecution.fetching = true
      delete state.venueExecution.upsert.notice
      delete state.venueExecution.upsert.error
    })
    .addCase(createVenueExecution.fulfilled, (state, action) => {
      state.venueExecution.fetching = false
      state.venueExecution.version += 1
      state.venueExecution.upsert.notice = '作成が完了しました'
    })
    .addCase(createVenueExecution.rejected, (state, action) => {
      state.venueExecution.fetching = false
      state.venueExecution.upsert.error = action.payload
    })
    .addCase(updateVenueExecution.pending, (state, action) => {
      state.venueExecution.fetching = true
      delete state.venueExecution.upsert.notice
      delete state.venueExecution.upsert.error
    })
    .addCase(updateVenueExecution.fulfilled, (state, action) => {
      state.venueExecution.fetching = false
      state.venueExecution.version += 1
      state.venueExecution.upsert.notice = '更新が完了しました'
    })
    .addCase(updateVenueExecution.rejected, (state, action) => {
      state.venueExecution.fetching = false
      state.venueExecution.upsert.error = action.payload
    })
    .addCase(fetchOperationOrganizations.pending, (state, action) => {
      state.operationOrganization.fetching = true
    })
    .addCase(fetchOperationOrganizations.fulfilled, (state, action) => {
      state.operationOrganization.fetching = false
      const { list, currentPage, totalCount, totalPages } = action.payload
      state.operationOrganization.list.data = list
      state.operationOrganization.list.currentPage = currentPage
      state.operationOrganization.list.totalCount = totalCount
      state.operationOrganization.list.totalPages = totalPages
    })
    .addCase(fetchOperationOrganizations.rejected, (state, action) => {
      state.operationOrganization.fetching = false
    })
    .addCase(createOperationOrganization.pending, (state, action) => {
      state.operationOrganization.fetching = true
    })
    .addCase(createOperationOrganization.fulfilled, (state, action) => {
      state.operationOrganization.fetching = false
      state.operationOrganization.version += 1
      state.operationOrganization.upsert.notice = '作成が完了しました'
    })
    .addCase(createOperationOrganization.rejected, (state, action) => {
      state.operationOrganization.fetching = false
      state.operationOrganization.upsert.error = action.payload
    })
    .addCase(updateOperationOrganization.pending, (state, action) => {
      state.operationOrganization.fetching = true
    })
    .addCase(updateOperationOrganization.fulfilled, (state, action) => {
      state.operationOrganization.fetching = false
      state.operationOrganization.version += 1
      state.operationOrganization.upsert.notice = '更新が完了しました'
    })
    .addCase(updateOperationOrganization.rejected, (state, action) => {
      state.operationOrganization.fetching = false
      state.operationOrganization.upsert.error = action.payload
    })
    .addCase(listUsers.pending, (state, action) => {
      state.operationOrganization.fetching = true
    })
    .addCase(listUsers.fulfilled, (state, action) => {
      state.operationOrganization.fetching = false
      const { list } = action.payload
      state.operationOrganization.upsert.userCandidates = list
    })
    .addCase(listUsers.rejected, (state, action) => {
      state.operationOrganization.fetching = false
    })
    .addCase(fetchExamOrCourseExamOrCourseNames.pending, (state, action) => {
      state.examOrCourse.fetching = true
    })
    .addCase(fetchExamOrCourseExamOrCourseNames.fulfilled, (state, action) => {
      state.examOrCourse.fetching = false
      const { list } = action.payload
      state.examOrCourse.upsert.nameCandidates = list
    })
    .addCase(fetchExamOrCourseExamOrCourseNames.rejected, (state, action) => {
      state.examOrCourse.fetching = false
    })
    .addCase(fetchExamOrCourseNames.pending, (state, action) => {
      state.examOrCourseName.fetching = true
    })
    .addCase(fetchExamOrCourseNames.fulfilled, (state, action) => {
      state.examOrCourseName.fetching = false
      const { list, currentPage, totalCount, totalPages } = action.payload
      state.examOrCourseName.list.data = list
      state.examOrCourseName.list.currentPage = currentPage
      state.examOrCourseName.list.totalCount = totalCount
      state.examOrCourseName.list.totalPages = totalPages
    })
    .addCase(fetchExamOrCourseNames.rejected, (state, action) => {
      state.examOrCourseName.fetching = false
    })
    .addCase(createExamOrCourseName.pending, (state, action) => {
      state.examOrCourseName.fetching = true
    })
    .addCase(createExamOrCourseName.fulfilled, (state, action) => {
      state.examOrCourseName.fetching = false
      state.examOrCourseName.version += 1
      state.examOrCourseName.upsert.notice = '作成が完了しました'
    })
    .addCase(createExamOrCourseName.rejected, (state, action) => {
      state.examOrCourseName.fetching = false
      state.examOrCourseName.upsert.error = action.payload
    })
    .addCase(updateExamOrCourseName.pending, (state, action) => {
      state.examOrCourseName.fetching = true
    })
    .addCase(updateExamOrCourseName.fulfilled, (state, action) => {
      state.examOrCourseName.fetching = false
      state.examOrCourseName.version += 1
      state.examOrCourseName.upsert.notice = '更新が完了しました'
    })
    .addCase(updateExamOrCourseName.rejected, (state, action) => {
      state.examOrCourseName.fetching = false
      state.examOrCourseName.upsert.error = action.payload
    })
    .addCase(createExamOrCourseLevel.pending, (state, action) => {
      state.examOrCourseName.fetching = true
    })
    .addCase(createExamOrCourseLevel.fulfilled, (state, action) => {
      state.examOrCourseName.fetching = false
      state.examOrCourseName.version += 1
      state.examOrCourseName.upsertLevel.notice = '作成が完了しました'
    })
    .addCase(createExamOrCourseLevel.rejected, (state, action) => {
      state.examOrCourseName.fetching = false
      state.examOrCourseName.upsertLevel.error = action.payload
    })
    .addCase(updateExamOrCourseLevel.pending, (state, action) => {
      state.examOrCourseName.fetching = true
    })
    .addCase(updateExamOrCourseLevel.fulfilled, (state, action) => {
      state.examOrCourseName.fetching = false
      state.examOrCourseName.version += 1
      state.examOrCourseName.upsertLevel.notice = '更新が完了しました'
    })
    .addCase(updateExamOrCourseLevel.rejected, (state, action) => {
      state.examOrCourseName.fetching = false
      state.examOrCourseName.upsertLevel.error = action.payload
    })
    .addCase(fetchExamOrCourseNameCategoryCandidates.pending, (state, action) => {
      state.examOrCourseName.fetching = true
    })
    .addCase(fetchExamOrCourseNameCategoryCandidates.fulfilled, (state, action) => {
      state.examOrCourseName.fetching = false
      const { list } = action.payload
      state.examOrCourseName.upsert.categoryCandidates = list
    })
    .addCase(fetchExamOrCourseNameCategoryCandidates.rejected, (state, action) => {
      state.examOrCourseName.fetching = false
    })
    .addCase(fetchTagsCandidates.pending, (state, action) => {
      state.examOrCourseName.fetching = true
    })
    .addCase(fetchTagsCandidates.fulfilled, (state, action) => {
      state.examOrCourseName.fetching = false
      const { list } = action.payload
      state.examOrCourseName.upsert.tagsCandidates = list
    })
    .addCase(fetchTagsCandidates.rejected, (state, action) => {
      state.examOrCourseName.fetching = false
    })
    .addCase(fetchExamOrCourseNameSections.pending, (state, action) => {
      state.examOrCourseName.fetching = true
    })
    .addCase(fetchExamOrCourseNameSections.fulfilled, (state, action) => {
      state.examOrCourseName.fetching = false
      const { list } = action.payload
      state.examOrCourseName.sections = list
    })
    .addCase(fetchExamOrCourseNameSections.rejected, (state, action) => {
      state.examOrCourseName.fetching = false
    })
    .addCase(fetchExamOrCourseNameExplanatoryItems.pending, (state, action) => {
      state.examOrCourseName.fetching = true
    })
    .addCase(fetchExamOrCourseNameExplanatoryItems.fulfilled, (state, action) => {
      state.examOrCourseName.fetching = false
    })
    .addCase(fetchExamOrCourseNameExplanatoryItems.rejected, (state, action) => {
      state.examOrCourseName.fetching = false
    })
    .addCase(fetchCategoriesForPreview.pending, (state, action) => {
    })
    .addCase(fetchCategoriesForPreview.fulfilled, (state, action) => {
      const { list } = action.payload
      state.examOrCourseName.upsert.preview.categories = list
    })
    .addCase(fetchCategoriesForPreview.rejected, (state, action) => {
    })
    .addCase(fetchTagsForPreview.pending, (state, action) => {
    })
    .addCase(fetchTagsForPreview.fulfilled, (state, action) => {
      const { list } = action.payload
      state.examOrCourseName.upsert.preview.tags = list
    })
    .addCase(fetchTagsForPreview.rejected, (state, action) => {
    })
    .addCase(fetchCategoryCandidates.pending, (state, action) => {
      state.category.fetching = true
    })
    .addCase(fetchCategoryCandidates.fulfilled, (state, action) => {
      state.category.fetching = false
      const { list } = action.payload
      state.category.upsert.parentCandidates = list
    })
    .addCase(fetchCategoryCandidates.rejected, (state, action) => {
      state.category.fetching = false
    })
    .addCase(createCategory.pending, (state, action) => {
      state.category.fetching = true
    })
    .addCase(createCategory.fulfilled, (state, action) => {
      state.category.fetching = false
      state.category.version += 1
      state.category.upsert.notice = '作成が完了しました'
    })
    .addCase(createCategory.rejected, (state, action) => {
      state.category.fetching = false
      state.category.upsert.error = action.payload
    })
    .addCase(updateCategory.pending, (state, action) => {
      state.category.fetching = true
    })
    .addCase(updateCategory.fulfilled, (state, action) => {
      state.category.fetching = false
      state.category.version += 1
      state.category.upsert.notice = '更新が完了しました'
    })
    .addCase(updateCategory.rejected, (state, action) => {
      state.category.fetching = false
      state.category.upsert.error = action.payload
    })
    .addCase(deleteCategory.pending, (state, action) => {
      state.category.fetching = true
    })
    .addCase(deleteCategory.fulfilled, (state, action) => {
      state.category.fetching = false
      state.category.version += 1
      state.category.delete.notice = '削除が完了しました'
    })
    .addCase(deleteCategory.rejected, (state, action) => {
      state.category.fetching = false
      state.category.delete.error = action.payload
    })
    .addCase(fetchCategories.pending, (state, action) => {
      state.category.fetching = true
    })
    .addCase(fetchCategories.fulfilled, (state, action) => {
      state.category.fetching = false
      const { list, currentPage, totalCount, totalPages, parent } = action.payload
      state.category.list.data = list
      state.category.list.currentPage = currentPage
      state.category.list.totalCount = totalCount
      state.category.list.totalPages = totalPages
      state.category.list.parent = parent
    })
    .addCase(fetchCategories.rejected, (state, action) => {
      state.category.fetching = false
    })
    .addCase(createTag.pending, (state, action) => {
      state.tag.fetching = true
    })
    .addCase(createTag.fulfilled, (state, action) => {
      state.tag.fetching = false
      state.tag.version += 1
      state.tag.upsert.notice = '作成が完了しました'
    })
    .addCase(createTag.rejected, (state, action) => {
      state.tag.fetching = false
      state.tag.upsert.error = action.payload
    })
    .addCase(updateTag.pending, (state, action) => {
      state.tag.fetching = true
    })
    .addCase(updateTag.fulfilled, (state, action) => {
      state.tag.fetching = false
      state.tag.version += 1
      state.tag.upsert.notice = '更新が完了しました'
    })
    .addCase(updateTag.rejected, (state, action) => {
      state.tag.fetching = false
      state.tag.upsert.error = action.payload
    })
    .addCase(deleteTag.pending, (state, action) => {
      state.tag.fetching = true
    })
    .addCase(deleteTag.fulfilled, (state, action) => {
      state.tag.fetching = false
      state.tag.version += 1
      state.tag.delete.notice = '削除が完了しました'
    })
    .addCase(deleteTag.rejected, (state, action) => {
      state.tag.fetching = false
      state.tag.delete.error = action.payload
    })
    .addCase(fetchTags.pending, (state, action) => {
      state.tag.fetching = true
    })
    .addCase(fetchTags.fulfilled, (state, action) => {
      state.tag.fetching = false
      const { list, currentPage, totalCount, totalPages, parent } = action.payload
      state.tag.list.data = list
      state.tag.list.currentPage = currentPage
      state.tag.list.totalCount = totalCount
      state.tag.list.totalPages = totalPages
      state.tag.list.parent = parent
    })
    .addCase(fetchTags.rejected, (state, action) => {
      state.tag.fetching = false
    })

    .addCase(fetchCertificates.pending, (state, action) => {
    })
    .addCase(fetchCertificates.fulfilled, (state, action) => {
      const { list, currentPage, totalCount, totalPages } = action.payload
      state.digitalCertificate.list.data = list
      state.digitalCertificate.list.currentPage = currentPage
      state.digitalCertificate.list.totalPages = totalPages
      state.digitalCertificate.list.totalCount = totalCount
    })
    .addCase(fetchCertificates.rejected, (state, action) => {
    })

    .addCase(addDigitalCertificate.pending, (state, action) => {
      state.digitalCertificate.fetching = true
      state.digitalCertificate.response = null
    })
    .addCase(addDigitalCertificate.fulfilled, (state, action) => {
      state.digitalCertificate.fetching = false
      state.digitalCertificate.response = action.payload
      state.digitalCertificate.lastRequest = 'add'
    })
    .addCase(addDigitalCertificate.rejected, (state, action) => {
      state.digitalCertificate.fetching = false
      const { status, messages } = action.payload
      state.digitalCertificate.error = {
        ...action.payload,
        messages: messages || [`デジタル合格証データを新規登録する際、エラー (ステータスコード${status}) が発生しました`]
      }
    })
    .addCase(updateDigitalCertificate.pending, (state, action) => {
      state.digitalCertificate.fetching = true
      state.digitalCertificate.response = null
    })
    .addCase(updateDigitalCertificate.fulfilled, (state, action) => {
      state.digitalCertificate.fetching = false
      state.digitalCertificate.response = action.payload
      state.digitalCertificate.lastRequest = 'update'
    })
    .addCase(updateDigitalCertificate.rejected, (state, action) => {
      state.digitalCertificate.fetching = false
      const { status, messages } = action.payload
      state.digitalCertificate.error = {
        ...action.payload,
        messages: messages || [`デジタル合格証データを修正する際、エラー (ステータスコード${status}) が発生しました`]
      }
    })
})

export const selectAdmin = state => state.admin

export const selectExamOrCourse = createSelector(
  selectAdmin,
  admin => admin.examOrCourse
)

export const selectSearchByPostalCode = createSelector(
  selectExamOrCourse,
  examOrCourse => examOrCourse.upsert.searchByPostalCode
)

export const selectApplication = createSelector(
  selectAdmin,
  admin => admin.application
)

export const selectVenueExecution = createSelector(
  selectAdmin,
  admin => admin.venueExecution
)

export const {
  openModal,
  closeModal,
  selectStatus,
  selectOperationOrganization,
  selectSortingKey,
  selectSortingOrder,
  clearAllLists,
  clearExamOrCourseUpsertCandidates,
  dismissDigitalCertificateError,
  resetDigitalCertificateResult
} = adminSlice.actions
