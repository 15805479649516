import React from 'react'
import {
  Alert,
  Modal,
  Form,
  Button
} from 'react-bootstrap'
import { Formik } from 'formik'
import _ from 'lodash'
import { Typeahead } from 'react-bootstrap-typeahead'
import { convertToKatakana } from '../../common'
import { operationOrganizationSchema } from './validation'

const Error = ({ error }) => error
  ? (
    <Alert variant='danger'>
      {error.message && error.message}
      {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
    </Alert>
    )
  : <></>

const Notice = ({ notice }) => notice ? <Alert variant='success'>{notice}</Alert> : <></>

const MenuItem = ({
  id,
  familyName,
  givenName,
  email
}) => `${id}: ${familyName || ''} ${givenName || ''} <${email}>`

export const UpsertModal = ({
  show,
  onHide,
  notice,
  error,
  fetching,
  onSubmit,
  operationOrganization,
  onContactPersonIdInputChange,
  userCandidates,
  onContactPersonsSelected
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
    backdrop='static'
    keyboard={false}
  >
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        name: operationOrganization?.name ?? '',
        phonicName: operationOrganization?.phonicName ?? '',
        notificationEmail: operationOrganization?.notificationEmail ?? '',
        contactPersonId: operationOrganization?.contactPerson?.id ?? ''
      }}
      validationSchema={operationOrganizationSchema}
    >{({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue
    }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>{operationOrganization ? '編集' : '新規作成'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error error={error} />
          <Notice notice={notice} />
          <Form.Group className='mb-3'>
            <Form.Label>名称</Form.Label>
            <Form.Control
              type='text'
              name='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.name && errors.name}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>名称カナ</Form.Label>
            <Form.Control
              type='text'
              name='phonicName'
              value={values.phonicName}
              onChange={handleChange}
              onBlur={event => {
                event.target.value = convertToKatakana(event.target.value)
                handleBlur(event)
                handleChange(event)
              }}
              isInvalid={touched.phonicName && errors.phonicName}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.phonicName}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>通知用メールアドレス</Form.Label>
            <Form.Control
              type='text'
              name='notificationEmail'
              value={values.notificationEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.notificationEmail && errors.notificationEmail}
              disabled={notice}
            />
            <Form.Control.Feedback type='invalid'>{errors.notificationEmail}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>連絡先ユーザー</Form.Label>
            <Typeahead
              id='contactPersonId'
              className='is-invalid'
              onInputChange={onContactPersonIdInputChange(() => {
                setFieldValue('contactPersonId', '')
              })}
              onFocus={event => onContactPersonIdInputChange(() => {})(event.target.value)}
              options={userCandidates}
              labelKey='email'
              renderMenuItemChildren={MenuItem}
              onChange={onContactPersonsSelected(selectedUser => {
                setFieldValue('contactPersonId', selectedUser.id)
              })}
              inputProps={{
                name: 'contactPersonId',
                isInvalid: touched.contactPersonId && errors.contactPersonId
              }}
              defaultInputValue={operationOrganization?.contactPerson?.email ?? ''}
              onBlur={handleBlur}
            />
            <Form.Control.Feedback type='invalid'>{errors.contactPersonId}</Form.Control.Feedback>
          </Form.Group>
          <Error error={error} />
          <Notice notice={notice} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            variant='secondary'
            onClick={onHide}
          >{notice ? '閉じる' : 'キャンセル'}
          </Button>
          <Button
            type='submit'
            style={{ display: notice ? 'none' : null }}
            disabled={(errors && Object.entries(errors).length > 0) || fetching}
          >{operationOrganization ? '更新する' : '作成する'}
          </Button>
        </Modal.Footer>
      </Form>
    )}
    </Formik>
  </Modal>
)
