import {
  object,
  string,
  number
} from 'yup'

export default id => object({
  name: string().required(),
  parentId: number().positive().notOneOf(id ? [id] : [], '自身を指定することはできません')
})
