import React from 'react'
import {
  Col,
  Form,
  Row
} from 'react-bootstrap'
import { SearchPanel } from '../../common'

const sortKeys = [
  { title: '登録順', key: 'id' },
  { title: '認証ID', key: 'certificateCode1' },
  { title: '受験番号', key: 'examineeCode' },
  { title: '氏名_姓', key: 'examineeLastName' },
  { title: '氏名_名', key: 'examineeFirstName' },
  { title: '生年月日', key: 'examineeBirthDay' },
  { title: '検定試験名称', key: 'examinationName' },
  { title: '級', key: 'examinationGrade' },
  { title: '試験回数', key: 'examinationStage' },
  { title: '更新ユーザー名', key: 'lastOperatorName' },
  { title: '新規登録日時', key: 'createdAt' },
  { title: '最終更新日時', key: 'administratedAt' }
]

const sortOrders = [
  { title: '昇順', order: 'asc' },
  { title: '降順', order: 'desc' }
]

export const CertificateSortItemDropdown = (props) => {
  return (
    <>
      <Form.Group className='d-flex'>
        <Form.Select
          onChange={props.onChangeKey}
          value={props.sortKey || sortKeys[0].key}
        >
          {
            sortKeys.map((key, idx) => (
              <option key={idx} value={key.key}>
                {key.title}
              </option>
            ))
          }
        </Form.Select>
        <Form.Select
          onChange={props.onChangeOrder}
          value={props.sortOrder || sortOrders[0].order}
        >
          {
            sortOrders.map((order, idx) => (
              <option key={idx} value={order.order}>
                {order.title}
              </option>
            ))
          }
        </Form.Select>
      </Form.Group>
    </>
  )
}

export const CertificateSearchPanel = (props) => {
  return (
    <SearchPanel {...props} queryPropName='query'>
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur
      }) => (
        <>
          <Row>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>認証ID</Form.Label>
              <Form.Control
                type='text'
                name='certificateCode1'
                value={values.certificateCode1 || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>受験番号</Form.Label>
              <Form.Control
                type='text'
                name='examineeCode'
                value={values.examineeCode || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>氏名_姓</Form.Label>
              <Form.Control
                type='text'
                name='examineeLastName'
                value={values.examineeLastName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>氏名_名</Form.Label>
              <Form.Control
                type='text'
                name='examineeFirstName'
                value={values.examineeFirstName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>生年月日がこれ以降</Form.Label>
              <Form.Control
                type='date'
                name='examineeBirthDaySince'
                value={values.examineeBirthDaySince || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>生年月日がこれ以前</Form.Label>
              <Form.Control
                type='date'
                name='examineeBirthDayUntil'
                value={values.examineeBirthDayUntil || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className='mb-3' as={Col} xs='4'>
              <Form.Label>検定試験名称</Form.Label>
              <Form.Control
                type='text'
                name='examinationName'
                value={values.examinationName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
            <Form.Group className='mb-3' as={Col} xs='4'>
              <Form.Label>級</Form.Label>
              <Form.Control
                type='text'
                name='examinationGrade'
                value={values.examinationGrade || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
            <Form.Group className='mb-3' as={Col} xs='4'>
              <Form.Label>試験回数</Form.Label>
              <Form.Control
                type='text'
                name='examinationStage'
                value={values.examinationStage || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>更新ユーザー名</Form.Label>
              <Form.Control
                type='text'
                name='lastOperatorName'
                value={values.lastOperatorName || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>新規登録日時がこれ以降</Form.Label>
              <Form.Control
                type='date'
                name='createdAtSince'
                value={values.createdAtSince || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>新規登録日時がこれ以前</Form.Label>
              <Form.Control
                type='date'
                name='createdAtUntil'
                value={values.createdAtUntil || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>最終更新日時がこれ以降</Form.Label>
              <Form.Control
                type='date'
                name='administratedAtSince'
                value={values.administratedAtSince || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
            <Form.Group className='mb-3' as={Col} xs='6'>
              <Form.Label>最終更新日時がこれ以前</Form.Label>
              <Form.Control
                type='date'
                name='administratedAtUntil'
                value={values.administratedAtUntil || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={false}
              />
            </Form.Group>
          </Row>
        </>
      )}
    </SearchPanel>
  )
}
