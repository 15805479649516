import { object } from 'yup'

import {
  registeringPasswordSchema,
  password
} from '../auth'

const currentPasswordSchema = object({
  currentPassword: password
})

export const passwordSettingSchema = email =>
  registeringPasswordSchema(email).concat(currentPasswordSchema)
