import React, { useState } from 'react'
import {
  Alert,
  Button,
  Form,
  Modal,
  Table
} from 'react-bootstrap'
import {
  Formik
} from 'formik'
import Papa from 'papaparse'
import _ from 'lodash'
import { examineeNumberUploadSchema } from './validation'

const Error = ({ error }) => error
  ? (
    <Alert variant='danger'>
      {error.message && error.message}
      {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
    </Alert>
    )
  : <></>

const Notice = ({ notice }) => notice ? <Alert variant='success'>{notice}</Alert> : <></>

export const ExamineeNumberUploadModal = ({
  show,
  onHide,
  onSubmit,
  fetching,
  notice,
  error
}) => {
  const [csvData, setCsvData] = useState(null)
  const fileReader = new FileReader() // eslint-disable-line no-undef
  const handleHide = () => {
    setCsvData(null)
    onHide()
  }

  return (
    <Modal
      show={show}
      onHide={handleHide}
      centered
    >
      <Formik
        initialValues={{
          examineeNumberCsv: null,
          skipFirstRow: false
        }}
        onSubmit={onSubmit}
        validationSchema={examineeNumberUploadSchema}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        setFieldError
      }) => {
        const handleFileChange = event => {
          const file = event.target.files[0]
          setFieldTouched('examineeNumberCsv')
          setFieldValue('examineeNumberCsv', file)
          if (!file) return

          fileReader.onloadend = () => {
            if (!fileReader.result) {
              setFieldError('examineeNumberCsv', 'CSVファイルの読み込みに失敗しました')
              return
            }
            const { data, errors: parseErrors } = Papa.parse(fileReader.result)
            if (parseErrors.length > 0 || !data || data.length < 1) {
              setFieldError('examineeNumberCsv', 'CSVファイルの読み込みに失敗しました')
              return
            }
            setCsvData(data)
          }
          fileReader.readAsText(file, 'Windows-31J')
        }
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>受検番号登録</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Error error={error} />
              <Notice notice={notice} />
              <Form.Group className='mb-3'>
                <Form.Control
                  name='examineeNumberCsv'
                  type='file'
                  onChange={handleFileChange}
                  onBlur={handleBlur}
                  isInvalid={touched.examineeNumberCsv && errors.examineeNumberCsv}
                  accept='text/csv'
                />
                <Form.Control.Feedback type='invalid'>{errors.examineeNumberCsv}</Form.Control.Feedback>
              </Form.Group>
              <Form.Check
                type='switch'
                name='skipFirstRow'
                value={values.skipFirstRow}
                onChange={handleChange}
                label='1行目をヘッダとして扱い、登録しない'
              />
              {csvData &&
                <>
                  <p>先頭の数行を表示しています。</p>
                  <Table responsive>
                    <tbody>
                      {_.map(
                        _.take(csvData, 3),
                        (row, index) => (
                          <tr key={index}>
                            {_.map(
                              row,
                              (cell, index) => (
                                <td key={index}>{cell}</td>
                              ))}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>}
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='button'
                variant='secondary'
                onClick={handleHide}
              >{notice ? '閉じる' : 'キャンセル'}
              </Button>
              <Button
                type='submit'
                style={{ display: notice ? 'none' : null }}
                disabled={(errors && Object.entries(errors).length > 0) || fetching}
              >登録する
              </Button>
            </Modal.Footer>
          </Form>
        )
      }}
      </Formik>
    </Modal>
  )
}
