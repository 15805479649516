import React from 'react'
import {
  Col,
  Form,
  Row
} from 'react-bootstrap'
import _ from 'lodash'
import { SearchPanel } from '../../common'

export const ApplicationSearchPanel = (props) => (
  <SearchPanel {...props} queryPropName='query'>
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur
    }) => (
      <>
        <Row>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>決済状況</Form.Label>
            <Form.Control
              as='select'
              name='paymentCompletedAtNull'
              value={values.paymentCompletedAtNull}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.paymentCompletedAtNull && errors.paymentCompletedAtNull}
            >{_.map(
              [{ value: 'all', name: '全て' },
                { value: 'true', name: '決済未完了' },
                { value: 'false', name: '決済完了済み' }],
              ({ value, name }, index) => (
                <option key={index} value={value}>{name}</option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>受検番号登録状況</Form.Label>
            <Form.Control
              as='select'
              name='examineeNumberNull'
              value={values.examineeNumberNull}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.examineeNumberNull && errors.examineeNumberNull}
            >{_.map(
              [{ value: 'all', name: '全て' },
                { value: 'true', name: '未登録' },
                { value: 'false', name: '登録済み' }],
              ({ value, name }, index) => (
                <option key={index} value={value}>{name}</option>
              ))}
            </Form.Control>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>決済完了日がこれ以降</Form.Label>
            <Form.Control
              type='date'
              name='paymentCompletedAtSince'
              value={values.paymentCompletedAtSince}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.paymentCompletedAtSince && errors.paymentCompletedAtSince}
            />
            <Form.Control.Feedback type='invalid'>{errors.paymentCompletedAtSince}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>決済完了日がこれ以前</Form.Label>
            <Form.Control
              type='date'
              name='paymentCompletedAtUntil'
              value={values.paymentCompletedAtUntil}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.paymentCompletedAtUntil && errors.paymentCompletedAtUntil}
            />
            <Form.Control.Feedback type='invalid'>{errors.paymentCompletedAtUntil}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>申し込み日がこれ以降</Form.Label>
            <Form.Control
              type='date'
              name='createdAtSince'
              value={values.createdAtSince}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.createdAtSince && errors.createdAtSince}
            />
            <Form.Control.Feedback type='invalid'>{errors.createdAtSince}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>申し込み日がこれ以前</Form.Label>
            <Form.Control
              type='date'
              name='createdAtUntil'
              value={values.createdAtUntil}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.createdAtUntil && errors.createdAtUntil}
            />
            <Form.Control.Feedback type='invalid'>{errors.createdAtUntil}</Form.Control.Feedback>
          </Form.Group>
        </Row>
      </>
    )}
  </SearchPanel>
)
