import React from 'react'
import {
  Alert,
  Modal,
  Form,
  Button,
  Image,
  ButtonGroup,
  Tabs,
  Tab
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FieldArray,
  Formik
} from 'formik'
import _ from 'lodash'
import { Typeahead } from 'react-bootstrap-typeahead'
import { convertToKatakana } from '../../common'
import { examOrCourseNameSchema } from './validation'
import ExamOrCourseNamePreview from './ExamOrCourseNamePreview'

const Error = ({ error }) => error
  ? (
    <Alert variant='danger'>
      {error.message && error.message}
      {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
    </Alert>
    )
  : <></>

const Notice = ({ notice }) => notice ? <Alert variant='success'>{notice}</Alert> : <></>

const CategoryMenuItem = ({ id, name }) => `${id}: ${name}`

const SectionForm = ({
  id: sectionId,
  name,
  explanatoryItems,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  disabled
}) => (
  <>
    <h5>{name}</h5>
    <FieldArray name={`explanatoryItems[${sectionId}]`}>
      {({ push, remove }) => (
        <>
          {_.map(explanatoryItems, ({ title, content, order }, index) => (
            <div className={`mb-3 section-${sectionId}-exlanatory-item-${index}`}>
              <Form.Group className='mb-3 '>
                <Form.Label>タイトル</Form.Label>
                <Form.Control
                  type='text'
                  name={`explanatoryItems[${sectionId}][${index}].title`}
                  value={title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched?.explanatoryItems &&
                             touched?.explanatoryItems[sectionId] &&
                             touched?.explanatoryItems[sectionId][index] &&
                             touched?.explanatoryItems[sectionId][index].title &&
                             errors?.explanatoryItems &&
                             errors?.explanatoryItems[sectionId] &&
                             errors?.explanatoryItems[sectionId][index] &&
                             errors?.explanatoryItems[sectionId][index].title}
                  disabled={disabled}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.explanatoryItems &&
                   errors?.explanatoryItems[sectionId] &&
                   errors?.explanatoryItems[sectionId][index] &&
                   errors?.explanatoryItems[sectionId][index].title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>内容</Form.Label>
                <Form.Control
                  type='text'
                  name={`explanatoryItems[${sectionId}][${index}].content`}
                  as='textarea'
                  value={content}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched?.explanatoryItems &&
                             touched?.explanatoryItems[sectionId] &&
                             touched?.explanatoryItems[sectionId][index] &&
                             touched?.explanatoryItems[sectionId][index].content &&
                             errors?.explanatoryItems &&
                             errors?.explanatoryItems[sectionId] &&
                             errors?.explanatoryItems[sectionId][index] &&
                             errors?.explanatoryItems[sectionId][index].content}
                  disabled={disabled}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors?.explanatoryItems &&
                   errors?.explanatoryItems[sectionId] &&
                   errors?.explanatoryItems[sectionId][index] &&
                   errors?.explanatoryItems[sectionId][index].content}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          ))}
          <ButtonGroup className={`mb-3 section-${sectionId}-button-group`}>
            <Button
              size={`sm section-${sectionId}-add-button`}
              className='plus'
              onClick={() => push({ title: '', content: '' })}
            >
              <FontAwesomeIcon fixedWidth icon='plus' />
            </Button>
            <Button
              size={`sm section-${sectionId}-remove-button`}
              className='minus'
              onClick={() => {
                if (explanatoryItems[explanatoryItems.length - 1].id) {
                  values.deletingExplanatoryItemIds.push(explanatoryItems[explanatoryItems.length - 1].id)
                }
                remove(explanatoryItems.length - 1)
              }}
              disabled={explanatoryItems.length <= 0}
            >
              <FontAwesomeIcon fixedWidth icon='minus' />
            </Button>
          </ButtonGroup>
        </>
      )}
    </FieldArray>
  </>
)

export const UpsertModal = ({
  show,
  onHide,
  examOrCourseName,
  notice,
  error,
  fetching,
  onSubmit,
  operationOrganizations,
  onCategoryInputChange,
  categoryCandidates,
  onCategorySelected,
  onTagsInputChange,
  tagsCandidates,
  onTagsSelected,
  sections
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
    backdrop='static'
    keyboard={false}
    size='xl'
  >
    <Formik
      initialValues={{
        operationOrganization: examOrCourseName?.operationOrganization.id ??
          (operationOrganizations?.length === 1 ? operationOrganizations[0].id : 0),
        value: examOrCourseName?.value ?? '',
        phonicName: examOrCourseName?.phonicName ?? '',
        categoryId: examOrCourseName?.category?.id ?? '',
        tagIds: _.map(examOrCourseName?.tags, ({ id }) => id) ?? [],
        description: examOrCourseName?.description ?? '',
        explanatoryItems: _.merge(
          Object.fromEntries(_.map(sections, ({ id }) => [id, []])),
          examOrCourseName?.explanatoryItems),
        deletingExplanatoryItemIds: []
      }}
      onSubmit={onSubmit}
      validationSchema={examOrCourseNameSchema}
    >{({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      setFieldTouched
    }) => (
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header>
          <Modal.Title>{examOrCourseName ? '編集' : '新規作成'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Error error={error} />
          <Notice notice={notice} />
          <Tabs defaultActiveKey='draft'>
            <Tab eventKey='draft' title='下書き'>
              <Form.Group className='mb-3'>
                <Form.Label>運営組織</Form.Label>
                <Form.Control
                  as='select'
                  name='operationOrganization'
                  value={values.operationOrganization}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.operationOrganization && errors.operationOrganization}
                  disabled={operationOrganizations?.length === 1 || examOrCourseName || notice}
                >{_.map(
                  _.concat([{ id: 0, name: '選択してください' }], operationOrganizations || []),
                  ({ id, name }, index) => (
                    <option key={index} value={id}>{name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>{errors.operationOrganization}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>試験・講座名</Form.Label>
                <Form.Control
                  type='text'
                  name='value'
                  value={values.value}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.value && errors.value}
                  disabled={notice}
                />
                <Form.Control.Feedback type='invalid'>{errors.value}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>試験・講座名カナ</Form.Label>
                <Form.Control
                  type='text'
                  name='phonicName'
                  value={values.phonicName}
                  onChange={handleChange}
                  onBlur={event => {
                    event.target.value = convertToKatakana(event.target.value)
                    handleBlur(event)
                    handleChange(event)
                  }}
                  isInvalid={touched.phonicName && errors.phonicName}
                  disabled={notice}
                />
                <Form.Control.Feedback type='invalid'>{errors.phonicName}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>ジャンル</Form.Label>
                <Typeahead
                  id='categoryId'
                  className={(touched.categoryId && errors.categoryId) ? 'is-invalid' : ''}
                  onInputChange={onCategoryInputChange({
                    preDispatchCallback: () => setFieldValue('categoryId', '')
                  })}
                  onFocus={event => onCategoryInputChange({})(event.target.value)}
                  options={categoryCandidates}
                  labelKey='name'
                  renderMenuItemChildren={CategoryMenuItem}
                  onChange={onCategorySelected(selectedCategory => {
                    setFieldValue('categoryId', selectedCategory.id)
                  })}
                  inputProps={{
                    name: 'categoryId'
                  }}
                  defaultInputValue={examOrCourseName?.category?.name ?? ''}
                  onBlur={handleBlur}
                  disabled={notice}
                />
                <Form.Control.Feedback type='invalid'>{errors.categoryId}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>タグ</Form.Label>
                <Typeahead
                  id='tagIds'
                  className={touched.tagIds && errors.tagIds ? 'is-invalid' : ''}
                  onInputChange={onTagsInputChange({})}
                  onFocus={event => onTagsInputChange({})(event.target.value)}
                  options={tagsCandidates}
                  labelKey='name'
                  multiple
                  onChange={onTagsSelected(selectedTags => {
                    setFieldValue('tagIds', [])
                    _.forEach(
                      selectedTags,
                      ({ id }, index) => setFieldValue(`tagIds.${index}`, id))
                  })}
                  inputProps={{ name: 'tagIds' }}
                  defaultSelected={examOrCourseName?.tags}
                  onBlur={handleBlur}
                  disabled={notice}
                />
                <Form.Control.Feedback type='invalid'>{errors.tagIds}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>概要</Form.Label>
                <Form.Control
                  type='text'
                  name='description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.description && errors.description}
                  disabled={notice}
                  as='textarea'
                />
                <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3'>
                <Form.Label>ロゴ画像</Form.Label>
                <Form.Control
                  type='file'
                  name='logoImage'
                  onChange={event => {
                    setFieldTouched('logoImage')
                    setFieldValue('logoImage', event.target.files[0])
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.logoImage && errors.logoImage}
                  disabled={notice}
                  accept='image/*'
                />
                <Form.Control.Feedback type='invalid'>{errors.logoImage}</Form.Control.Feedback>
                {!values.logoImage && examOrCourseName?.logoImage && <Image fluid src={examOrCourseName?.logoImage} />}
                {values.logoImage && <Image fluid src={URL.createObjectURL(values.logoImage)} />}
              </Form.Group>
              {_.map(
                sections,
                section => (
                  <SectionForm
                    key={section.id}
                    {...section}
                    explanatoryItems={values.explanatoryItems[section.id]}
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                  />
                ))}
            </Tab>
            <Tab eventKey='preview' title='プレビュー'>
              <ExamOrCourseNamePreview
                value={values.value}
                description={values.description}
                sections={_.filter(sections, section => _.map(_.filter(_.entries(values.explanatoryItems), ([_sectionId, sectionExplanatoryItems]) => sectionExplanatoryItems.length > 0), ([sectionId]) => +sectionId).includes(section.id))}
                explanatoryItems={values.explanatoryItems}
                logoImage={values.logoImage ? URL.createObjectURL(values.logoImage) : examOrCourseName?.logoImage}
                tagIds={values.tagIds}
                categoryId={values.categoryId}
              />
            </Tab>
          </Tabs>
          <Error error={error} />
          <Notice notice={notice} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            type='button'
            variant='secondary'
            onClick={onHide}
          >{notice ? '閉じる' : 'キャンセル'}
          </Button>
          <Button
            type='submit'
            style={{ display: notice ? 'none' : null }}
            disabled={(errors && Object.entries(errors).length > 0) || fetching}
          >{examOrCourseName ? '更新する' : '作成する'}
          </Button>
        </Modal.Footer>
      </Form>
    )}
    </Formik>
  </Modal>
)
