import React from 'react'

export const TermsOfUse = () => (
  <div className='terms-of-use'>
    <h1>利用規約</h1>

    <p>この利用規約（以下、「本規約」といいます）は、パーソルビジネスプロセスデザイン株式会社（以下、「当社」といいます）が提供する当ウェブサイト（以下、「本サービス」といいます）の利用条件を定めるものであり、本サービスを利用した全ての利用者（第１条に規定します、以下同じ）に適用されます。なお、利用者が本サービスの使用を開始した時点で、本規約に同意したものとさせていただきますので、ご利用の前に必ずお読みください。<strong>また、個人情報の取り扱いについては第１５条に明記しております。</strong></p>

    <h2>第１条（定義）</h2>
    <p>本規約において、次の各号に掲げる用語の意義は、以下のとおりとします。</p>
    <ol>
      <li><p>利用者</p><p>本サービスに登録し、学習コンテンツ及び受験申込など個人が利用するサービスを受ける者</p></li>
      <li><p>主催者</p><p>本サービスに登録し、本サービスを介して資格・検定試験を運営するサービス提供者</p></li>
      <li><p>ICDL</p><p>ICDL財団およびICDL Asiaが提供する、デジタルスキル認定試験とその学習システム</p></li>
    </ol>

    <h2>第２条（適用）</h2>
    <ol>
      <li><p>本規約は、利用者および主催者と当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</p></li>
      <li><p>当社は本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下「個別規定」といいます）をすることがあります。これら個別規定はその名称の如何に関わらず、本規約の一部を構成するものとします。</p></li>
      <li><p>本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。</p></li>
    </ol>

    <h2>第３条（利用登録）</h2>
    <ol>
      <li><p>本サービスにおいては、利用者および主催者が本規約に同意の上、当社の定める方法によってサービス登録を申請し、当社がこれを承認することによって、サービス登録が完了するものとします。</p></li>
      <li>
        <p>当社はサービス登録の申請者に以下の事由があると判断した場合、サービス登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。</p>
        <ol>
          <li><p>サービス登録の申請に際して虚偽の事項を届け出た場合</p></li>
          <li><p>本規約に違反したことがある者からの申請である場合</p></li>
          <li><p>本サービスの利用上または技術上の支障がある、または支障を生じるおそれがあると判断される場合</p></li>
          <li><p>その他、当社がサービス登録を相当でないと判断した場合</p></li>
        </ol>
      </li>
      <li><p>本サービスの利用にあたって、利用者および主催者が、個人情報・団体情報を登録する場合、真実かつ正確な情報を登録しなければなりません。</p></li>
    </ol>

    <h2>第４条（認証情報の管理義務）</h2>
    <ol>
      <li><p>利用者および主催者は、認証情報の使用および管理について一切の責任を負うものとし、第三者による使用、第三者への貸与、譲渡等をしてはならないものとします。</p></li>
      <li><p>認証情報の管理不十分、使用上の過誤、第三者の使用等による損害の責任は利用者および主催者が負うものとし、当社は一切の責任を負いません。</p></li>
      <li><p>利用者は、認証情報が盗まれたもしくは第三者に使用されていることを知った場合には、直ちに当社にその旨を連絡するとともに、当社からの指示に従うものとします。当社への連絡の前後にかかわらず、利用者が被る被害については、当社は一切の責任を負いません。</p></li>
      <li><p>認証情報を紛失した場合はログイン画面に表記された内容に従い、手続きを行うものとします。</p></li>
    </ol>

    <h2>第５条（本サービスの利用料金）</h2>
    <p>利用者は、本サービスの有料部分の対価として、主催者が別途定め、本ウェブサイトに表示する利用料金を、主催者が指定する方法により支払うものとします。</p>

    <h2>第６条（登録内容の変更）</h2>
    <ol>
      <li><p>利用者および主催者は、登録情報の内容に変更が生じた場合、当社の定める方法で速やかに変更手続きを行うものとします。変更手続きは変更の申告時期によって手続きが異なるため、当社の指示に従ってください。</p></li>
      <li><p>前項の変更手続きをしなかったことにより、不利益を被った場合でも、当社は一切その責任を負いません。</p></li>
    </ol>

    <h2>第７条（禁止事項）</h2>
    <p>利用者および主催者は、本サービスの利用にあたり、以下の行為を禁止します。</p>
    <ol>
      <li><p>法令または公序良俗に違反する行為</p></li>
      <li><p>犯罪行為に関連する行為</p></li>
      <li><p>本サービスの内容等、本サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為</p></li>
      <li><p>当社、他の利用者および主催者、またはその他第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為</p></li>
      <li><p>運営者のサービスの運営を妨害するおそれのある行為</p></li>
      <li><p>不正アクセス、またはこれを試みる行為</p></li>
      <li><p>他の利用者および主催者に関する個人情報等を収集または蓄積する行為</p></li>
      <li><p>不正な目的を持って本サービスを利用する行為</p></li>
      <li><p>本サービスの他の利用者および主催者またはその他の第三者に不利益、損害、不快感を与える行為</p></li>
      <li><p>他の利用者および主催者になりすます行為</p></li>
      <li><p>当社のサービスに関連して、反社会的勢力に対して直接または間接的に利益を供与する行為</p></li>
      <li><p>その他、当社が不適切と判断する行為</p></li>
    </ol>

    <h2>第８条（本サービスの提供の停止等）</h2>
    <ol>
      <li>
        <p>当社は、以下のいずれかの事由があると判断した場合、利用者および主催者にこと前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</p>
        <ol>
          <li><p>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</p></li>
          <li><p>地震、落雷、火災、停電または天災等の不可抗力により、本サービスの提供が困難となった場合</p></li>
          <li><p>コンピュータまたは通信回線等が事故により停止した場合</p></li>
          <li><p>その他、当社が本サービスの提供が困難と判断した場合</p></li>
        </ol>
      </li>
      <li><p>当社は、本サービスの提供の停止または中断により、利用者、主催者および第三者が被ったいかなる不利益または損害についての一切の責任を負わないものとします。</p></li>
    </ol>

    <h2>第９条（利用制限および登録抹消）</h2>
    <ol>
      <li><p>当社は、利用者および主催者が以下のいずれかに該当する場合には、事前の通知なく、利用者および主催者に対して、本サービスの全部もしくは一部の利用を制限し、または利用者および主催者としての登録を抹消することができるものとします。</p>
        <ol>
          <li><p>本規約のいずれかの条項に違反した場合</p></li>
          <li><p>登録事項に虚偽の事実があることが判明した場合</p></li>
          <li><p>当社からの連絡に対し、一定期間返答がない場合</p></li>
          <li><p>本サービスについて、最終の利用から一定期間利用がない場合</p></li>
          <li><p>その他、当社が本サービスの利用を適当でないと判断した場合</p></li>
        </ol>
      </li>
      <li><p>当社は本条に基づき、当社が行った行為により利用者および主催者に生じた損害について、一切の責任を負わないものとします。</p></li>
    </ol>

    <h2>第１０条（退会）</h2>
    <ol>
      <li><p>利用者および主催者が、本サービスから退会する場合、当社所定の手続き完了を以て退会とします。</p></li>
      <li><p>改めて本サービスに登録する場合は新規登録扱いとなり、過去のデータは一切反映されないものとします。</p></li>
      <li><p>退会に伴い、法令上保管期間が定められている個人データ以外は全て削除します。</p></li>
      <li><p>削除処理完了後、最大7日間はデータを保持します。</p></li>
    </ol>

    <h2>第１１条（免責事項）</h2>
    <ol>
      <li><p>当社は、本サービスへの登録・利用、および本サービスを介したイベント・セミナー利用等から利用者および主催者に生じる一切の損害（精神的苦痛、またはその他の金銭的損失を含む一切の不利益）に関して責任を負うものではありません。</p></li>
      <li><p>利用者および主催者が、本サービスの登録・利用により第三者に対して損害を生じさせた場合は、利用者および主催者の責任において解決し、当社は一切の責任を負いません。</p></li>
      <li><p>当社は、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</p></li>
      <li><p>当社は、本サービスを利用することによって生じたトラブル（商行為の如何を問わず）や利用者および主催者が被った損害について、一切の責任を負担しないものとします。</p></li>
    </ol>

    <h2>第１２条（不可抗力）</h2>
    <ol>
      <li><p>当社は、通常講ずるべきウイルス対策では防止できないウイルス被害、天変地異による被害、その他、当社の責によらない事由による被害が利用者および主催者に生じた場合には、一切責任を負わないものとします。</p></li>
      <li><p>当社は、前項の不可抗力に起因して本サービスのデータが消去・変更されないことを保証するものではありません。</p></li>
    </ol>

    <h2>第１３条（権利譲渡の禁止）</h2>
    <p>利用者および主催者は、本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。</p>

    <h2>第１４条（損害賠償）</h2>
    <ol>
      <li><p>当社は、利用者および主催者が本サービスをご利用になれなかったことにより発生した一切の損害について、いかなる責任も負わないものとします。</p></li>
      <li><p>利用者および主催者が故意または過失により本規約に違反し、当社に対し損害を与えた場合、利用者および主催者は当社に対しその一切の損害について賠償の責めを負うものとします。</p></li>
    </ol>

    <h2>第１５条（個人情報の取り扱い）</h2>
    <p>当社は、本サービスの利用によって取得する個人情報については、当社<a href='https://www.persol-bd.co.jp/policy/' target='_blank' rel='noreferrer'>「個人情報保護方針」</a>に基づき、適切に取り扱うものとします。</p>

    <ol>
      <li>登録いただいた個人情報の利用目的</li>
      <p>利用者情報および受検結果等の一元管理、デジタル合格証表示等の合格者向けサービス、資格・検定試験等の運営、サービス開発およびデータ提供を目的とした調査・分析、企画・案内関連の情報提供</p>

      <li><p>第三者提供に関する事項</p>
        <p>ご登録いただいた個人情報は、検定試験受験、検定講座受講に際し、各主催団体へ提供する場合があります。</p>

        <ul>
          <li><p>提供先</p></li>
          <p>①…検定講座主催団体（業務提携先）</p>
          <p>提供目的…利用者情報および受検結果等の一元管理、デジタル合格証表示等の合格者向けサービス、資格・検定試験等の運営のため
          </p>
          <p>提供する項目…氏名、生年月日、住所、その他受験・受講に必要な情報</p>
          <p>提供の手段…セキュリティ対策を施した専用ウェブサイトからのダウンロード</p>
          <p>②…検定講座受験団体（サービス提供先）</p>
          <p>提供目的…利用者情報・学習進捗の確認および受検結果等の一元管理のため</p>
          <p>提供する項目…氏名、生年月日、住所、その他受験・受講に必要な情報</p>
          <p>提供の手段…セキュリティ対策を施した専用ウェブサイトからのダウンロード</p>
          <p>③…ICDL認定プログラム運営団体（ICDL-Asia）（詳細は３．をご参照ください。）</p>
        </ul>

        <p>また、個人情報の保護に関する法律（個人情報保護法）第２７条１項各号による場合（法令に基づく場合など）には、第三者へ提供することがあります。</p>

      </li>
      <li>外国にある第三者への提供
        <p>ICDLのプログラムへの参加は、ICDL-Asiaによって管理されます。ICDLプログラムに参加すると学習システムが提供され、認定試験に合格すると、受験者は特定のICDL認定プログラムで認定されたことを正式に示す証明書を受け取ります。学習システムを利用者に提供する段階で、ICDL-Asiaへ個人情報を提供いたします。</p>

        <ul>
          <li><p>提供先第三者の属する国：シンガポール</p></li>
          <li><p>提供先：ICDL-Asia</p></li>
          <li><p>提供目的：学習システムの利用と認定証明書発行のため</p></li>
          <li><p>提供する項目：英字氏名、生年月日、性別その他認定証明書発行に必要な情報</p></li>
          <li><p>提供する手段または方法：セキュリティ対策を施した専用のウェブサイトからのダウンロード</p></li>
          <li>
            <p>当該外国における個人情報の保護に関する制度に関する情報：以下リンクをご参照ください。
              <br />シンガポール共和国の個人情報の保護に関する制度等（個人情報保護委員会調査）
              <br /><a href='https://www.ppc.go.jp/files/pdf/singapore_report.pdf' target='_blank' rel='noreferrer'>（ https://www.ppc.go.jp/files/pdf/singapore_report.pdf ）</a>
            </p>
          </li>
          <p />
          <li>提供先が講ずる個人情報の保護のための措置に関する情報：
            <br />提供先では、OECDプライバシーガイドライン８原則に対応する全ての措置を講じ、提供される個人情報を適切に取り扱う旨、確認しております。<p />
          </li>
        </ul>

      </li>
      <li>個人情報登録の任意性について</li>
      <p>本サービスへの個人情報登録は任意ですが、ご登録いただけない場合は本サービスをご利用いただくことができません。</p>

      <li>個人情報に関するご要望の受付</li>
      <p>本サービスの利用に際しご登録いただいた個人情報に関し、次に掲げる対応のご請求を、ご本人または代理人から承ります。</p>

      <p>【請求をお受けする対応】</p>

      <ul>
        <li><p>利用目的の通知</p></li>
        <li><p>登録されている情報の開示</p></li>
        <li><p>内容の訂正、追加または削除</p></li>
        <li><p>利用の停止</p></li>
        <li><p>消去</p></li>
        <li><p>第三者への提供の停止</p></li>
        <li><p>第三者提供の記録の開示</p></li>
      </ul>

      <p>【請求のお手続き】</p>
      <p>お申し出先：下記、「苦情・相談受付窓口」

      </p>
      <ul>
        <li><p>お申し出方法：お電話または電子メール</p></li>
        <li><p>開示方法：口頭開示、書面開示、電磁的方法（電子メール含む）</p></li>
        <li><p>本人確認：当社所定の方法によりお申し出人の本人または代理人であることを確認</p></li>

        <ul>
          <li><p>利用目的の通知または開示を請求されるときは、通知または開示の方法を、口頭、書面、電磁的方法（電子メールを含む）のいずれかよりお選びいただけます。</p></li>
          <li><p>利用の停止または消去について、要請を承り対応した後もしばらくの間は当社からのご案内が届く場合があります。</p></li>
          <li><p>次の場合には請求に応じることができません。あらかじめご了承ください。</p></li>

          <ul>
            <li><p>ご請求の対象となる情報が、当社の保有個人データ（※）ではない場合</p></li>
            <li><p>法令に抵触するおそれがある場合</p></li>
            <li><p>ご本人または第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</p></li>
            <li><p>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</p></li>
            <p>※保有個人データとは、個人情報取扱事業者が、本人から求められる上記の対応のすべてに応じることができる権限を有する個人データであって、その存否が明らかになることによって公益その他の利益が害されるものとして政令で定めるもの以外を言います。</p>
          </ul>

        </ul>
      </ul>

      <li>個人情報の取り扱いに関する苦情・相談</li>
      <p>本サービスにおける個人情報の取り扱いに関する苦情・相談つきましては、以下へご連絡ください。</p>
      <p>【苦情・相談受付窓口】
        <br />パーソルビジネスプロセスデザイン株式会社
        <br />〒135-0061 東京都 江東区豊洲3-2-20 豊洲フロント7F
        <br />E-mail：pbd-privacy@persol.co.jp
        <br />TEL：03-6758-3371（受付時間：平日10:00-18:00）
      </p>

      <li>個人情報保護に関するその他の事項</li>
      <p>その他、個人情報保護に関する事項につきましては、以下をご参照ください。
        <br />当社ホームページ<a href='https://www.persol-bd.co.jp/privacy/' target='_blank' rel='noreferrer'>「個人情報の取り扱いについて」</a> 4.個人情報の安全管理措置
      </p>

      <li>個人情報保護に関する責任者</li>
      <p>組織：パーソルビジネスプロセスデザイン株式会社
        <br />ガバナンス本部 本部長
        <br />E-mail：pbd-privacy@persol.co.jp
      </p>

      <p />

    </ol>

    <h2>第１６条（サービスの変更等について）</h2>
    <p>当社は、利用者および主催者に事前告知の有無にかかわらず、本サービスの内容を変更、追加または廃止することがあり、利用者および主催者はこれを承諾するものとします。</p>

    <h2>第１７条（申込内容の変更（試験日時・会場）およびキャンセルに関する事項）</h2>
    <ul>
      <li><p>CBT方式の試験（以下「CBT試験」といいます）の受験申請および受験会場・日時の予約を完了した者が、予約のキャンセルもしくは予約内容の変更を希望する場合、各主催団体のルールに則って運用します。</p></li>
      <li><p>IBT方式の試験（以下「IBT試験」といいます）、IBT試験講習、動画、テキストダウンロード等の各種コンテンツ、および認定証などの物販等の申込において、ご入金後のキャンセルおよび各料金の返金はできません。</p></li>
      <li><p>各主催団体が主催するマークシート試験や記述試験、講習会等の申込において、申込処理完了後のキャンセルおよび変更については、各主催団体のルールに則って運用します。</p></li>
    </ul>

    <h2>第１８条（試験中に関する事項）</h2>
    <p>主催者が定める規約と相違する場合は、主催者の規約を優先とします。</p>
    <ol>
      <li><p> CBT受験の場合</p>
        <p> 受験者は、受験に際して順守すべき以下のルールに関する同意書に署名する必要があります。なお、受験者がこの同意書に署名しない場合、当日予定している試験を受けることができません。</p>
        <ul>
          <li><p>受験者は、試験会場の受付にて、あらかじめ指示された書類を提示して受験予定者本人であることの確認を受けます。</p></li>
          <li><p>試験室は受験者のみ入室可能です。試験会場には同伴者の待機場所はなく、試験室への同席もできません。</p></li>
          <li><p>試験室には原則として本人確認書類、試験監督官から配布されるメモ用紙・ペン・ロッカーのカギ、会場が配布した荷物収納バック以外を持ち込むことができません。携帯電話・スマートフォン・PCなどの電子機器、腕時計、財布、帽子、かばん、コートなどの私物は、試験監督官の指示のもと、所定の場所に保管してください。許可されたもの以外を試験室に持ち込んだ場合、故意・過失問わず不正行為とみなされ試験は無効となります。</p></li>
          <li><p>電子機器は預ける前に電源を切っておいてください。なお、私物保管前・返却後の紛失や答案についてはテストセンターでは責任を負いません。</p></li>
          <li><p>試験開始前にパソコン画面に表示されている氏名・試験科目名等の情報が本人のものであることを必ず確認の上、試験を開始してください。</p></li>
          <li><p>受験者は、試験中に、Windowsのログオフやユーザーの切断、PCの電源を切る行為は禁止されています。</p></li>
          <li><p>受験者は、試験中は飲食、喫煙、会話、その他の受験者に迷惑となる行為は禁止されています。</p></li>
          <li><p>受験者は、試験中は、試験の公平性や安全の確保のため、試験監督官により目視やその他の方法により監視され、ビデオ録画やその他の方法で記録される場合があります。</p></li>
          <li><p>受験者は、受験の妨げになるような事態が発生した場合は、直ちに試験監督官に報告してください。</p></li>
          <li><p>試験監督官は試験内容に関する質問には一切お答えできません。</p></li>
          <li><p>受験者は試験室に入室した後、原則として試験が終了するまで退室できません。試験終了前に試験監督官の許可を得ることなく退室した場合には、その時点で試験を棄権したものとみなされます。（この場合、受験料は返金されません。）ただし、休憩に関する特別な指定をしている試験についてはこの限りではありません。</p></li>
          <li><p>試験の途中で受験者自身が試験を終了させた場合、過失であっても試験の再開はできません。既定の試験終了時間前に試験を終了させる場合は、受験者の責任の下、操作を行ってください。</p></li>
          <li><p>受験者は、試験問題や解答などの試験に関する情報を第三者に漏洩することは固く禁じられています。</p></li>
          <li><p>試験終了後は試験監督官の指示またはテストセンターの規定に従い退室してください。</p></li>
          <li><p>試験監督官より結果レポートを忘れずに受け取ってください。</p>
            <p>（＊試験科目によっては結果レポートの受け取りが無い場合もあります）</p>
          </li>
          <li><p>試験問題や解答内容を試験室から持ち出すこと、ならびにその情報を第三者と共有することを禁止しています。試験終了後は試験監督官の指示に従い、配布されたメモ用紙、ペン等の試験資材を全て返却してください、。違反した場合は、故意・過失問わず不正行為とみなされ試験は無効となり、試験主催者より処罰を受ける場合があります。</p></li>
          <li><p>CBT試験の最中に受験者の責に帰さない何らかのトラブルが発生して、試験を継続できなかった場合、受験者は引き続き該当会場にて継続して受験を再開するか、別日程での再受験を申請することができます。ただし、再受験を申請する場合、発生する交通費等の費用や費やした時間・労力に対する保証はありませんので、あらかじめご了承ください。</p></li>
        </ul>

        <p> 以上の注意事項に従わない場合、当社や試験主催者の判断により、その受験者の試験結果が無効になったり、資格がはく奪されたり、損害賠償を請求される場合などがあります。</p>
        <p>なお、本規約の違反により試験が無効になった場合、受験料の返金はできません。</p>
      </li>

      <li><p>IBT試験、E-ラーニング受講の場合</p>
        <p> 申込者は主にIBTおよびE-ラーニング受講（動画視聴、テキスト閲覧等）に関して、以下の事項に同意する必要があり、当社は同意を前提として申込を受理するものとします。</p>
        <ul>
          <li><p>IBT試験およびE-ラーニング受講の支払完了後は、キャンセルは一切できません。</p></li>
          <li><p>実施日程において一定期間が設けられている場合、申請者は該当日程内にIBT試験や教材の閲覧、その他課せられた事項を完了する必要があります。いかなる理由であっても、期間の延長やサービス利用権限の再付与、支払料金の返金はできません。</p></li>
          <li><p>申請者は、あらかじめ自身の利用するPC環境が該当のサービスに適した環境であることを確認する必要があります。利用環境の不適合に起因するトラブルが発生し正常にサービスを利用できない場合があります。その場合も、料金は返金できませんのでご注意ください。</p></li>
          <li><p>なお、推奨環境は必ずしも正常な動作を保証するものではございません。ご利用の端末環境や、通信環境、その他ソフトウェアなどの影響により正常に動作しない場合がございますので、あらかじめご承知おきください。</p></li>
          <li><p>当社サポートセンターは試験や学習の内容に関する質問には一切お答えできません。</p></li>
          <li><p>サービス利用中に受験者の用いたPCにおいて、何らかのトラブルが発生し試験が中断した場合は、当社サポートセンターへ直ちに連絡し、指示を仰いでください。それぞれの試験主催者の取り決めに沿った対応を実施することとします。</p></li>
        </ul>

        <p>以上の注意事項に反する行為を申込者が行った場合、当社や主催団体の判断により、その申込者の試験結果および受講の履歴が無効になったり、資格がはく奪されたり、損害賠償を請求される場合などがあります。</p>
        <p>なお、本規約の違反により試験が無効になった場合、受験料の返金はできません。</p>
      </li>
      <li><p>マークシート試験、記述試験の場合</p>
        <p> 申請者は、主に試験中に関して、各試験主催者が定める受験規約および以下の事項に同意する必要があり、当社は申請者の同意を前提として申請を受理するものとします。</p>
        <ul>
          <li><p>受験者は、試験室に入室後は指定された座席に着席し、試験監督官の指示に従って試験を開始してください。</p></li>
          <li><p>試験室は受験者のみ入室可能です。試験会場には同伴者の待機場所はなく、試験室への同席もできません。</p></li>
          <li><p>受験者は、試験中は飲食、喫煙、会話、その他の受験者に迷惑となる行為は禁止されています。</p></li>
          <li><p>受験者は、試験中は、試験の公平性や安全確保のため、試験監督官に目視やその他の方法により監視され、ビデオ録画やその他の方法で記録される場合があります。</p></li>
          <li><p>受験者は、受験の妨げになるような事態が発生した場合は、直ちに試験監督官に報告してください。</p></li>
          <li><p>試験監督官は試験内容に関する質問には一切お答えできません。</p></li>
          <li><p>試験監督官が試験の終了を宣言したら、受験者は速やかに筆記用具を置き、指示の通りに退出手続きを行ってください。</p></li>
          <li><p>受験者は、試験問題や解答などの試験に関する情報を第三者に漏洩することは固く禁じられています。</p></li>
          <li><p>試験の最中に受験者の責に帰さない何らかのトラブルが発生し、試験を継続できなかった場合、その後の対応は各試験主催者の判断に基づき運用します。</p></li>
        </ul>

        <p>以上の注意事項に反する行為を受験者が行った場合、当社や試験主催者の判断により、その受験者の試験結果が無効になったり、資格がはく奪されたり、損害賠償を請求される場合があります。</p>
        <p>なお、本規約の違反により試験が無効になった場合、受験料の返金はできません。</p>
      </li>
    </ol>

    <h2>第１９条（受験上の配慮申請について）</h2>
    <p>車椅子や補聴器をご利用の方などお身体の不自由な方で、受験上の配慮を希望する場合は、申込時に申請が必要となります。</p>
    <p>申請された情報については、試験会場での誘導等に関わる担当者間で情報を連携いたします。</p>

    <h2>第２０条（本規約の変更）</h2>
    <ol>
      <li><p>当社は、以下の場合には利用者および主催者の個別の同意を要せず、本規約を変更することができるものとします。</p>
        <ol>
          <li><p>利用者および主催者の一般の利益に適合するとき。</p></li>
          <li><p>本規約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。</p></li>
        </ol>
      </li>
      <li><p>当社は利用者および主催者に対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を本サービス上で通知します。</p></li>
    </ol>

    <h2>第２１条（準拠法）</h2>
    <p>本規約は、日本法に準拠し、日本法に従って解釈されるものとします。</p>

    <h2>第２２条（管轄裁判所）</h2>
    <p>本規約に関する一切の訴訟その他の紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とするものとします。</p>

    <p>以上</p>

    <p>制定 ２０２３年２月１日</p>
    <p>改訂 ２０２４年１０月１日</p>
  </div>
)
