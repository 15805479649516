import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import _ from 'lodash'
import {
  csrfToken,
  objectToQuerystring,
  toSnake,
  fetchExamOrCourseNameSectionsPayloadCreator,
  handleError
} from '../../common'
import { invalidateCurrentUser } from '../../auth'

export const fetchExamOrCourses = createAsyncThunk(
  'examOrCourse/browse/fetchExamOrCourses',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}exam_or_courses?${objectToQuerystring(params)}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({ dispatch, rejectWithValue, invalidateCurrentUser }))
  }
)

export const fetchExamOrCourse = createAsyncThunk(
  'examOrCourse/browse/fetchExamOrCourse',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const examOrCourseId = params
    return fetch(`${baseUri}exam_or_courses/${examOrCourseId}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({ dispatch, rejectWithValue, invalidateCurrentUser }))
  }
)

export const createApplication = createAsyncThunk(
  'examOrCourse/browse/createApplication',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { examOrCourseId, ...values } = params
    return fetch(`${baseUri}exam_or_courses/${examOrCourseId}/applications`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake(values))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const fetchExamOrCourseName = createAsyncThunk(
  'examOrCourse/browse/fetchExamOrCourseName',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id } = params
    return fetch(`${baseUri}exam_or_course_names/${id}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({ dispatch, rejectWithValue, invalidateCurrentUser }))
  }
)

export const fetchExamOrCourseNameSections = createAsyncThunk(
  'examOrCourse/browse/fetchExamOrCourseNameSections',
  fetchExamOrCourseNameSectionsPayloadCreator(invalidateCurrentUser)
)
