import React from 'react'
import _ from 'lodash'
import {
  Dropdown,
  DropdownButton
} from 'react-bootstrap'

export const OperationOrganizationDropdown = ({
  operationOrganizations,
  selected,
  onSelectOperationOrganization,
  className
}) => (
  <DropdownButton
    variant='secondary'
    title={_.find(operationOrganizations, { id: selected })?.name ?? '全て'}
    className={className}
  >
    <Dropdown.Item
      onClick={onSelectOperationOrganization('all')}
      active={selected === 'all'}
    >全て
    </Dropdown.Item>
    {operationOrganizations &&
     _.map(
       operationOrganizations,
       (organization, i) => (
         <Dropdown.Item
           key={i}
           onClick={onSelectOperationOrganization(organization.id)}
           active={selected === organization.id}
         >
           {organization.name}
         </Dropdown.Item>
       ))}
  </DropdownButton>
)
