import { createAsyncThunk } from '@reduxjs/toolkit'
import _, { snakeCase, identity } from 'lodash'
import createHumps from 'lodash-humps/lib/createHumps'
import toCamel from 'lodash-humps'
import KenAll from 'ken-all'

export const csrfToken = () => {
  const csrfTokenMetaElement = document.getElementsByName(
    'csrf-token'
  )[0]
  // In Rails testing environment, CSRF verification is disabled, and no
  // csrf-token meta element exists in an HTML document.Therefore, we need to
  // check if csrfTokenMetaElement is truty below.
  return csrfTokenMetaElement ? csrfTokenMetaElement.content : ''
}

export const setCsrfToken = token => {
  const csrfTokenMetaElement = document.getElementsByName(
    'csrf-token'
  )[0]
  if (!csrfTokenMetaElement) {
    return
  }
  csrfTokenMetaElement.content = token
}

export const toSnake = createHumps(s => snakeCase(s).replace(/([A-Za-z]+)_([0-9]+)/, '$1$2'))

const snakeCaseKey = key => {
  const match = key.match(/(.+)\[(.*)\]/)
  if (!match) {
    return snakeCase(key)
  }
  return `${snakeCaseKey(match[1])}[${snakeCase(match[2])}]`
}

export const prefixesToString = ([head, ...rest]) =>
  head === undefined
    ? ''
    : `${snakeCaseKey(head)}` + _.map(rest, (s) => `[${snakeCaseKey(s)}]`).join('')

export const objectToQueryParamPairs = (paramObj, prefixes = []) => {
  if (paramObj === undefined || paramObj === null) {
    return []
  }
  if (Array.isArray(paramObj)) {
    return _.flatMap(paramObj, element => objectToQueryParamPairs(element, [...prefixes, '']))
  }
  if (typeof paramObj === 'object' && !(paramObj instanceof File)) { // eslint-disable-line no-undef
    return _.flatMap(
      _.toPairs(paramObj),
      ([key, value]) => objectToQueryParamPairs(value, [...prefixes, key]))
  }
  return [[prefixesToString(prefixes), paramObj]]
}

export const objectToQuerystring = (params) =>
  _.map(
    objectToQueryParamPairs(params),
    ([key, value]) =>
      `${key}=${((key === 'sorting[key]') ? snakeCase : identity)(value)}`)
    .join('&')

export const createPostalCodeSearchAsyncThunk = (
  typePrefix
) => {
  const thunk = createAsyncThunk(
    typePrefix,
    async (query, { rejectWithValue }) => {
      if (query.length < 7) {
        return Promise.resolve([])
      }
      return KenAll(query)
        .then(result => _.map(
          result,
          ([prefecture, city, rest]) => ({
            label: query,
            prefecture,
            city,
            rest
          })))
        .catch(error => rejectWithValue(error.message))
    }
  )
  thunk.pendingReducer = stateSelector => state => {
    const targetState = stateSelector(state)
    targetState.fetching = true
  }
  thunk.fulfilledReducer = stateSelector => (state, action) => {
    const targetState = stateSelector(state)
    targetState.fetching = false
    targetState.candidates = action.payload
  }
  thunk.rejectedReducer = stateSelector => state => {
    const targetState = stateSelector(state)
    targetState.fetching = false
  }
  return thunk
}

export const parseQueryAsInt = (queryParams, key) => {
  if (!queryParams.has(key)) return null

  const parsedMaybeInt = Number.parseInt(queryParams.get(key))
  if (Number.isNaN(parsedMaybeInt)) return null

  return parsedMaybeInt
}

export const parseQueryAsIntAll = (queryParams, key) =>
  _.filter(
    _.map(
      queryParams.getAll(key),
      value => Number.parseInt(value)),
    maybeIntValue => !Number.isNaN(maybeIntValue))

export const removeQueryParamKeys = (queryParams, keys) =>
  new URLSearchParams(
    _.filter(
      [...queryParams.entries()],
      ([key, _value]) => !keys.includes(key)))

export const removeQueryParamEntry = (queryParams, [key, value]) =>
  new URLSearchParams(
    _.filter(
      [...queryParams.entries()],
      ([k, v]) => k !== key || v !== value))

export const addQueryParamEntries = (queryParams, entries) =>
  new URLSearchParams(
    _.concat(
      [...queryParams.entries()],
      entries))

export const updateQueryParamEntries = (queryParams, entries) =>
  addQueryParamEntries(
    removeQueryParamKeys(
      queryParams,
      _.map(entries, ([key, _value]) => key)),
    entries)

export const fetchCategoriesPayloadCreator = invalidateCurrentUser =>
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}categories?${objectToQuerystring(params)}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({ dispatch, rejectWithValue, invalidateCurrentUser }))
  }

export const fetchTagsPayloadCreator = invalidateCurrentUser =>
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}tags?${objectToQuerystring(params)}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({ dispatch, rejectWithValue, invalidateCurrentUser }))
  }

export const fetchExamOrCourseNameSectionsPayloadCreator = invalidateCurrentUser =>
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}exam_or_course_name_sections?${objectToQuerystring(params)}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({ dispatch, rejectWithValue, invalidateCurrentUser }))
  }

export const handleError = ({
  dispatch,
  rejectWithValue,
  invalidateCurrentUser,
  redirectUri,
  options
}) => error => {
  const errorObject = _.merge(
    {
      messages: error.messages,
      status: error.status,
      statusText: error.statusText
    }, options)
  if (errorObject.status === 401) {
    dispatch(invalidateCurrentUser(_.merge(errorObject, { redirectUri })))
  }
  return rejectWithValue(errorObject)
}
