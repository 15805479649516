import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  Button,
  Form
} from 'react-bootstrap'
import _ from 'lodash'
import {
  addQueryParamEntries,
  parseQueryAsIntAll,
  removeQueryParamEntry
} from '../common'
import { selectCategories } from './examOrCourseSlice'
import { fetchCategories } from './examOrCourseAPI'

export default () => {
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useSearchParams()
  const categories = useSelector(selectCategories)
  const selectedCategoryIds = parseQueryAsIntAll(queryParams, 'categories[]')

  useEffect(() => {
    dispatch(fetchCategories({ perPage: 1024 }))
  }, [])

  const handleCheckboxChange = categoryId => checked =>
    setQueryParams(
      checked
        ? addQueryParamEntries(queryParams, [['categories[]', categoryId.toString()]])
        : removeQueryParamEntry(queryParams, ['categories[]', categoryId.toString()]),
      { replace: true })

  return (
    <section className='category-selector'>
      <h3 className='remoes-h3'>ジャンルで探す</h3>
      <ul className='list-unstyled p-3'>
        {_.map(
          categories,
          ({ id, name }) => {
            const checked = selectedCategoryIds.includes(id)
            const variant = checked ? 'primary' : 'outline-dark'
            return (
              <li key={id}>
                <Button variant={variant} as='label'>
                  <Form.Check
                    type='checkbox'
                    label={name}
                    checked={checked}
                    onChange={event => handleCheckboxChange(id)(event.target.checked)}
                  />
                </Button>
              </li>
            )
          })}
      </ul>
    </section>
  )
}
