import React from 'react'
import { useSelector } from 'react-redux'
import {
  Col,
  ListGroup,
  Nav,
  Row
} from 'react-bootstrap'
import {
  NavLink,
  Outlet
} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  selectUser
} from '../auth'

export const Admin = () => {
  const user = useSelector(selectUser)
  return (
    <>
      <Row className='h-100'>
        <Col sm='2'>
          <h2>管理エリア</h2>
          <ListGroup className='admin-sidemenu'>
            <ListGroup.Item
              as={NavLink}
              to='examOrCourseName'
            >試験・講座名
            </ListGroup.Item>
            <ListGroup.Item
              as={NavLink}
              to='examOrCourse'
            >試験・講座
            </ListGroup.Item>
            <ListGroup.Item
              as={NavLink}
              to='application'
            >受検者
            </ListGroup.Item>
            <ListGroup.Item
              as={NavLink}
              to='venueExecution'
            >会場
            </ListGroup.Item>
            {user?.privilege === 'super_user' &&
              <>
                <ListGroup.Item
                  as={NavLink}
                  to='category'
                >ジャンル
                </ListGroup.Item>
                <ListGroup.Item
                  as={NavLink}
                  to='tag'
                >タグ
                </ListGroup.Item>
                <ListGroup.Item
                  as={NavLink}
                  to='operationOrganization'
                >検定運営組織
                </ListGroup.Item>
                <ListGroup.Item
                  as={NavLink}
                  to='digitalCertificateList'
                >デジタル合格証データ一覧
                </ListGroup.Item>
                <ListGroup.Item
                  as={NavLink}
                  to='digitalCertificate'
                >デジタル合格証データ管理
                </ListGroup.Item>
              </>}
          </ListGroup>
        </Col>
        <Col sm='10'><Outlet /></Col>
      </Row>
    </>
  )
}

Admin.NavItem = () => {
  const user = useSelector(selectUser)
  if (!user || (user.operationOrganizations.length <= 0 && user.privilege !== 'super_user')) {
    return <></>
  }

  return (
    <Nav.Link
      as={NavLink}
      to='/admin'
    ><FontAwesomeIcon icon='wrench' />
    </Nav.Link>
  )
}
