import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  fetchCategoriesPayloadCreator,
  fetchTagsPayloadCreator
} from '../common'
import { invalidateCurrentUser } from '../auth'

export const fetchCategories = createAsyncThunk(
  'examOrCourse/fetchCategories',
  fetchCategoriesPayloadCreator(invalidateCurrentUser)
)

export const fetchTags = createAsyncThunk(
  'examOrCourse/fetchTags',
  fetchTagsPayloadCreator(invalidateCurrentUser)
)
