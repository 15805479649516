import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  csrfToken,
  toSnake,
  createPostalCodeSearchAsyncThunk,
  handleError
} from '../../common'
import { invalidateCurrentUser } from '../../auth'
import {
  fetchExamOrCoursesPayloadCreator,
  fetchVenueExecutionsPayloadCreator,
  fetchExamOrCourseNamesPayloadCreator
} from '../adminAPI'

export const createExamOrCourse = createAsyncThunk(
  'admin/examOrCourse/createExamOrCourse',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { operationOrganization: organizationId, ...createParams } = params
    return fetch(`${baseUri}admin/operation_organizations/${organizationId}/exam_or_courses`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake(createParams))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const searchAddressByPostalCode = createPostalCodeSearchAsyncThunk('admin/examOrCourse/searchAddressByPostalCode')

export const searchVenue = createAsyncThunk(
  'admin/examOrCourse/searchVenue',
  fetchVenueExecutionsPayloadCreator
)

export const fetchExamOrCourses = createAsyncThunk(
  'admin/examOrCourse/fetchExamOrCourses',
  fetchExamOrCoursesPayloadCreator
)

export const updateExamOrCourse = createAsyncThunk(
  'admin/examOrCourse/updateExamOrCourse',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { operationOrganization: organizationId, ...updateParams } = params
    const id = updateParams.examOrCourse.id
    return fetch(`${baseUri}admin/operation_organizations/${organizationId}/exam_or_courses/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake(updateParams))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const fetchExamOrCourseNames = createAsyncThunk(
  'admin/examOrCourse/fetchExamOrCourseNames',
  fetchExamOrCourseNamesPayloadCreator
)
