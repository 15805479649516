import React from 'react'
import {
  Alert,
  Button,
  Form,
  InputGroup,
  Modal
} from 'react-bootstrap'
import { Formik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { registeringPasswordSchema } from './validation'

export const ResetPasswordModal = ({
  show,
  onHide,
  onSubmit,
  passwordHidden,
  error,
  onToggleButtonClicked,
  email
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
  >
    <Formik
      initialValues={{ password: '' }}
      onSubmit={onSubmit}
      validationSchema={registeringPasswordSchema(email)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>パスワード再設定</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && error.messages && error.messages.length > 0 &&
              <Alert variant='danger'>{_.map(error.messages, (m, i) => <p key={i}>{m}</p>)}</Alert>}
            <Form.Group className='mb-3'>
              <Form.Label>パスワード</Form.Label>
              <InputGroup>
                <Form.Control
                  type={passwordHidden ? 'password' : 'text'}
                  name='password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && errors.password}
                />
                <Button
                  variant='secondary'
                  onClick={onToggleButtonClicked}
                >
                  <FontAwesomeIcon icon={passwordHidden ? 'eye-slash' : 'eye'} />
                </Button>
                <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              disabled={errors && Object.entries(errors).length > 0}
            >パスワードを再設定する
            </Button>
          </Modal.Footer>
        </Form>
      )}
    </Formik>
  </Modal>
)
