import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Col,
  Container,
  ListGroup,
  NavDropdown,
  Row
} from 'react-bootstrap'
import {
  NavLink
} from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'
import {
  Pagination,
  examOrCourseDisplay
} from '../../common'
import { selectUser } from '../../auth'
import {
  selectApplication,
  selectPage
} from '../examOrCourseSlice'
import {
  fetchApplications
} from './applicationAPI'
moment.locale('ja')

const ApplicationRow = ({
  application
}) => (
  <Row>
    <Col>
      <h5>
        {application.examOrCourse.operationOrganization.name} / {examOrCourseDisplay(application.examOrCourse)}
      </h5>
      <dl>
        <dt>申し込み期間</dt>
        <dd>{moment(application.examOrCourse.applicationStartAt).format('LLL')} - {moment(application.examOrCourse.applicationEndAt).format('LLL')}</dd>
        {_.map(
          application.examOrCourse.durations,
          ({ from, to, allDay }, index) => (
            <div key={index}>
              <dt>開催期間{application.examOrCourse.durations.length > 1 && index + 1}</dt>
              <dd>{moment(from).format(allDay ? 'LL' : 'LLL')} - {moment(to).format(allDay ? 'LL' : 'LLL')}</dd>
            </div>
          ))}
        <dt>会場</dt>
        <dd>{_.map(application.examOrCourse.venueExecutions, venueExecution => venueExecution.name)}</dd>
        <dt>受検料</dt>
        <dd>{(application.examOrCourse.price + application.examOrCourse.tax).toLocaleString('ja-JP')}円 (税込)</dd>

        <dt>点数</dt>
        <dd>{(application.score === null || application.score === undefined)
          ? '未発表'
          : application.score}
        </dd>
        <dt>合否</dt>
        <dd>{(application.passed === null || application.passed === undefined)
          ? '未発表'
          : (application.passed ? '合格' : '不合格')}
        </dd>
        <dt>支払い</dt>
        <dd>{(application.paymentCompletedAt === null || application.paymentCompletedAt === undefined)
          ? '未払い'
          : `${moment(application.paymentCompletedAt).format('LLL')}支払い済み`}
        </dd>
        <dt>受検番号</dt>
        <dd>{(application.examineeNumber === null || application.examineeNumber === undefined)
          ? '未登録'
          : application.examineeNumber}
        </dd>
        {_.map(
          application.user.extraAttributes,
          (extraAttribute, index) => (
            <div key={index}>
              <dt>{extraAttribute.name}</dt>
              <dd>{extraAttribute.value
                ? extraAttribute.value
                : _.join(extraAttribute.values ?? [], ', ')}
              </dd>
            </div>
          ))}
      </dl>
    </Col>
  </Row>
)

export const ApplicationList = () => {
  const user = useSelector(selectUser)
  const dispatch = useDispatch()
  const { list } = useSelector(selectApplication)
  useEffect(() => {
    dispatch(fetchApplications({
      sorting: list.sorting,
      page: list.currentPage
    }
    ))
  }, [user, list.sorting, list.currentPage])
  const handlePaginationClick = page => () => dispatch(selectPage({ page, propName: 'application' }))
  return (
    <>
      <Container>
        <ListGroup variant='flush' className='application-list'>
          {_.map(
            list.data,
            (application, index) => (
              <ListGroup.Item key={index}>
                <ApplicationRow application={application} />
              </ListGroup.Item>
            ))}
        </ListGroup>
      </Container>
      <Pagination
        currentPage={list.currentPage}
        totalPages={list.totalPages}
        onPaginationClick={handlePaginationClick}
      />
    </>
  )
}

ApplicationList.NavItem = () => (
  <NavDropdown.Item
    as={NavLink}
    to='/examOrCourse/application'
  >申し込み一覧
  </NavDropdown.Item>
)
