import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import {
  Badge,
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  ListGroup,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import {
  Pagination,
  examOrCourseDisplay,
  updateQueryParamEntries,
  removeQueryParamKeys,
  parseQueryAsInt
} from '../../common'
import {
  selectUser
} from '../../auth'
import {
  selectBrowse
} from '../examOrCourseSlice'
import {
  fetchExamOrCourses
} from './browseAPI'
import SearchPanel from './SearchPanel'
moment.locale('ja')

const statusDisplays = {
  draft: '下書き',
  open: startTime => startTime <= Date.now() ? '受付中' : '受付前',
  capReached: '満員',
  closed: '終了'
}

const StatusBadge = ({
  status,
  applicationStartAt
}) => {
  const statusDisplay = statusDisplays[status]
  return (
    <Badge pill bg='primary'>
      {typeof statusDisplay === 'function' ? statusDisplay(Date.parse(applicationStartAt)) : statusDisplay}
    </Badge>
  )
}

const ExamOrCourseRow = ({
  examOrCourse,
  onApplyClick
}) => (
  <Row>
    <Col>
      <h5>
        {examOrCourse.operationOrganization.name} / {examOrCourseDisplay(examOrCourse)}
        <StatusBadge
          status={_.camelCase(examOrCourse.status)}
          applicationStartAt={examOrCourse.applicationStartAt}
        />
      </h5>
      <dl>
        <dt>申し込み期間</dt>
        <dd>{moment(examOrCourse.applicationStartAt).format('LLL')} - {moment(examOrCourse.applicationEndAt).format('LLL')}</dd>
        {_.map(
          examOrCourse.durations,
          ({ from, to, allDay }, index) => (
            <div key={index}>
              <dt>開催期間{examOrCourse.durations.length > 1 && index + 1}</dt>
              <dd>{moment(from).format(allDay ? 'LL' : 'LLL')} - {moment(to).format(allDay ? 'LL' : 'LLL')}</dd>
            </div>
          ))}
        <dt>会場</dt>
        <dd>{_.map(examOrCourse.venueExecutions, venueExecution => venueExecution.name)}</dd>
        <dt>受検料</dt>
        <dd>{(examOrCourse.price + examOrCourse.tax).toLocaleString('ja-JP')}円 (税込)</dd>
      </dl>
    </Col>
    <Col sm='auto'>
      <Button
        variant='primary'
        onClick={onApplyClick(examOrCourse)}
      >詳細
      </Button>
    </Col>
  </Row>
)

const ExamOrCourseListContent = ({
  list,
  onApplyClick
}) => (
  <ListGroup variant='flush'>
    {_.map(
      list,
      (examOrCourse, index) => (
        <ListGroup.Item key={index}>
          <ExamOrCourseRow
            examOrCourse={examOrCourse}
            onApplyClick={onApplyClick}
          />
        </ListGroup.Item>
      ))}
  </ListGroup>
)

const sortKeys = [
  { title: '更新日', key: 'updatedAt' },
  { title: '検定・講座名', key: 'name' },
  { title: '申し込み開始日時', key: 'applicationStartAt' },
  { title: '申し込み終了日時', key: 'applicationEndAt' }
]

const sortOrders = [
  { title: '昇順', order: 'asc' },
  { title: '降順', order: 'desc' }
]

const SortingDropdown = ({
  onSelectSortingKey,
  selectedKey,
  onSelectSortingOrder,
  selectedOrder
}) => {
  return (
    <DropdownButton
      variant='secondary'
      title={_.find(sortKeys, { key: selectedKey })?.title ?? ''}
    >
      {_.map(sortKeys,
        ({ title, key }, index) => (
          <Dropdown.Item
            key={index}
            onClick={onSelectSortingKey(key)}
            active={selectedKey === key}
          >{title}
          </Dropdown.Item>
        ))}
      <Dropdown.Divider />
      {_.map(sortOrders,
        ({ title, order }, index) => (
          <Dropdown.Item
            key={index}
            onClick={onSelectSortingOrder(order)}
            active={selectedOrder === order}
          >{title}
          </Dropdown.Item>
        ))}
    </DropdownButton>
  )
}

const tabConditionMap = {
  all: {},
  capReached: {
    statuses: ['cap_reached']
  },
  capNotReached: {
    statuses: ['open']
  }
}

const searchKeys = [
  'name',
  'applicationStartSince',
  'applicationStartUntil',
  'applicationEndSince',
  'applicationEndUntil',
  'durationFromSince',
  'durationFromUntil',
  'durationToSince',
  'durationToUntil',
  'priceLeq',
  'priceGeq'
]

const fetchKeys = [
  ...searchKeys,
  'tab',
  'sorting[key]',
  'sorting[order]',
  'categories[]',
  'tags[]'
]

const parseQueryToFiltering = queryParams =>
  _.defaults(
    _.reduce(
      _.filter(
        [...queryParams.entries()],
        ([key, _value]) => fetchKeys.includes(key)),
      (obj, [key, value]) => {
        if (key.endsWith('[]')) {
          const objKey = key.substring(0, key.length - 2)
          return _.merge(
            obj,
            { [objKey]: [..._.has(obj, objKey) ? obj[objKey] : [], value] })
        }
        return _.merge(obj, { [key]: value })
      },
      {}),
    { 'sorting[key]': 'updatedAt', 'sorting[order]': 'desc' })

export const ExamOrCourseList = () => {
  const dispatch = useDispatch()
  const { list } = useSelector(selectBrowse)
  const user = useSelector(selectUser)
  const navigate = useNavigate()
  const [queryParams, setQueryParams] = useSearchParams()
  const currentPage = parseQueryAsInt(queryParams, 'page')
  const filtering = parseQueryToFiltering(queryParams)
  useEffect(() => {
    const filtering = parseQueryToFiltering(queryParams)
    const params = _.omitBy(
      _.merge(
        tabConditionMap[filtering.tab],
        {
          page: currentPage
        },
        filtering
      ),
      (value, key) => !value || key === 'tab')
    dispatch(fetchExamOrCourses(params))
  }, [list.tab, currentPage, user, queryParams])
  const handleSearch = values =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        Object.entries(values)),
      { replace: false })
  const handleReset = () =>
    setQueryParams(
      removeQueryParamKeys(
        queryParams,
        searchKeys),
      { replace: false })
  const handleSelectTab = key =>
    setQueryParams(
      updateQueryParamEntries(
        removeQueryParamKeys(
          queryParams,
          ['page', 'tab']),
        key === 'all' ? [] : [['tab', key]]),
      { replace: false })
  const handlePaginationClick = page => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['page', page]]),
      { replace: false })
  const handleSelectSortingKey = key => () =>
    setQueryParams(
      updateQueryParamEntries(
        removeQueryParamKeys(
          queryParams,
          ['page', 'sorting[key]']),
        key === 'updatedAt' ? [] : [['sorting[key]', key]]),
      { replace: false })
  const handleSelectSortingOrder = order => () =>
    setQueryParams(
      updateQueryParamEntries(
        removeQueryParamKeys(
          queryParams,
          ['page', 'sorting[order]']),
        order === 'desc' ? [] : [['sorting[order]', order]]),
      { replace: false })
  const handleApplyClick = examOrCourse => () => navigate(`/examOrCourse/browse/${examOrCourse.nameId}?selectedExamOrCourseId=${examOrCourse.id}`)
  return (
    <>
      <Container>
        <Row className='mb-2'>
          <Col>
            <SearchPanel
              queryParams={queryParams}
              onSearch={handleSearch}
              onReset={handleReset}
              initialValues={Object.fromEntries(_.filter(_.entries(filtering), ([key, _value]) => searchKeys.includes(key)))}
            />
          </Col>
          <Col sm='auto'>
            <SortingDropdown
              onSelectSortingKey={handleSelectSortingKey}
              selectedKey={filtering['sorting[key]']}
              onSelectSortingOrder={handleSelectSortingOrder}
              selectedOrder={filtering['sorting[order]']}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs
              transition={false}
              activeKey={list.key}
              onSelect={handleSelectTab}
            >{_.map(
              [{ key: 'all', title: '全て' },
                { key: 'capNotReached', title: '残席あり' },
                { key: 'capReached', title: '満員' }],
              ({ key, title }, index) => (
                <Tab key={index} eventKey={key} title={title}>
                  {list && <small>{list.totalCount}件</small>}
                  <ExamOrCourseListContent
                    list={list.data}
                    user={user}
                    onApplyClick={handleApplyClick}
                  />
                  <Pagination
                    currentPage={currentPage || 1}
                    totalPages={list.totalPages}
                    onPaginationClick={handlePaginationClick}
                  />
                </Tab>
              )
            )}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  )
}
