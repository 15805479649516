import {
  array,
  boolean,
  date,
  lazy,
  number,
  object,
  ref,
  string
} from 'yup'
import _ from 'lodash'
import { compose } from 'lodash/fp'
import { prefectures } from '../../common/validation'

const extractVenueSelectionFromParent = options => options.parent.venueSelection
const isEqualToExisting = selection => selection === 'existing'
const optianalOrRequiredSchema = schema => switcher => switcher ? schema.optional() : schema.required()
const optionalOrRequiredBasedOnVenueSelection = schema => compose(optianalOrRequiredSchema(schema), isEqualToExisting, extractVenueSelectionFromParent)
const optionalOrRequiredStringBasedOnVenueSelection = optionalOrRequiredBasedOnVenueSelection(string())
const optionalOrRequiredNumberBasedOnVenueSelection = optionalOrRequiredBasedOnVenueSelection(number())
const requiredOrOptionalBasedOnVenueSelection = schema => compose(optianalOrRequiredSchema(schema), _.negate(isEqualToExisting), extractVenueSelectionFromParent)
const requiredOrOptionalNumberBasedOnVenueSelection = requiredOrOptionalBasedOnVenueSelection(number())

export const createSchema = object({
  nameId: number().required(),
  levelId: number().optional().min(1),
  time: number().optional().min(1),
  operationOrganization: number().required().min(1, '選択してください'),
  durations: array().min(1).of(lazy((item, options) => {
    const durations = options.parent
    const index = durations instanceof Array ? durations.indexOf(item) : -1
    const previousDuration = index < 1 ? null : durations[index - 1]
    return object({
      from: previousDuration ? date().required().min(previousDuration.to, '期間が重なっています') : date().required(),
      to: date().required().min(ref('from'), '開始より先を入力してください')
    })
  })),
  applicationStartAt: date().required(),
  applicationEndAt: date().required().min(ref('applicationStartAt'), '開始より先を入力してください'),
  price: number().min(1).required(),
  tax: number().min(0).required(),
  resultPublishedAt: date().required(),
  venueSelection: string().required().oneOf(['existing', 'create']),
  shareCapacity: boolean().required(),
  venueName: lazy((_value, options) => optionalOrRequiredStringBasedOnVenueSelection(options)),
  venueCapacity: lazy((_value, options) => optionalOrRequiredNumberBasedOnVenueSelection(options).positive()),
  venuePostalCode: string().optional().matches(/\d{7}/, '数字7桁で入力してください'),
  venuePrefecture: string().optional().oneOf(prefectures),
  venueCity: string().optional(),
  venueAddressLine1: string().optional(),
  venueAddressLine2: string().optional(),
  venueId: lazy((_value, options) => requiredOrOptionalNumberBasedOnVenueSelection(options)),
  extraAttributes: array().min(0).of(object({
    name: string().required(),
    extraAttributeType: string().required().oneOf(['single_choice', 'multiple_choice', 'open_ended']),
    description: string().optional(),
    choices: array().min(0).of(string().required())
  }))
})
