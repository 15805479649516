import React from 'react'
import {
  Col,
  Form,
  Row
} from 'react-bootstrap'
import { SearchPanel } from '../../common'

export default (props) => (
  <SearchPanel {...props} queryPropName='name'>
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur
    }) => (
      <>
        <Row>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>申し込み開始がこれ以降</Form.Label>
            <Form.Control
              type='date'
              name='applicationStartSince'
              value={values.applicationStartSince}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.applicationStartSince && errors.applicationStartSince}
            />
            <Form.Control.Feedback type='invalid'>{errors.applicationStartSince}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>申し込み開始がこれ以前</Form.Label>
            <Form.Control
              type='date'
              name='applicationStartUntil'
              value={values.applicationStartUntil}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.applicationStartUntil && errors.applicationStartUntil}
            />
            <Form.Control.Feedback type='invalid'>{errors.applicationStartUntil}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>申し込み終了がこれ以降</Form.Label>
            <Form.Control
              type='date'
              name='applicationEndSince'
              value={values.applicationEndSince}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.applicationEndSince && errors.applicationEndSince}
            />
            <Form.Control.Feedback type='invalid'>{errors.applicationEndSince}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>申し込み終了がこれ以前</Form.Label>
            <Form.Control
              type='date'
              name='applicationEndUntil'
              value={values.applicationEndUntil}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.applicationEndUntil && errors.applicationEndUntil}
            />
            <Form.Control.Feedback type='invalid'>{errors.applicationEndUntil}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>開催開始がこれ以降</Form.Label>
            <Form.Control
              type='date'
              name='durationFromSince'
              value={values.durationFromSince}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.durationFromSince && errors.durationFromSince}
            />
            <Form.Control.Feedback type='invalid'>{errors.durationFromSince}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>開催開始がこれ以前</Form.Label>
            <Form.Control
              type='date'
              name='durationFromUntil'
              value={values.durationFromUntil}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.durationFromUntil && errors.durationFromUntil}
            />
            <Form.Control.Feedback type='invalid'>{errors.durationFromUntil}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>開催終了がこれ以降</Form.Label>
            <Form.Control
              type='date'
              name='durationToSince'
              value={values.durationToSince}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.durationToSince && errors.durationToSince}
            />
            <Form.Control.Feedback type='invalid'>{errors.durationToSince}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>開催終了がこれ以前</Form.Label>
            <Form.Control
              type='date'
              name='durationToUntil'
              value={values.durationToUntil}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.durationToUntil && errors.durationToUntil}
            />
            <Form.Control.Feedback type='invalid'>{errors.durationToUntil}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>費用がこれ以下</Form.Label>
            <Form.Control
              type='number'
              name='priceLeq'
              value={values.priceLeq}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.priceLeq && errors.priceLeq}
            />
            <Form.Control.Feedback type='invalid'>{errors.priceLeq}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3' as={Col} xs='6'>
            <Form.Label>費用がこれ以上</Form.Label>
            <Form.Control
              type='number'
              name='priceGeq'
              value={values.priceGeq}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.priceGeq && errors.priceGeq}
            />
            <Form.Control.Feedback type='invalid'>{errors.priceGeq}</Form.Control.Feedback>
          </Form.Group>
        </Row>
      </>
    )}
  </SearchPanel>
)
