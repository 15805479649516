import React from 'react'
import {
  Alert,
  Button,
  ButtonGroup,
  Col,
  Form,
  Modal,
  Row
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  FieldArray,
  Formik
} from 'formik'
import _ from 'lodash'
import {
  Typeahead
} from 'react-bootstrap-typeahead'
import moment from 'moment'
import {
  PostalCodeInput,
  prefectures,
  examOrCourseDisplay
} from '../../common'
import { createSchema } from './validation'

const examOrCourseStatusTitles = {
  draft: '下書き',
  open: '公開済み',
  cap_reached: '満員',
  closed: '終了済み'
}

const keyToKeyTitleObject = key => ({ key, title: examOrCourseStatusTitles[key] })

export const examOrCourseStatuses = _.map(
  ['draft', 'open', 'cap_reached', 'closed'],
  keyToKeyTitleObject)

export const destinationStatuses = _.mapValues(
  {
    initial: ['draft'],
    draft: ['draft', 'open', 'closed'],
    open: ['draft', 'open', 'closed'],
    cap_reached: ['cap_reached', 'closed'],
    closed: ['closed']
  },
  keys => _.map(keys, keyToKeyTitleObject))

const Error = ({ error }) => error
  ? (
    <Alert variant='danger'>
      {error.message && error.message}
      {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
    </Alert>
    )
  : <></>

const Notice = ({ notice }) => notice ? <Alert variant='success'>{notice}</Alert> : <></>

const MenuItem = ({ name, examOrCourses }) => {
  const examOrCourseNames = _.join(_.map(examOrCourses, examOrCourse => examOrCourseDisplay(examOrCourse)), ', ')
  const postFix = examOrCourseNames ? ` (${examOrCourseNames})` : ''
  return name + postFix
}

const ExamOrCourseNameMenuItem = ({ id, value }) => `${id}: ${value}`

const ExamOrCourseLevelInput = ({
  values,
  touched,
  errors,
  nameCandidates,
  onChange,
  onBlur
}) => {
  if (!values.nameId) {
    return <></>
  }
  const examOrCourseName = _.find(nameCandidates, ({ id }) => id === values.nameId)
  if (!examOrCourseName?.levels || examOrCourseName?.levels?.length <= 0) {
    return <p>級・レベルの定義がありません。</p>
  }
  return (
    <Form.Control
      as='select'
      name='levelId'
      value={values.levelId}
      onChange={onChange}
      onBlur={onBlur}
      isInvalid={touched.levelId && errors.levelId}
    >{_.map(
      _.concat([{ id: 0, name: '選択してください' }], examOrCourseName.levels),
      ({ id, name }) => (
        <option key={id} value={id}>{name}</option>
      ))}
    </Form.Control>
  )
}

export const UpsertModal = ({
  show,
  onHide,
  onSubmit,
  fetching,
  notice,
  error,
  operationOrganizations,
  venueAddressCandidates,
  onPostalCodeInputChange,
  onVenueExecutionIdInputChange,
  onVenueExecutionIdSelectChanged,
  venueCandidates,
  examOrCourse,
  onNameInputChange,
  nameCandidates,
  onNameIdSelected,
  onOperationOrganizationSelected
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
    backdrop='static'
    keyboard={false}
  >
    <Formik
      initialValues={{
        nameId: examOrCourse?.nameId ?? '',
        levelId: examOrCourse?.level?.id ?? '',
        time: examOrCourse?.time ?? '',
        operationOrganization: examOrCourse?.operationOrganization.id ??
          (operationOrganizations?.length === 1 ? operationOrganizations[0].id : 0),
        status: examOrCourse?.status ?? 'draft',
        durations: examOrCourse
          ? _.map(
            examOrCourse.durations,
            ({ from, to, allDay }) => ({
              from: moment(from).format(allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm'),
              to: moment(to).format(allDay ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm')
            }))
          : [{ from: '', to: '' }],
        allDay: (examOrCourse?.durations.length > 0) ? examOrCourse?.durations[0].allDay : true,
        price: examOrCourse?.price ?? 0,
        tax: examOrCourse?.tax ?? 0,
        applicationStartAt: examOrCourse?.applicationStartAt ? moment(examOrCourse?.applicationStartAt).format('YYYY-MM-DDTHH:mm') : '',
        applicationEndAt: examOrCourse?.applicationEndAt ? moment(examOrCourse?.applicationEndAt).format('YYYY-MM-DDTHH:mm') : '',
        resultPublishedAt: examOrCourse?.resultPublishedAt ? moment(examOrCourse?.resultPublishedAt).format('YYYY-MM-DDTHH:mm') : '',
        venueSelection: 'existing',
        shareCapacity: false,
        venueName: '',
        venueCapacity: '',
        venuePostalCode: '',
        venuePrefecture: '',
        venueCity: '',
        venueAddressLine1: '',
        venueAddressLine2: '',
        venueId: examOrCourse?.venueExecutions[0].venueId ?? '',
        venueExecutionId: examOrCourse?.venueExecutions[0].id ?? '',
        extraAttributes: _.map(examOrCourse?.extraAttributes, ({ required, ...rest }) => ({ ...rest, required: required ? 'required' : 'optional' })) ?? []
      }}
      onSubmit={onSubmit}
      validationSchema={createSchema}
    >{({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      setFieldError
    }) => {
      const nameIdInput = React.createRef()
      const venueExecutionIdInput = React.createRef()
      return (
        <Form noValidate onSubmit={handleSubmit}>
          <Modal.Header>
            <Modal.Title>{examOrCourse ? '編集' : '新規作成'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Error error={error} />
            <Notice notice={notice} />
            <Form.Group className='mb-3'>
              <Form.Label>運営組織</Form.Label>
              <Form.Control
                as='select'
                name='operationOrganization'
                value={values.operationOrganization}
                onChange={event => {
                  _.map(
                    ['nameId', 'levelId', 'venueId', 'venueExecutionId'],
                    fieldId => setFieldValue(fieldId, ''))
                  nameIdInput.current.clear()
                  venueExecutionIdInput.current.clear()
                  onOperationOrganizationSelected()
                  handleChange(event)
                }}
                onBlur={handleBlur}
                isInvalid={touched.operationOrganization && errors.operationOrganization}
                disabled={operationOrganizations?.length === 1 || examOrCourse}
              >{_.map(
                _.concat([{ id: 0, name: '選択してください' }], operationOrganizations || []),
                ({ id, name }, index) => (
                  <option key={index} value={id}>{name}</option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>{errors.operationOrganization}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>ステータス</Form.Label>
              <Form.Control
                as='select'
                name='status'
                value={values.status}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.status && errors.status}
                disabled={!examOrCourse}
              >{_.map(destinationStatuses[examOrCourse?.status ?? 'initial'], ({ key, title }, i) => <option key={i} value={key}>{title}</option>)}
              </Form.Control>
              <Form.Control.Feedback type='invalid'>{errors.status}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>試験・講座名</Form.Label>
              <Typeahead
                id='nameId'
                ref={nameIdInput}
                className='is-invalid'
                onInputChange={onNameInputChange({
                  preDispatchCallback: () => setFieldValue('nameId', ''),
                  operationOrganizationId: values.operationOrganization
                })}
                onFocus={event => onNameInputChange({
                  operationOrganizationId: values.operationOrganization
                })(event.target.value)}
                options={nameCandidates}
                labelKey='value'
                renderMenuItemChildren={ExamOrCourseNameMenuItem}
                onChange={onNameIdSelected(selectedName => {
                  setFieldValue('nameId', selectedName.id)
                  setFieldValue('levelId', '')
                })}
                inputProps={{
                  name: 'nameId',
                  isInvalid: touched.nameId && errors.nameId
                }}
                defaultInputValue={examOrCourse?.name ?? ''}
                onBlur={handleBlur}
              />
              <Form.Control.Feedback type='invalid'>{values.operationOrganization === 0 ? '運営組織を選択してください' : errors.nameId}</Form.Control.Feedback>
            </Form.Group>
            <Row className='mb-3'>
              <Form.Group as={Col} xs='6'>
                <Form.Label>級・レベル</Form.Label>
                <ExamOrCourseLevelInput
                  values={values}
                  touched={touched}
                  errors={errors}
                  nameCandidates={nameCandidates}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldError={setFieldError}
                />
                <Form.Control.Feedback type='invalid'>{errors.levelId}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs='6'>
                <Form.Label>試験回数</Form.Label>
                <Form.Control
                  type='number'
                  name='time'
                  value={values.time}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.time && errors.time}
                />
                <Form.Control.Feedback type='invalid'>{errors.time}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} xs='6'>
                <Form.Label>申し込み開始日時</Form.Label>
                <Form.Control
                  type='datetime-local'
                  name='applicationStartAt'
                  value={values.applicationStartAt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.applicationStartAt && errors.applicationStartAt}
                />
                <Form.Control.Feedback type='invalid'>{errors.applicationStartAt}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs='6'>
                <Form.Label>申し込み終了日時</Form.Label>
                <Form.Control
                  type='datetime-local'
                  name='applicationEndAt'
                  value={values.applicationEndAt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.applicationEndAt && errors.applicationEndAt}
                />
                <Form.Control.Feedback type='invalid'>{errors.applicationEndAt}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} xs='6'>
                <Form.Label>受検料金 (税抜)</Form.Label>
                <Form.Control
                  type='number'
                  name='price'
                  value={values.price}
                  onChange={handleChange}
                  onBlur={event => {
                    handleBlur(event)
                    setFieldValue('tax', Math.floor(values.price * 0.1))
                  }}
                  isInvalid={touched.price && errors.price}
                />
                <Form.Control.Feedback type='invalid'>{errors.price}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} xs='6'>
                <Form.Label>受検料金 (税額)</Form.Label>
                <Form.Control
                  type='number'
                  name='tax'
                  value={values.tax}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.tax && errors.tax}
                />
                <Form.Control.Feedback type='invalid'>{errors.tax}</Form.Control.Feedback>
              </Form.Group>
            </Row>
            <FieldArray name='durations'>
              {({ push, remove }) => (
                <>
                  {_.map(
                    values.durations,
                    (duration, index) => (
                      <Row key={index}>
                        <Form.Label>開催期間 {values.durations.length > 1 ? `${index + 1}` : ''}</Form.Label>
                        <Form.Group className='mb-3' as={Col} xs='6'>
                          <Form.Control
                            type={values.allDay ? 'date' : 'datetime-local'}
                            name={`durations[${index}].from`}
                            value={duration?.from}
                            onChange={handleChange}
                            onBlur={event => {
                              if (!duration?.to) {
                                setFieldValue(`durations[${index}].to`, duration?.from)
                              }
                              handleBlur(event)
                            }}
                            isInvalid={
                              touched.durations &&
                                touched.durations.length > index &&
                                touched.durations[index]?.from &&
                                errors.durations &&
                                errors.durations.length > index &&
                                errors.durations[index]?.from
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.durations &&
                             errors.durations.length > index &&
                             errors.durations[index]?.from}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' as={Col} xs='6'>
                          <Form.Control
                            type={values.allDay ? 'date' : 'datetime-local'}
                            name={`durations[${index}].to`}
                            value={duration?.to}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={
                              touched.durations &&
                                touched.durations.length > index &&
                                touched.durations[index]?.to &&
                                errors.durations &&
                                errors.durations.length > index &&
                                errors.durations[index]?.to
                            }
                          />
                          <Form.Control.Feedback type='invalid'>
                            {errors.durations &&
                             errors.durations.length > index &&
                             errors.durations[index]?.to}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    ))}
                  <Row>
                    <Col>
                      <Form.Check
                        type='checkbox'
                        name='allDay'
                        onChange={event => {
                          _.forEach(
                            values.durations,
                            (duration, index) => _.forEach(
                              ['from', 'to'],
                              (propName) => {
                                const currentValue = duration[propName]
                                const d = currentValue ? new Date(currentValue) : new Date()
                                const newValue = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}` + (
                                  event.target.checked
                                    ? ''
                                    : 'T09:00')
                                setFieldValue(`durations[${index}][${propName}]`, newValue)
                              }))
                          handleChange(event)
                        }}
                        onBlur={handleBlur}
                        checked={values.allDay}
                        label='終日'
                      />
                    </Col>
                    <Col sm='auto'>
                      <ButtonGroup className='mb-3'>
                        <Button
                          size='sm'
                          onClick={() => push(values.durations[values.durations.length - 1])}
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon='plus'
                          />
                        </Button>
                        <Button
                          size='sm'
                          onClick={() => remove(values.durations.length - 1)}
                          disabled={values.durations.length === 1}
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon='minus'
                          />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                </>
              )}
            </FieldArray>
            <Form.Group className='mb-3'>
              <Form.Label>結果発表日時</Form.Label>
              <Form.Control
                type='datetime-local'
                name='resultPublishedAt'
                value={values.resultPublishedAt}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.resultPublishedAt && errors.resultPublishedAt}
              />
              <Form.Control.Feedback type='invalid'>{errors.resultPublishedAt}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>会場</Form.Label>
              <Form.Check
                type='radio'
                name='venueSelection'
                label='新しく会場を登録する'
                value='create'
                checked={values.venueSelection === 'create'}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Check
                type='radio'
                name='venueSelection'
                label='既存の会場から選ぶ'
                value='existing'
                checked={values.venueSelection === 'existing'}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <Form.Check
                type='checkbox'
                name='shareCapacity'
                label='定員を共有する'
                checked={values.venueSelection === 'existing' && values.shareCapacity}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={values.venueSelection !== 'existing'}
              />
            </Form.Group>
            {values.venueSelection === 'existing'
              ? (
                <Form.Group className='mb-3'>
                  <Typeahead
                    id='venueExecutionId'
                    ref={venueExecutionIdInput}
                    className='is-invalid'
                    onChange={onVenueExecutionIdSelectChanged({ setFieldValue })}
                    onInputChange={onVenueExecutionIdInputChange({
                      operationOrganization: values.operationOrganization,
                      setFieldValue
                    })}
                    options={venueCandidates}
                    renderMenuItemChildren={MenuItem}
                    filterBy={() => true}
                    labelKey='name'
                    onBlur={handleBlur}
                    inputProps={{
                      name: 'venueExecutionId',
                      isInvalid: touched.venueExecutionId && (values.operationOrganization === 0 || errors.venueExecutionId)
                    }}
                    defaultInputValue={examOrCourse?.venueExecutions[0].name ?? ''}
                  />
                  <Form.Control.Feedback type='invalid'>{
                  values.operationOrganization === 0 ? '運営組織を選択してください' : errors.venueExecutionId
                }
                  </Form.Control.Feedback>
                </Form.Group>
                )
              : (
                <>
                  <Form.Group className='mb-3'>
                    <Form.Label>会場名</Form.Label>
                    <Form.Control
                      type='text'
                      name='venueName'
                      value={values.venueName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.venueName && errors.venueName}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.venueName}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>定員</Form.Label>
                    <Form.Control
                      type='number'
                      name='venueCapacity'
                      value={values.venueCapacity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.venueCapacity && errors.venueCapacity}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.venueCapacity}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>郵便番号</Form.Label>
                    <PostalCodeInput
                      id='venuePostalCode'
                      defaultInputValue={values.venuePostalCode}
                      setFieldValue={setFieldValue}
                      postalCodeFieldName='venuePostalCode'
                      prefectureFieldName='venuePrefecture'
                      cityFieldName='venueCity'
                      addressLineFieldName='venueAddressLine1'
                      onInputChange={onPostalCodeInputChange}
                      options={venueAddressCandidates}
                      isInvalid={touched.venuePostalCode && !!errors.venuePostalCode}
                      onBlur={handleBlur}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.venuePostalCode}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>都道府県</Form.Label>
                    <Form.Control
                      as='select'
                      name='venuePrefecture'
                      value={values.venuePrefecture}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.venuePrefecture && errors.venuePrefecture}
                    >{_.map(
                      _.concat([['選択してください', '']], _.map(prefectures, prefecture => [prefecture, prefecture])),
                      ([display, value], i) => <option key={i} value={value}>{display}</option>)}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>{errors.venuePrefecture}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>市区町村</Form.Label>
                    <Form.Control
                      type='text'
                      name='venueCity'
                      value={values.venueCity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.venueCity && errors.venueCity}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.venueCity}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>丁目・番地・号</Form.Label>
                    <Form.Control
                      type='text'
                      name='venueAddressLine1'
                      value={values.venueAddressLine1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.venueAddressLine1 && errors.venueAddressLine1}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.venueAddressLine1}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className='mb-3'>
                    <Form.Label>建物名・部屋番号</Form.Label>
                    <Form.Control
                      type='text'
                      name='venueAddressLine2'
                      value={values.venueAddressLine2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.venueAddressLine2 && errors.venueAddressLine2}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.venueAddressLine2}</Form.Control.Feedback>
                  </Form.Group>
                </>
                )}
            <Form.Group className='mb-3'>
              <Form.Label>追加属性</Form.Label>
              <FieldArray name='extraAttributes'>
                {({ push, remove }) => (
                  <>
                    {_.map(
                      values.extraAttributes,
                      (extraAttribute, index) => (
                        <FieldArray name={`extraAttributes[${index}].choices`}>
                          {({ push: pushChoice, remove: removeChoice }) => (
                            <Row key={`extraAttributes${index}`} className={`extra-attributes-${index}`}>
                              {extraAttribute.id &&
                                <Form.Control
                                  type='hidden'
                                  name={`extraAttributes[${index}].id`}
                                  value={extraAttribute.id}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />}
                              <Form.Group>
                                <Form.Label>追加属性名</Form.Label>
                                <Form.Control
                                  type='text'
                                  name={`extraAttributes[${index}].name`}
                                  value={extraAttribute.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={touched.extraAttributes && touched.extraAttributes[index] &&
                                       touched.extraAttributes[index].name &&
                                       errors.extraAttributes && errors.extraAttributes[index] &&
                                       errors.extraAttributes[index].name}
                                />
                                <Form.Control.Feedback type='invalid'>{
                            errors.extraAttributes && errors.extraAttributes[index] &&
                              errors.extraAttributes[index].name
}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>必須・任意設定</Form.Label>
                                <Form.Check
                                  type='radio'
                                  name={`extraAttributes[${index}].required`}
                                  label='必須'
                                  value='required'
                                  checked={extraAttribute.required === 'required'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <Form.Check
                                  type='radio'
                                  name={`extraAttributes[${index}].required`}
                                  label='任意'
                                  value='optional'
                                  checked={extraAttribute.required === 'optional'}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>属性タイプ</Form.Label>
                                <Form.Control
                                  as='select'
                                  name={`extraAttributes[${index}].extraAttributeType`}
                                  value={extraAttribute.extraAttributeType}
                                  onChange={event => {
                                    if (event.target.value === 'open_ended') {
                                      _.forEach(extraAttribute.choices, choice => removeChoice(choice))
                                    } else {
                                      _.times(2 - extraAttribute.choices.length, () => pushChoice(''))
                                    }
                                    handleChange(event)
                                  }}
                                  onBlur={handleBlur}
                                >{_.map(
                                  [{ value: 'single_choice', name: '単一選択' },
                                    { value: 'multiple_choice', name: '複数選択' },
                                    { value: 'open_ended', name: '自由記述' }],
                                  ({ value, name }, index) => (<option key={index} value={value}>{name}</option>)
                                )}
                                </Form.Control>
                              </Form.Group>
                              <Form.Group>
                                <Form.Label>属性の説明 (オプショナル)</Form.Label>
                                <Form.Control
                                  type='text'
                                  name={`extraAttributes[${index}].description`}
                                  value={extraAttribute.description}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={touched.extraAttributes && touched.extraAttributes[index] &&
                                       touched.extraAttributes[index].description &&
                                       errors.extraAttributes && errors.extraAttributes[index] &&
                                       errors.extraAttributes[index].description}
                                />
                                <Form.Control.Feedback type='invalid'>{
                            errors.extraAttributes && errors.extraAttributes[index] &&
                              errors.extraAttributes[index].description
}
                                </Form.Control.Feedback>
                              </Form.Group>
                              <>
                                <Form.Label>選択肢</Form.Label>
                                {_.map(
                                  extraAttribute.choices,
                                  (choice, choiceIndex) => (
                                    <Form.Group>
                                      <Form.Label>選択肢{choiceIndex + 1}</Form.Label>
                                      <Form.Control
                                        type='text'
                                        name={`extraAttributes[${index}].choices[${choiceIndex}]`}
                                        value={choice}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.extraAttributes && touched.extraAttributes[index] &&
                                                   touched.extraAttributes[index].choices &&
                                                   touched.extraAttributes[index].choices[choiceIndex] &&
                                                   errors.extraAttributes && errors.extraAttributes[index] &&
                                                   errors.extraAttributes[index].choices &&
                                                   errors.extraAttributes[index].choices[choiceIndex]}
                                      />
                                      <Form.Control.Feedback type='invalid'>{
                                        errors.extraAttributes && errors.extraAttributes[index] &&
                                          errors.extraAttributes[index].choices &&
                                          errors.extraAttributes[index].choices[choiceIndex]
                                      }
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  ))}
                                <Col sm='auto'>
                                  <ButtonGroup className='mb-3 choices'>
                                    <Button
                                      size='sm'
                                      className='plus'
                                      onClick={() => pushChoice('')}
                                      disabled={extraAttribute.extraAttributeType === 'open_ended'}
                                    >
                                      <FontAwesomeIcon fixedWidth icon='plus' />
                                    </Button>
                                    <Button
                                      size='sm'
                                      className='minus'
                                      onClick={() => removeChoice(extraAttribute.choices[extraAttribute.choices.length - 1])}
                                      disabled={extraAttribute.choices.length < 3}
                                    >
                                      <FontAwesomeIcon fixedWidth icon='minus' />
                                    </Button>
                                  </ButtonGroup>
                                </Col>
                              </>
                            </Row>
                          )}
                        </FieldArray>
                      ))}
                    <Col sm='auto'>
                      <ButtonGroup className='mb-3 extra-attributes'>
                        <Button
                          size='sm'
                          className='plus'
                          onClick={() => push({
                            name: '',
                            extraAttributeType: 'single_choice',
                            description: '',
                            choices: ['', ''],
                            required: 'required'
                          })}
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon='plus'
                          />
                        </Button>
                        <Button
                          size='sm'
                          className='minus'
                          onClick={() => remove(values.extraAttributes.length - 1)}
                          disabled={values.extraAttributes.length === 0}
                        >
                          <FontAwesomeIcon
                            fixedWidth
                            icon='minus'
                          />
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </>
                )}
              </FieldArray>
            </Form.Group>
            <Error error={error} />
            <Notice notice={notice} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='button'
              variant='secondary'
              onClick={onHide}
            >{notice ? '閉じる' : 'キャンセル'}
            </Button>
            <Button
              type='submit'
              style={{ display: notice ? 'none' : null }}
              disabled={(errors && Object.entries(errors).length > 0) || fetching}
            >{examOrCourse ? '更新する' : '作成する'}
            </Button>
          </Modal.Footer>
        </Form>
      )
    }}
    </Formik>
  </Modal>
)
