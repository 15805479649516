import {
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import {
  updatePassword,
  updateEmail
} from './settingAPI'

const initialState = {
  passwordSetting: {
    currentPasswordHidden: true,
    passwordHidden: true
  },
  emailSetting: {
    passwordHidden: true
  }
}

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    togglePasswordHidden: (state, action) => {
      const [propName1, propName2] = action.payload
      state[propName1][propName2] = !state[propName1][propName2]
    },
    dismissNotice: (state, action) => {
      const propName = action.payload
      delete state[propName].notice
    },
    dismissAlert: (state, action) => {
      const propName = action.payload
      delete state[propName].error
    }
  },
  extraReducers: builder => builder
    .addCase(updatePassword.pending, state => {
      state.fetching = true
      delete state.passwordSetting.error
    })
    .addCase(updatePassword.fulfilled, state => {
      state.fetching = false
      state.passwordSetting.notice = 'パスワードの更新を完了しました'
      delete state.passwordSetting.error
    })
    .addCase(updatePassword.rejected, (state, action) => {
      state.fetching = false
      state.passwordSetting.error = action.payload
    })
    .addCase(updateEmail.pending, state => {
      state.fetching = true
      delete state.emailSetting.error
    })
    .addCase(updateEmail.fulfilled, state => {
      state.fetching = false
      state.emailSetting.notice = '確認メールを送信しました。メール内のリンクをクリックしてください。'
      delete state.emailSetting.error
    })
    .addCase(updateEmail.rejected, (state, action) => {
      state.fetching = false
      state.emailSetting.error = action.payload
    })
})

export const selectSetting = state => state.setting

export const selectPasswordSetting = createSelector(
  selectSetting,
  setting => setting.passwordSetting
)

export const selectEmailSetting = createSelector(
  selectSetting,
  setting => setting.emailSetting
)

export const {
  togglePasswordHidden,
  dismissNotice,
  dismissAlert
} = settingSlice.actions
