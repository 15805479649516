import React from 'react'
import {
  Link
} from 'react-router-dom'
import {
  Button,
  Image
} from 'react-bootstrap'
import _ from 'lodash'

const buttonTextDisplays = application => application
  ? {
      created: '申し込み未完了',
      paymentCompleted: '申し込み済み',
      canceled: 'キャンセル済み'
    }[_.camelCase(application.status)]
  : '申し込む'

const ApplyButton = ({
  status,
  application,
  onClick
}) => (
  <Button
    variant='danger'
    disabled={(status === 'cap_reached' && application?.status !== 'created') ||
            application?.status === 'payment_completed' ||
            (application?.status === 'canceled' && !application?.paymentCompletedAt)}
    onClick={onClick}
  >{buttonTextDisplays(application)}
  </Button>
)

const ExamOrCourseNameDisplay = ({
  value,
  category,
  tags,
  logoImage,
  description,
  sections,
  explanatoryItems,
  onApply,
  examOrCourse
}) => (
  <div className='remoes-main col-12 col-md-9 p-4 bg-white rounded-2 shadow'>
    <h1 className='remoes-h1 d-flex justify-content-between'>{value}</h1>
    {category &&
      <ul className='list-unstyled d-flex flex-wrap mb-0'>
        <li className='p-1'>
          <Link to={`/examOrCourse/browse?${new URLSearchParams([['categories[]', category.id]]).toString()}`}>
            <Button
              variant='outline-primary'
              size='sm'
            >{category.name}
            </Button>
          </Link>
        </li>
      </ul>}
    {tags?.length > 0 &&
      <ul className='list-unstyled d-flex flex-wrap'>
        {_.map(
          tags,
          ({ id, name }) => (
            <li className='p-1' key={id}>
              <Link to={`/examOrCourse/browse?${new URLSearchParams([['tags[]', id]]).toString()}`}>
                <Button
                  variant='outline-dark'
                  size='sm'
                >{name}
                </Button>
              </Link>
            </li>
          ))}
      </ul>}
    <section className='remoes-item d-md-flex justify-content-between mt-4'>
      <figure className='col-md-3 text-center'>
        {logoImage && <Image src={logoImage} width='240' />}
      </figure>
      <div className='col-md-9'>
        <p>{description}</p>
        <p className='text-end'>
          <ApplyButton
            {...examOrCourse}
            onClick={onApply}
          />
        </p>
      </div>
    </section>
    {_.map(
      sections,
      section => (
        <section className='remoes-detail mt-4'>
          <h3 className='remoes-h3'>{section.name}</h3>
          {_.map(
            explanatoryItems[section.id],
            explanatoryItem => (
              <dl className='d-md-flex p-3'>
                <dt className='col-md-3'>{explanatoryItem.title}</dt>
                <dd>
                  {_.map(
                    explanatoryItem.content.split(/\r?\n/),
                    (line, index) => (
                      <div key={index}>
                        {line}
                        <br />
                      </div>
                    )
                  )}
                </dd>
              </dl>
            ))}
        </section>
      ))}
  </div>
)

export default ExamOrCourseNameDisplay
