import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import _ from 'lodash'
import {
  selectUser,
  authenticatingPasswordSchema,
  emailSchema
} from '../auth'
import {
  dismissAlert,
  dismissNotice,
  selectEmailSetting,
  togglePasswordHidden
} from './settingSlice'
import {
  updateEmail
} from './settingAPI'

export const EmailSetting = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const { passwordHidden, notice, error } = useSelector(selectEmailSetting)
  const handleSubmit = values => dispatch(updateEmail({ ...values, id: user.id }))
  const handlePasswordToggleButtonClick = propNames => () => dispatch(togglePasswordHidden(propNames))
  const handleNoticeClose = () => dispatch(dismissNotice('emailSetting'))
  const handleAlertClose = () => dispatch(dismissAlert('emailSetting'))
  return (
    <Formik
      initialValues={{ password: '', email: '' }}
      onSubmit={handleSubmit}
      validationSchema={authenticatingPasswordSchema.concat(emailSchema)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Col>
              <h3>メールアドレス</h3>
            </Col>
            <Col sm='auto'>
              <Button
                type='submit'
                disabled={!user || (errors && Object.entries(errors).length > 0)}
              >保存する
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {error &&
                <Alert
                  variant='danger'
                  dismissible
                  onClose={handleAlertClose}
                >
                  {error.message && error.message}
                  {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
                </Alert>}
              {notice &&
                <Alert
                  variant='success'
                  dismissible
                  onClose={handleNoticeClose}
                >{notice}
                </Alert>}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>メールアドレス</Form.Label>
                <Form.Control
                  type='email'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && errors.email}
                />
                <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className='mb-3'>
                <Form.Label>パスワード</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={passwordHidden ? 'password' : 'text'}
                    name='password'
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.password && errors.password}
                  />
                  <Button
                    variant='secondary'
                    onClick={handlePasswordToggleButtonClick(['emailSetting', 'passwordHidden'])}
                  >
                    <FontAwesomeIcon icon={passwordHidden ? 'eye-slash' : 'eye'} />
                  </Button>
                  <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}
