import React from 'react'
import {
  Alert,
  Button,
  Form,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap'
import {
  Formik
} from 'formik'
import _ from 'lodash'
import { examOrCourseDisplay } from '../../common'
import { applicationSchema } from './validation'

const PrivacyAgreement = ({
  values,
  errors,
  handleChange,
  handleBlur
}) => (
  <>
    <p className='border border-secondary'>申込情報ならびに受験結果などの個人情報は、登録情報として保存されるとともに、お申込み中の試験の主催者、購入する学習などのサービス提供者に提供されます。提供された個人情報は、お申込みされた試験の実施のために必要な範囲で使用するとともに、お申込みされた試験主催団体のみに提供いたします。なお、本画面の申込みボタンを押した段階で、個人情報の取得について同意したものとさせていただきます。申込みをされる際には、上記の通り個人情報が提供されることにも同意の上、お申込みくださいますようお願いします。</p>
    <Form.Check
      name='privacyAgreement'
      type='checkbox'
      label='個人情報の提供に同意する'
      checked={values.privacyAgreement}
      onChange={handleChange}
      onBlur={handleBlur}
      isInvalid={errors.privacyAgreement}
      feedback={errors.privacyAgreement}
      feedbackType='invalid'
    />
  </>
)

const OpenEndedInput = ({
  index,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  extraAttribute
}) => (
  <Form.Group>
    <Form.Label>{extraAttribute.name} {extraAttribute.required ? '(入力必須)' : ''}</Form.Label>
    <Form.Control
      type='text'
      name={`extraAttributeValues[${index}].value`}
      value={values.extraAttributeValues[index] ? values.extraAttributeValues[index].value : ''}
      onChange={handleChange}
      onBlur={handleBlur}
      isInvalid={touched.extraAttributeValues && touched.extraAttributeValues[index] &&
               touched.extraAttributeValues[index].value && errors.extraAttributeValues && errors.extraAttributeValues[index] &&
               errors.extraAttributeValues[index].value}
    />
    <Form.Control.Feedback type='invalid'>{errors.extraAttributeValues?.[index]?.value}</Form.Control.Feedback>
  </Form.Group>
)

const SingleChoiceInput = ({
  index,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  extraAttribute
}) => (
  <Form.Group>
    <Form.Label>{extraAttribute.name} {extraAttribute.required ? '(入力必須)' : ''}</Form.Label>
    <Form.Control
      as='select'
      name={`extraAttributeValues[${index}].value`}
      value={values.extraAttributeValues[index] ? values.extraAttributeValues[index].value : ''}
      onChange={handleChange}
      onBlur={handleBlur}
      isInvalid={touched.extraAttributeValues?.[index]?.value && errors.extraAttributeValues?.[index]?.value}
    >{_.map(
      [{ value: '', title: '選択してください' }, ..._.map(extraAttribute.choices, choice => ({ value: choice, title: choice }))],
      ({ value, title }, index) => (
        <option key={`extra_attribute-choice-option-${index}`} value={value}>{title}</option>
      )
    )}
    </Form.Control>
    <Form.Control.Feedback type='invalid'>{errors.extraAttributeValues?.[index]?.value}</Form.Control.Feedback>
  </Form.Group>
)

const MultipleChoiceInput = ({
  index,
  values,
  errors,
  setFieldValue,
  extraAttribute
}) => (
  <Form.Group>
    <Form.Label>{extraAttribute.name} {extraAttribute.required ? '(入力必須)' : ''}</Form.Label>
    <>{_.map(
      extraAttribute.choices,
      (choice, choiceIndex) => (
        <div key={choiceIndex}>
          <Form.Check type='checkbox'>
            <Form.Check.Input
              type='checkbox'
              name={`extraAttributeValues[${index}].values[${choiceIndex}]`}
              checked={values.extraAttributeValues[index].values.includes(choice)}
              onChange={() => {
                setFieldValue(
                  `extraAttributeValues[${index}].values`,
                  values.extraAttributeValues[index].values.includes(choice)
                    ? _.reject(values.extraAttributeValues[index].values, value => value === choice)
                    : _.concat(values.extraAttributeValues[index].values, choice)
                )
              }}
              isInvalid={errors.extraAttributeValues?.[index]?.values}
            />
            <Form.Check.Label>{choice}</Form.Check.Label>
            {choiceIndex === extraAttribute.choices.length - 1 && <Form.Control.Feedback type='invalid'>{errors.extraAttributeValues?.[index]?.values}</Form.Control.Feedback>}
          </Form.Check>
        </div>
      )
    )}
    </>
  </Form.Group>
)

const VenueExecutionInput = ({
  venueExecutions,
  values,
  handleChange,
  handleBlur,
  touched,
  errors
}) => (
  <Form.Group className='mb-3'>
    <Form.Label>会場</Form.Label>
    <Form.Control
      as='select'
      name='venueExecutionId'
      value={values.venueExecutionId}
      onChange={handleChange}
      onBlur={handleBlur}
      isInvalid={touched.venuExecutionId && errors.venueExecutionId}
      disabled={venueExecutions.length === 1}
    >
      {_.map(
        venueExecutions,
        ({ id, name }, index) => (<option key={index} value={id}>{name}</option>))}
    </Form.Control>
  </Form.Group>
)

const Error = ({ error }) => error
  ? (
    <Alert variant='danger'>
      {error.message && error.message}
      {error.messages && _.map(error.messages, (m, i) => <p key={i}>{m}</p>)}
    </Alert>
    )
  : <></>

const ApplicationResult = ({
  examOrCourse
}) => (
  <>
    {examOrCourseDisplay(examOrCourse)} の申し込みに成功しました。
  </>
)

const Prompt = ({
  examOrCourse,
  ...props
}) => (
  <>
    <Row className='mb-3'>
      {examOrCourse.application && examOrCourse.application.status !== 'payment_completed' &&
        <p>申し込みの決済がまだ完了していません。</p>}
      <p>{examOrCourseDisplay(examOrCourse)}に申し込みますか？</p>
      <VenueExecutionInput
        venueExecutions={examOrCourse.venueExecutions}
        {...props}
      />
      {examOrCourse.extraAttributes?.length > 0 && <p>以下の項目を入力してください。</p>}
    </Row>
    {_.map(
      examOrCourse.extraAttributes,
      (extraAttribute, index) => (
        <Row key={index} className='mb-3'>
          <Form.Control
            type='hidden'
            name={`extraAttributeValues[${index}].extraAttributeId`}
            value={extraAttribute.id}
          />
          {extraAttribute.extraAttributeType === 'open_ended'
            ? <OpenEndedInput
                index={index}
                extraAttribute={extraAttribute}
                {...props}
              />
            : (extraAttribute.extraAttributeType === 'single_choice'
                ? <SingleChoiceInput
                    index={index}
                    extraAttribute={extraAttribute}
                    {...props}
                  />
                : <MultipleChoiceInput
                    index={index}
                    extraAttribute={extraAttribute}
                    {...props}
                  />)}
        </Row>
      )
    )}
    <Row className='mb-3'>
      <h6>個人情報の提供に関する同意について</h6>
      <PrivacyAgreement
        {...props}
      />
    </Row>
    <Row>
      <Alert variant='danger'>
        <h6>※申し込み前に必ずご確認ください※</h6>
        <p>決済完了後、「サイトに戻る」ボタンを"必ず"押下ください。その操作がないと正常に申込が完了されませんので、ご注意ください。</p>
        <p>なお、受験料のお支払いを以て申し込み完了となります。</p>
        <p>申込ボタンを押下後、3日以内（申込締切日まで2日を切っての申込の場合は申込締切日まで）に決済手続きの完了を確認できない場合は、キャンセル扱いとなり、同じ試験には申し込めなくなりますのでご了承ください。</p>
      </Alert>
    </Row>
  </>
)

export const ApplyModal = ({
  show,
  onHide,
  onSubmit,
  examOrCourse,
  error
}) => (
  <Modal
    show={show}
    onHide={onHide}
    centered
  >{examOrCourse
    ? (
      <Formik
        onSubmit={onSubmit(examOrCourse)}
        initialValues={{
          extraAttributeValues: _.map(
            examOrCourse.extraAttributes,
            extraAttribute => _.merge(
              { extraAttributeId: extraAttribute.id },
              extraAttribute.extraAttributeType === 'single_choice'
                ? { value: '' }
                : (extraAttribute.extraAttributeType === 'multiple_choice'
                    ? { values: [] }
                    : { value: '' }))),
          venueExecutionId: examOrCourse.venueExecutions.length === 1 ? examOrCourse.venueExecutions[0].id : '',
          privacyAgreement: false
        }}
        validationSchema={applicationSchema(examOrCourse.extraAttributes)}
      >{(formikProps) => (
        <Form
          noValidate onSubmit={formikProps.handleSubmit}
        >
          <Modal.Header closeButton>
            <Modal.Title>申し込み</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Error error={error} />
            {examOrCourse.application?.status === 'payment_completed'
              ? <ApplicationResult
                  examOrCourse={examOrCourse}
                />
              : <Prompt
                  examOrCourse={examOrCourse}
                  {...formikProps}
                />}
            <Error error={error} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='button'
              variant='secondary'
              onClick={onHide}
            >閉じる
            </Button>
            <Button
              type='submit'
              style={{ display: examOrCourse?.application?.status === 'payment_completed' ? 'none' : null }}
              disabled={examOrCourse?.application?.status === 'payment_completed' ||
                        (formikProps.errors && Object.entries(formikProps.errors).length > 0) ||
                        error}
            >申し込む
            </Button>
          </Modal.Footer>
        </Form>
      )}
      </Formik>)
    : <Spinner animation='border' />}
  </Modal>
)
