import {
  createSlice,
  createSelector
} from '@reduxjs/toolkit'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {}
})

export const selectCommon = state => state.common

export const selectBaseUri = createSelector(
  selectCommon,
  common => common.baseUri
)
