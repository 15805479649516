import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import {
  fetchCategoriesPayloadCreator,
  fetchTagsPayloadCreator,
  handleError
} from '../common'
import { invalidateCurrentUser } from '../auth'

export const fetchMessage = createAsyncThunk(
  'home/fetchMessage',
  async (_, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}`, {
      headers: {
        Accept: 'application/json'
      }
    })
      .then(response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          throw error
        }
        return response.json()
      })
      .then(toCamel)
      .catch(handleError({ dispatch, rejectWithValue, invalidateCurrentUser }))
  }
)

export const fetchCategories = createAsyncThunk(
  'home/fetchCategories',
  fetchCategoriesPayloadCreator(invalidateCurrentUser)
)

export const fetchTags = createAsyncThunk(
  'home/fetchTags',
  fetchTagsPayloadCreator(invalidateCurrentUser)
)
