import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  useSearchParams,
  useNavigate
} from 'react-router-dom'
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  ListGroup,
  Row,
  Tab,
  Tabs
} from 'react-bootstrap'
import _ from 'lodash'
import moment from 'moment'
import { selectUser } from '../../auth'
import {
  Pagination,
  parseQueryAsInt,
  updateQueryParamEntries,
  removeQueryParamKeys,
  examOrCourseDisplay,
  selectBaseUri,
  objectToQuerystring
} from '../../common'
import {
  UpsertModal,
  examOrCourseStatuses
} from './UpsertModal'
import {
  openModal,
  closeModal,
  selectExamOrCourse,
  selectSearchByPostalCode,
  selectStatus,
  selectOperationOrganization,
  selectSortingKey,
  selectSortingOrder,
  clearAllLists,
  clearExamOrCourseUpsertCandidates
} from '../adminSlice'
import {
  OperationOrganizationDropdown
} from '../OperationOrganizationDropdown'
import {
  createExamOrCourse,
  searchAddressByPostalCode,
  searchVenue,
  fetchExamOrCourses,
  updateExamOrCourse,
  fetchExamOrCourseNames
} from './examOrCourseAPI'
import { ExamOrCourseSearchPanel } from './SearchPanel'
moment.locale('ja')

const ExamOrCourseRow = ({
  examOrCourse,
  onEditClick,
  onExamineesClick
}) => {
  const totalNumberOfApplicants = _.sum(_.map(examOrCourse.venueExecutions, venueExecution => venueExecution.numberOfApplicants))
  const totalCapacity = _.sum(_.map(examOrCourse.venueExecutions, venueExecution => venueExecution.capacity))
  const totalNumberOfRemainingSeats = totalCapacity - totalNumberOfApplicants
  const includeSharedVenueExecution = _.some(examOrCourse.venueExecutions, venueExecution => venueExecution.shared)
  return (
    <Row>
      <h5>{examOrCourse.operationOrganization.name} / {examOrCourseDisplay(examOrCourse)}</h5>
      <Col>
        <dl>
          <dt>申し込み期間</dt>
          <dd>{moment(examOrCourse.applicationStartAt).format('LLL')} - {moment(examOrCourse.applicationEndAt).format('LLL')}</dd>
          {_.map(
            examOrCourse.durations,
            ({ from, to, allDay }, index) => (
              <div key={index}>
                <dt>開催期間{examOrCourse.durations.length > 1 && index + 1}</dt>
                <dd>{moment(from).format(allDay ? 'LL' : 'LLL')} - {moment(to).format(allDay ? 'LL' : 'LLL')}</dd>
              </div>
            ))}
          <dt>会場</dt>
          <dd>{_.map(examOrCourse.venueExecutions, venueExecution => venueExecution.name)}</dd>
          <dt>受検料</dt>
          <dd>{(examOrCourse.price + examOrCourse.tax).toLocaleString('ja-JP')}円 (税込)</dd>
        </dl>
        <Button
          onClick={onExamineesClick}
        >
          受検者一覧
        </Button>
      </Col>
      <Col>
        <dl>
          <dt>申込人数</dt>
          <dd className='total-number-of-applicants'>{totalNumberOfApplicants}</dd>
          <dt>定員数</dt>
          <dd className='total-capacity'>{totalCapacity}</dd>
          <dt>残席数 {includeSharedVenueExecution && '(共有会場含む)'}</dt>
          <dd className='total-number-of-remaining-seats'>{totalNumberOfRemainingSeats}</dd>
        </dl>
      </Col>
      <Col sm='auto'>
        <Button
          variant='light'
          onClick={onEditClick}
        >編集
        </Button>
      </Col>
      <Col sm='auto'>
        <Button
          variant='danger'
        >削除
        </Button>
      </Col>
    </Row>
  )
}

const ExamOrCourseListContent = ({
  list,
  onEditClick,
  onExamineesClick
}) => (
  <ListGroup variant='flush'>
    {_.map(
      list,
      (examOrCourse, index) => (
        <ListGroup.Item key={index}>
          <ExamOrCourseRow
            examOrCourse={examOrCourse}
            onEditClick={onEditClick(examOrCourse)}
            onExamineesClick={onExamineesClick(examOrCourse)}
          />
        </ListGroup.Item>
      ))}
  </ListGroup>
)

const sortKeys = [
  { title: '更新日', key: 'updatedAt' },
  { title: '検定・講座名', key: 'name' },
  { title: '申し込み開始日時', key: 'applicationStartAt' },
  { title: '申し込み終了日時', key: 'applicationEndAt' }
]

const sortOrders = [
  { title: '昇順', order: 'asc' },
  { title: '降順', order: 'desc' }
]

const SortingDropdown = ({
  onSelectSortingKey,
  selectedKey,
  onSelectSortingOrder,
  selectedOrder
}) => {
  return (
    <DropdownButton
      variant='secondary'
      title={_.find(sortKeys, { key: selectedKey })?.title ?? ''}
    >
      {_.map(sortKeys,
        ({ title, key }, index) => (
          <Dropdown.Item
            key={index}
            onClick={onSelectSortingKey(key)}
            active={selectedKey === key}
          >{title}
          </Dropdown.Item>
        ))}
      <Dropdown.Divider />
      {_.map(sortOrders,
        ({ title, order }, index) => (
          <Dropdown.Item
            key={index}
            onClick={onSelectSortingOrder(order)}
            active={selectedOrder === order}
          >{title}
          </Dropdown.Item>
        ))}
    </DropdownButton>
  )
}

const fetchExamOrCoursesParams = (status, operationOrganizationId, sorting, page, filtering) =>
  _.omitBy(
    _.merge(
      { status, operationOrganizationId, sorting, page },
      filtering),
    (value) => !value || value === 'all')

const examOrCourseSearchKeys = ['name']
const parseQueryToFiltering = queryParams =>
  Object.fromEntries(
    _.filter(
      [...queryParams.entries()],
      ([key, _value]) => examOrCourseSearchKeys.includes(key)))

export const ExamOrCourseList = () => {
  const dispatch = useDispatch()
  const [queryParams, setQueryParams] = useSearchParams()
  const navigate = useNavigate()
  const {
    version,
    openedModal,
    upsert,
    fetching,
    list
  } = useSelector(selectExamOrCourse)
  const user = useSelector(selectUser)
  const currentPage = parseQueryAsInt(queryParams, 'page')
  const filtering = parseQueryToFiltering(queryParams)
  const baseUri = useSelector(selectBaseUri)

  useEffect(() => {
    if (!user) {
      dispatch(clearAllLists())
    }
  }, [user])

  useEffect(
    () => {
      const filtering = parseQueryToFiltering(queryParams)
      dispatch(fetchExamOrCourses(
        fetchExamOrCoursesParams(list.status, list.operationOrganization, list.sorting, currentPage, filtering)
      ))
    },
    [list.status, list.operationOrganization, list.sorting, version, currentPage, queryParams])
  const searchByPostalCode = useSelector(selectSearchByPostalCode)
  const handleOpenModal = modalSelector => args => () => dispatch(openModal({ modalSelector, args }))
  const handleHideModal = modalSelector => () => dispatch(closeModal(modalSelector))
  const handleSubmit = currentExamOrCourse => values => {
    const {
      allDay, durations, operationOrganization, shareCapacity, venueId, venueExecutionId,
      venueAddressLine1, venueAddressLine2, venueCapacity, venueCity,
      venueName, venuePostalCode, venuePrefecture, venueSelection,
      extraAttributes, ...rest
    } = values
    const examOrCourse = _.merge(
      rest,
      {
        venue: {
          selection: venueSelection,
          id: venueSelection === 'existing' ? venueId : null,
          venueExecutionId: (currentExamOrCourse?.venueExecutions[0].id === venueExecutionId || (venueSelection === 'existing' && shareCapacity)) ? venueExecutionId : null,
          name: venueName,
          postalCode: venuePostalCode,
          prefecture: venuePrefecture,
          city: venueCity,
          addressLine1: venueAddressLine1,
          addressLine2: venueAddressLine2,
          capacity: venueCapacity,
          shareCapacity
        }
      },
      {
        durations: _.map(durations, duration => _.merge(duration, { allDay }))
      },
      { id: currentExamOrCourse?.id },
      { extraAttributes: _.map(extraAttributes, ({ required, ...extraAttributeProps }) => ({ required: required === 'required', ...extraAttributeProps })) }
    )
    const action = currentExamOrCourse?.id ? updateExamOrCourse : createExamOrCourse
    dispatch(action({ operationOrganization, examOrCourse }))
  }
  const handlePostalCodeInputChange = postalCode => dispatch(searchAddressByPostalCode(postalCode))
  const handleVenueExecutionIdInputChange = ({
    operationOrganization,
    setFieldValue
  }) => query => {
    setFieldValue('venueId', '')
    setFieldValue('venueExecutionId', '')
    dispatch(searchVenue(_.merge({ query }, operationOrganization === 0
      ? {}
      : {
          operationOrganizationId: operationOrganization
        })))
  }
  const handleVenueExecutionIdSelectChanged = ({
    setFieldValue
  }) => (selectedVenueExecutions) => {
    if (selectedVenueExecutions.length < 1) {
      return
    }
    setFieldValue('venueId', selectedVenueExecutions[0].venueId)
    setFieldValue('venueExecutionId', selectedVenueExecutions[0].id)
  }
  const handleSelectStatus = status => dispatch(selectStatus(status))
  const handleSelectOperationOrganization = operationOrganization => () => dispatch(selectOperationOrganization({ operationOrganization, propName: 'examOrCourse' }))
  const handleSelectSortingKey = key => () => dispatch(selectSortingKey(key))
  const handleSelectSortingOrder = order => () => dispatch(selectSortingOrder(order))
  const handleSearch = values =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        Object.entries(values)),
      { replace: true })
  const handleReset = () =>
    setQueryParams(
      removeQueryParamKeys(
        queryParams,
        examOrCourseSearchKeys),
      { replace: true })
  const handlePaginationClick = page => () =>
    setQueryParams(
      updateQueryParamEntries(
        queryParams,
        [['page', page]]),
      { replace: true })
  const handleExamineesClick = examOrCourse => () => navigate(`/admin/application?operationOrganization=${examOrCourse.operationOrganization.id}&examOrCourse=${examOrCourse.id}`)
  const handleNameInputChange = ({ preDispatchCallback, operationOrganizationId }) => query => {
    if (preDispatchCallback) {
      preDispatchCallback()
    }
    if (operationOrganizationId) {
      dispatch(fetchExamOrCourseNames({ operationOrganizationId, query }))
    }
  }
  const handleNameIdSelected = setName => selectedNames => {
    if (selectedNames.length < 1) {
      return
    }
    setName(selectedNames[0])
  }
  const handleOperationOrganizationSelected = () => dispatch(clearExamOrCourseUpsertCandidates())
  const handleEditClick = (examOrCourse) => () => {
    dispatch(fetchExamOrCourseNames({
      operationOrganizationId: examOrCourse.operationOrganization.id,
      id: examOrCourse.nameId
    })).then(() => {
      handleOpenModal(['examOrCourse', 'upsert'])(examOrCourse)()
    })
  }
  return (
    <>
      <UpsertModal
        show={openedModal === 'upsert'}
        onHide={handleHideModal(['examOrCourse', 'upsert'])}
        onSubmit={handleSubmit(upsert.args)}
        fetching={fetching}
        notice={upsert.notice}
        error={upsert.error}
        operationOrganizations={user?.operationOrganizations}
        venueAddressCandidates={searchByPostalCode.candidates}
        onPostalCodeInputChange={handlePostalCodeInputChange}
        onVenueExecutionIdInputChange={handleVenueExecutionIdInputChange}
        onVenueExecutionIdSelectChanged={handleVenueExecutionIdSelectChanged}
        venueCandidates={upsert.venueCandidates}
        examOrCourse={upsert.args}
        onNameInputChange={handleNameInputChange}
        onNameIdSelected={handleNameIdSelected}
        nameCandidates={upsert.nameCandidates}
        onOperationOrganizationSelected={handleOperationOrganizationSelected}
      />
      <Container>
        <Row className='mb-2'>
          <Col>
            <ExamOrCourseSearchPanel
              queryParams={queryParams}
              onSearch={handleSearch}
              onReset={handleReset}
              initialValues={filtering}
            />
          </Col>
          <Col sm='auto'>
            <OperationOrganizationDropdown
              operationOrganizations={user?.operationOrganizations}
              selected={list.operationOrganization}
              onSelectOperationOrganization={handleSelectOperationOrganization}
            />
          </Col>
          <Col sm='auto'>
            <SortingDropdown
              onSelectSortingKey={handleSelectSortingKey}
              selectedKey={list.sorting.key}
              onSelectSortingOrder={handleSelectSortingOrder}
              selectedOrder={list.sorting.order}
            />
          </Col>
          <Col sm='auto'>
            <Button
              onClick={handleOpenModal(['examOrCourse', 'upsert'])()}
            >新規作成
            </Button>
          </Col>
          <Col sm='auto'>
            <Button
              href={`${baseUri}admin/exam_or_courses?${objectToQuerystring(_.merge(_.omit(fetchExamOrCoursesParams(list.status, list.operationOrganization, list.sorting, currentPage, filtering), ['page']), { format: 'xlsx' }))}`}
            >ダウンロード
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <Tabs
              transition={false}
              activeKey={list.status}
              onSelect={handleSelectStatus}
            >{_.map(
              _.concat([{ key: 'all', title: '全て' }], examOrCourseStatuses),
              ({ key, title }, index) => (
                <Tab key={index} eventKey={key} title={title}>
                  {list && <small>{list.totalCount}件</small>}
                  <ExamOrCourseListContent
                    list={list.data}
                    onEditClick={handleEditClick}
                    onExamineesClick={handleExamineesClick}
                  />
                  <Pagination
                    currentPage={currentPage || 1}
                    totalPages={list.totalPages}
                    onPaginationClick={handlePaginationClick}
                  />
                </Tab>
              ))}
            </Tabs>
          </Col>
        </Row>
      </Container>
    </>
  )
}
