import {
  createSelector,
  createSlice
} from '@reduxjs/toolkit'
import { setCsrfToken } from '../common'
import {
  login,
  logout,
  signup,
  confirm,
  forgetPassword,
  resetPassword,
  resendConfirmation,
  resendUnlock,
  unlock,
  fetchUser,
  removeFrontendState
} from './authAPI'

const initialState = {
  fetching: false,
  error: null,
  openedModal: null,
  login: {
    passwordHidden: true
  },
  signup: {
    passwordHidden: true
  },
  forgetPassword: {},
  resetPassword: {
    passwordHidden: true
  },
  resendConfirmation: {},
  resendUnlock: {},
  authError: {
    open: false,
    waitForReloading: false
  },
  loggedOutExplicitly: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    openModal: (state, action) => {
      const { modalName, notice, ...postAuth } = action.payload
      state.openedModal = modalName
      if (Object.keys(postAuth).length > 0) {
        state.postAuth = postAuth
      }
      if (notice) {
        state[state.openedModal].notice = notice
      }
    },
    closeModal: state => {
      delete state[state.openedModal].error
      delete state[state.openedModal].notice
      state.openedModal = null
    },
    toggleLoginPasswordHidden: state => {
      state.login.passwordHidden = !state.login.passwordHidden
    },
    toggleSignupPasswordHidden: state => {
      state.signup.passwordHidden = !state.signup.passwordHidden
    },
    toggleResetPasswordPasswordHidden: state => {
      state.resetPassword.passwordHidden = !state.resetPassword.passwordHidden
    },
    invalidateCurrentUser: (state, action) => {
      const { redirectUri, ...error } = action.payload
      state.currentUser = null
      state.authError.open = true
      if (!state.authError.error) {
        state.authError.error = error
      }
      state.authError.redirectUri = redirectUri
    },
    hideAuthErrorModal: state => {
      state.authError.open = false
      delete state.authError.error
      state.authError.waitForReloading = true
    },
    completeExplicitLogout: state => {
      state.loggedOutExplicitly = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(login.pending, state => {
        state.fetching = true
        delete state.login.error
      })
      .addCase(login.fulfilled, (state, action) => {
        const { user, csrfToken, frontendState } = action.payload
        state.fetching = false
        state.currentUser = user
        state.openedModal = null
        if (frontendState?.auth?.postAuth) {
          state.postAuth = frontendState.auth.postAuth
        }
        delete state.login.notice
        delete state.login.error
        setCsrfToken(csrfToken)
      })
      .addCase(login.rejected, (state, action) => {
        state.fetching = false
        state.login.error = action.payload
      })
      .addCase(logout.pending, (state, action) => {
        state.fetching = true
        state.error = null
      })
      .addCase(logout.fulfilled, (state, action) => {
        const { csrfToken } = action.payload
        state.fetching = false
        state.currentUser = null
        setCsrfToken(csrfToken)
        state.loggedOutExplicitly = true
      })
      .addCase(logout.rejected, (state, action) => {
        state.fetching = false
        state.error = action.payload
        console.log(action.payload.stack)
      })
      .addCase(signup.pending, (state, action) => {
        state.fetching = true
        delete state.signup.error
      })
      .addCase(signup.fulfilled, (state, action) => {
        const { csrfToken, notice } = action.payload
        state.fetching = false
        state.signup.notice = notice || '登録が完了しました'
        delete state.signup.error
        setCsrfToken(csrfToken)
      })
      .addCase(signup.rejected, (state, action) => {
        state.fetching = false
        state.signup.error = action.payload
      })
      .addCase(confirm.pending, state => {
        state.fetching = true
        delete state.login.error
      })
      .addCase(confirm.fulfilled, (state, action) => {
        const { notice } = action.payload
        state.fetching = false
        state.login.notice = notice || 'メールアドレスの確認が完了しました'
        delete state.login.error
      })
      .addCase(confirm.rejected, (state, action) => {
        state.fetching = false
        state.login.error = action.payload
      })
      .addCase(forgetPassword.pending, state => {
        state.fetching = true
        delete state.forgetPassword.error
      })
      .addCase(forgetPassword.fulfilled, (state, action) => {
        const { csrfToken, notice } = action.payload
        state.fetching = false
        state.forgetPassword.notice = notice || 'パスワードリセット用のメールを送信しました。メール内のリンクからパスワードを再設定してください。'
        delete state.forgetPassword.error
        setCsrfToken(csrfToken)
      })
      .addCase(forgetPassword.rejected, (state, action) => {
        state.fetching = false
        state.forgetPassword.error = action.payload
      })
      .addCase(resetPassword.pending, state => {
        state.fetching = true
        delete state.resetPassword.error
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        const { csrfToken, user } = action.payload
        state.fetching = false
        state.currentUser = user
        delete state.resetPassword.error
        state.openedModal = null
        setCsrfToken(csrfToken)
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.fetching = false
        state.resetPassword.error = action.payload
      })
      .addCase(resendConfirmation.pending, state => {
        state.fetching = true
        delete state.resendConfirmation.error
      })
      .addCase(resendConfirmation.fulfilled, state => {
        state.fetching = false
        state.resendConfirmation.notice = '確認メールを送信しました。メール内のリンクからパスワードを設定してください。'
        delete state.resendConfirmation.error
      })
      .addCase(resendConfirmation.rejected, (state, action) => {
        state.fetching = false
        state.resendConfirmation.error = action.payload
      })
      .addCase(resendUnlock.pending, state => {
        state.fetching = true
        delete state.resendUnlock.error
      })
      .addCase(resendUnlock.fulfilled, state => {
        state.fetching = false
        state.resendUnlock.notice = 'ロック解除方法を送信しました。メール内のリンクからロックを解除してください。'
        delete state.resendUnlock.error
      })
      .addCase(resendUnlock.rejected, (state, action) => {
        state.fetching = false
        state.resendUnlock.error = action.payload
      })
      .addCase(unlock.pending, state => {
        state.fetching = true
        delete state.login.error
      })
      .addCase(unlock.fulfilled, (state, action) => {
        const { notice } = action.payload
        state.fetching = false
        state.login.notice = notice || 'アカウントをロック解除しました。'
        delete state.login.error
      })
      .addCase(unlock.rejected, (state, action) => {
        state.fetching = false
        state.login.error = action.payload
      })
      .addCase(fetchUser.pending, (state, action) => {
        state.fetching = true
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.fetching = false
        state.currentUser = action.payload
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.fetching = false
        state.error = action.payload
      })
      .addCase(removeFrontendState.pending, (state, action) => {
      })
      .addCase(removeFrontendState.fulfilled, (state, action) => {
        delete state.postAuth
      })
      .addCase(removeFrontendState.rejected, (state, action) => {
      })
  }
})

export const selectAuth = state => state.auth

export const selectUser = createSelector(
  selectAuth,
  auth => auth.currentUser
)

export const {
  openModal,
  closeModal,
  toggleLoginPasswordHidden,
  toggleSignupPasswordHidden,
  toggleResetPasswordPasswordHidden,
  invalidateCurrentUser,
  hideAuthErrorModal,
  completeExplicitLogout
} = authSlice.actions

export default authSlice.reducer
