import React from 'react'
import {
  Container,
  Navbar,
  Image
} from 'react-bootstrap'
import {
  Outlet,
  Link
} from 'react-router-dom'
import remoesLogoImage from 'images/logo-remoes.svg'
import pwdLogoImage from 'images/logo_persol_w.png'
import remoesLogoWhiteImage from 'images/logo-remoes-white.svg'

export const Layout = ({
  children
}) => (
  <>
    <Navbar
      bg='white'
      className='p-3 remoes-globalnavi'
      expand='md'
    >
      <Container>
        <p className='mb-0'>
          <Navbar.Brand
            to='/'
            as={Link}
          ><Image src={remoesLogoImage} alt='リモーズ' width='156' />
          </Navbar.Brand>
        </p>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='remoes-menu' className='pt-2 pt-md-0 pb-4 pb-md-0'>{children}</Navbar.Collapse>
      </Container>
    </Navbar>
    <Container className='min-vh-100'>
      <Outlet />
    </Container>
    <nav className='remoes-footer mt-4 p-3 bg-color1'>
      <div className='container p-4 d-md-flex'>
        <div className='col-md-4'>
          <p className='text-center'>
            <a href='https://www.persol-bd.co.jp/' target='_blank' rel='noreferrer'>
              <Image src={pwdLogoImage} alt='パーソルビジネスプロセスデザイン' width='400' />
            </a>
          </p>
        </div>
        <div className='col-md-4'>
          <p className='text-center'>
            <a href='/'>
              <Image src={remoesLogoWhiteImage} alt='リモーズ' width='160' />
            </a>
          </p>
          <p className='text-center small pt-1'>copyrights © PERSOL BUSINESS PROCESS DESIGN CO., LTD.</p>
        </div>
      </div>
    </nav>
  </>
)
