import { createSlice } from '@reduxjs/toolkit'
import {
  fetchMessage,
  fetchCategories,
  fetchTags
} from './homeAPI'

const initialState = {
  message: '',
  fetching: false,
  error: null,
  categories: [],
  tags: []
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMessage.pending, (state, action) => {
        state.fetching = true
        state.error = null
      })
      .addCase(fetchMessage.fulfilled, (state, action) => {
        state.fetching = false
        state.message = action.payload.message
      })
      .addCase(fetchMessage.rejected, (state, action) => {
        state.fetching = false
        state.error = action.payload.message
        console.log(action.payload.stack)
      })
      .addCase(fetchCategories.pending, (state, action) => {
        state.fetching = true
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.fetching = false
        state.categories = action.payload.list
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.fetching = false
      })
      .addCase(fetchTags.pending, (state, action) => {
        state.fetching = true
      })
      .addCase(fetchTags.fulfilled, (state, action) => {
        state.fetching = false
        state.tags = action.payload.list
      })
      .addCase(fetchTags.rejected, (state, action) => {
        state.fetching = false
      })
  }
})
