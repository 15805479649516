import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  csrfToken,
  toSnake,
  createPostalCodeSearchAsyncThunk,
  handleError
} from '../../common'
import { invalidateCurrentUser } from '../../auth'
import {
  fetchExamOrCoursesPayloadCreator,
  fetchVenueExecutionsPayloadCreator
} from '../adminAPI'

export const fetchExamOrCourses = createAsyncThunk(
  'admin/venueExecution/fetchExamOrCourses',
  fetchExamOrCoursesPayloadCreator
)

export const fetchVenueExecutions = createAsyncThunk(
  'admin/venueExecution/fetchVenueExecutions',
  fetchVenueExecutionsPayloadCreator
)

export const searchAddressByPostalCode = createPostalCodeSearchAsyncThunk('admin/venueExecution/searchAddressByPostalCode')

export const createVenueExecution = createAsyncThunk(
  'admin/venueExecution/createvenueExecution',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { operationOrganization: organizationId, ...createParams } = params
    return fetch(`${baseUri}admin/operation_organizations/${organizationId}/venue_executions`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ venueExecution: createParams }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)

export const updateVenueExecution = createAsyncThunk(
  'admin/venueExecution/updateVenueExecution',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { operationOrganization: organizationId, id, ...updateParams } = params
    return fetch(`${baseUri}admin/operation_organizations/${organizationId}/venue_executions/${id}`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ venueExecution: updateParams }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({
        dispatch,
        rejectWithValue,
        invalidateCurrentUser,
        redirectUri: '/?authMode=login'
      }))
  }
)
