import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  csrfToken,
  toSnake,
  handleError
} from '../common'
import { invalidateCurrentUser } from '../auth'

export const updatePassword = createAsyncThunk(
  'setting/updatePassowrd',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/${params.id}/password`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ user: params }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({ dispatch, rejectWithValue, invalidateCurrentUser }))
  }
)

export const updateEmail = createAsyncThunk(
  'setting/updateEmail',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    return fetch(`${baseUri}users/${params.id}/email`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': csrfToken(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(toSnake({ user: params }))
    })
      .then(async response => {
        if (!response.ok) {
          const error = new Error(`${response.status} ${response.statusText}`)
          error.status = response.status
          error.statusText = response.statusText
          const contentType = response.headers.get('Content-Type')
          if (contentType && contentType.startsWith('application/json')) {
            const responseBody = await response.json()
            error.messages = _.reduce(
              responseBody.errors,
              (accumulatedErrors, errors) => _.concat(accumulatedErrors, errors),
              responseBody.error ? [responseBody.error] : []
            )
          }
          throw error
        }
        return Promise.resolve()
      })
      .catch(handleError({ dispatch, rejectWithValue, invalidateCurrentUser }))
  }
)
