import {
  boolean,
  object,
  number,
  array,
  tuple,
  string
} from 'yup'
import _ from 'lodash'

const singleChoiceSchema = extraAttribute => object({
  extraAttributeId: number().required(),
  value: string().oneOf(extraAttribute.choices)[extraAttribute.required ? 'required' : 'optional']()
})

const multiChoiceSchema = extraAttribute => object({
  extraAttributeId: number().required(),
  values: array().of(string().oneOf(extraAttribute.choices)).min(extraAttribute.required ? 1 : 0)
})

const openEndedSchema = extraAttribute => object({
  extraAttributeId: number().required(),
  value: string()[extraAttribute.required ? 'required' : 'optional']()
})

export const applicationSchema = (extraAttributes) => object({
  privacyAgreement: boolean().required().oneOf([true], '申し込みには同意が必要です'),
  venueExecutionId: number().required(),
  extraAttributeValues: tuple(
    _.map(
      extraAttributes,
      (extraAttribute) =>
        (extraAttribute.extraAttributeType === 'single_choice'
          ? singleChoiceSchema
          : (extraAttribute.extraAttributeType === 'multiple_choice'
              ? multiChoiceSchema
              : openEndedSchema))(extraAttribute)))
})
