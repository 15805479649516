import React from 'react'
import {
  Button,
  Modal
} from 'react-bootstrap'
import _ from 'lodash'

const AuthErrorModal = ({
  show,
  onHide,
  error
}) => {
  const messages = error?.message ? [error.message] : (error?.messages ? error.messages : ['セッションがタイムアウトした可能性があります。もう一度ログインしてください。'])
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Body>{_.map(messages, (m, i) => <p key={i}>{m}</p>)}</Modal.Body>
      <Modal.Footer>
        <Button
          type='button'
          variant='primary'
          onClick={onHide}
        >OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AuthErrorModal
