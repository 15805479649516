import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  Nav,
  NavDropdown
} from 'react-bootstrap'
import _ from 'lodash'
import {
  useSearchParams,
  useResolvedPath,
  useNavigate
} from 'react-router-dom'
import {
  closeModal,
  openModal,
  selectAuth,
  toggleLoginPasswordHidden,
  toggleSignupPasswordHidden,
  toggleResetPasswordPasswordHidden,
  hideAuthErrorModal,
  completeExplicitLogout
} from './authSlice'
import {
  login,
  logout,
  signup,
  confirm,
  forgetPassword,
  resetPassword,
  resendConfirmation,
  resendUnlock,
  unlock,
  removeFrontendState
} from './authAPI'
import { LoginModal } from './LoginModal'
import { SignupModal } from './SignupModal'
import { ForgetPasswordModal } from './ForgetPasswordModal'
import { ResetPasswordModal } from './ResetPasswordModal'
import { ResendConfirmationModal } from './ResendConfirmationModal'
import { ResendUnlockModal } from './ResendUnlockModal'
import AuthErrorModal from './AuthErrorModal'

const LoginOrSignUp = () => {
  const {
    openedModal,
    login: loginForm,
    signup: signupForm,
    forgetPassword: forgetPasswordForm,
    resetPassword: resetPasswordForm,
    resendConfirmation: resendConfirmationForm,
    resendUnlock: resendUnlockForm,
    authError,
    loggedOutExplicitly
  } = useSelector(selectAuth)
  const [queryParams] = useSearchParams()
  const [authMode, confirmationToken, resetPasswordToken, unlockToken, email] = _.map(
    ['authMode', 'confirmationToken', 'resetPasswordToken', 'unlockToken', 'email'],
    k => queryParams.get(k))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  useEffect(() => {
    if (['login', 'resetPassword'].includes(authMode)) {
      dispatch(openModal({ modalName: authMode }))
    }
  }, [authMode])
  useEffect(() => {
    if (confirmationToken) {
      dispatch(confirm({ confirmationToken }))
    }
  }, [confirmationToken])
  useEffect(() => {
    if (unlockToken) {
      dispatch(unlock({ unlockToken }))
    }
  }, [unlockToken])
  const handleLoginSubmit = values => dispatch(login({ ...values, rememberMe: values.rememberMe ? 1 : 0 }))
  useEffect(() => {
    if (!authError.waitForReloading) return

    window.location.href = authError.redirectUri ? authError.redirectUri : window.location.href
  }, [authError.waitForReloading, authError.redirectUri])
  useEffect(() => {
    if (loggedOutExplicitly) {
      navigate('/')
      dispatch(completeExplicitLogout())
    }
  }, [loggedOutExplicitly])
  const handleSignupButtonClicked = () => dispatch(openModal({ modalName: 'signup' }))
  const handleSignupSubmit = values => {
    dispatch(signup(values))
  }
  const handleSigninButtonClicked = () => dispatch(openModal({ modalName: 'login' }))
  const handleForgetPasswordButtonClicked = () => dispatch(openModal({ modalName: 'forgetPassword' }))
  const handleHide = () => dispatch(closeModal())
  const handleForgetPasswordSubmit = values => dispatch(forgetPassword(values))
  const handleResetPasswordSubmit = values => dispatch(resetPassword({ ...values, resetPasswordToken }))
  const handleResendConfirmationButtonClicked = () => dispatch(openModal({ modalName: 'resendConfirmation' }))
  const handleResendConfirmationSubmit = values => dispatch(resendConfirmation(values))
  const handleResendUnlockButtonClicked = () => dispatch(openModal({ modalName: 'resendUnlock' }))
  const handleResendUnlockSubmit = values => dispatch(resendUnlock(values))
  const handleHideAuthErrorModal = () => dispatch(hideAuthErrorModal())
  return (
    <>
      <LoginModal
        show={openedModal === 'login' && !authError.open}
        onHide={handleHide}
        onSubmit={handleLoginSubmit}
        passwordHidden={loginForm.passwordHidden}
        notice={loginForm.notice}
        error={loginForm.error}
        onToggleButtonClicked={() => dispatch(toggleLoginPasswordHidden())}
        onSignupButtonClicked={handleSignupButtonClicked}
        onForgetPasswordButtonClicked={handleForgetPasswordButtonClicked}
        onResendConfirmationButtonClicked={handleResendConfirmationButtonClicked}
        onResendUnlockButtonClicked={handleResendUnlockButtonClicked}
      />
      <SignupModal
        show={openedModal === 'signup' && !authError.open}
        onHide={handleHide}
        onSubmit={handleSignupSubmit}
        passwordHidden={signupForm.passwordHidden}
        error={signupForm.error}
        notice={signupForm.notice}
        onToggleButtonClicked={() => dispatch(toggleSignupPasswordHidden())}
        onLoginButtonClicked={handleSigninButtonClicked}
      />
      <ForgetPasswordModal
        show={openedModal === 'forgetPassword' && !authError.open}
        onHide={handleHide}
        error={forgetPasswordForm.error}
        notice={forgetPasswordForm.notice}
        onSubmit={handleForgetPasswordSubmit}
        onLoginButtonClicked={handleSigninButtonClicked}
      />
      <ResetPasswordModal
        show={openedModal === 'resetPassword' && !authError.open}
        onHide={handleHide}
        onSubmit={handleResetPasswordSubmit}
        passwordHidden={resetPasswordForm.passwordHidden}
        error={resetPasswordForm.error}
        onToggleButtonClicked={() => dispatch(toggleResetPasswordPasswordHidden())}
        email={email}
      />
      <ResendConfirmationModal
        show={openedModal === 'resendConfirmation' && !authError.open}
        onHide={handleHide}
        onSubmit={handleResendConfirmationSubmit}
        error={resendConfirmationForm.error}
        notice={resendConfirmationForm.notice}
        onLoginButtonClicked={handleSigninButtonClicked}
      />
      <ResendUnlockModal
        show={openedModal === 'resendUnlock' && !authError.open}
        onHide={handleHide}
        onSubmit={handleResendUnlockSubmit}
        error={resendUnlockForm.error}
        notice={resendUnlockForm.notice}
        onLoginButtonClicked={handleSigninButtonClicked}
      />
      <AuthErrorModal
        show={authError.open}
        onHide={handleHideAuthErrorModal}
        error={authError.error}
      />
      <Nav.Item>
        <Button
          onClick={handleSignupButtonClicked}
          className='btn-regi'
        >無料登録
        </Button>
      </Nav.Item>
      <Nav.Item>
        <Button
          onClick={handleSigninButtonClicked}
          className='btn-login'
        >ログイン
        </Button>
      </Nav.Item>
    </>
  )
}

const AuthDropdown = ({
  user,
  children
}) => {
  const [queryParams, setQueryParams] = useSearchParams()
  const { pathname } = useResolvedPath()
  const navigate = useNavigate()
  const { postAuth } = useSelector(selectAuth)
  useEffect(() => {
    setQueryParams(
      _.filter(
        [...queryParams.entries()],
        ([key, _value]) => !['authMode', 'confirmationToken', 'resetPasswordToken', 'unlockToken'].includes(key)))
  }, [queryParams, setQueryParams])
  const dispatch = useDispatch()
  useEffect(() => {
    if (user && postAuth?.applyingExamOrCourse && postAuth?.applyingExamOrCourseName &&
        (pathname !== `/examOrCourse/browse/${postAuth.applyingExamOrCourseName}` ||
         queryParams.get('applyingExamOrCourse') !== postAuth.applyingExamOrCourse ||
         queryParams.get('applyingExamOrCourseName') !== postAuth.applyingExamOrCourseName)) {
      dispatch(removeFrontendState(user.id))
      navigate(`/examOrCourse/browse/${postAuth.applyingExamOrCourseName}?selectedExamOrCourseId=${postAuth.applyingExamOrCourse}&applyingExamOrCourse=${postAuth.applyingExamOrCourse}&applyingExamOrCourseName=${postAuth.applyingExamOrCourseName}`, { replace: true })
    }
  }, [pathname, navigate, postAuth, user, dispatch, queryParams])
  const handleClickLogout = () => dispatch(logout())
  return (
    <NavDropdown title={user.email}>
      {children}
      <NavDropdown.Item onClick={handleClickLogout}>ログアウト</NavDropdown.Item>
    </NavDropdown>
  )
}

export const AuthNavItem = ({ children }) => {
  const { currentUser } = useSelector(selectAuth)
  return currentUser
    ? <AuthDropdown user={currentUser}>{children}</AuthDropdown>
    : <LoginOrSignUp />
}
