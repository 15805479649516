import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ExamOrCourseNameDisplay from 'features/examOrCourse/browse/ExamOrCourseNameDisplay'
import {
  fetchCategoriesForPreview,
  fetchTagsForPreview
} from './examOrCourseNameAPI'
import { selectExamOrCourseName } from './examOrCourseNameSelectors'

const ExamOrCourseNamePreview = (props) => {
  const { tagIds, categoryId, ...otherProps } = props
  const dispatch = useDispatch()
  const { upsert } = useSelector(selectExamOrCourseName)
  const { preview } = upsert

  useEffect(() => {
    dispatch(fetchTagsForPreview({ ids: tagIds.length === 0 ? [0] : tagIds }))
  }, [tagIds])

  useEffect(() => {
    dispatch(fetchCategoriesForPreview({ ids: [categoryId || 0] }))
  }, [categoryId])

  return (
    <ExamOrCourseNameDisplay
      {...otherProps}
      tags={preview.tags}
      category={preview.categories.length > 0 ? preview.categories[0] : null}
    />
  )
}

export default ExamOrCourseNamePreview
